import { motion } from 'framer-motion/dist/framer-motion'
const modalCustom = ({children}) => {

    return (<motion.div 
                className="modal-wrapper"
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3}}
                exit={{ opacity: 1 }}
            >
        <motion.div 
            style={{
                maxWidth: "400px",
                maxHeight: "550px",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px"
            }}
            className="modal-display p-4"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', duration: .3}}
            exit={{ opacity: 0, scale: 0 }} 
        >
            {children}
        </motion.div>
    </motion.div>)
}
export default modalCustom