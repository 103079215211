import { DataContext } from '../../context/DataContext'
import { useContext } from 'react'
import AddBankModal from '../modals/addBankModal'
import SelectBankModal from '../modals/selectBankModal'
import Crypto2 from './internal2/crypto2'
import { Redirect } from "react-router-dom/cjs/react-router-dom.min"
import Fiat2 from './internal2/fiat2'
const Transaccion2 = ({ isLogin }) => {
     const ctx = useContext(DataContext)
    if (isLogin === false) {
        return <Redirect to='/' />
    }
   
    return (<>
        <AddBankModal />
        <SelectBankModal />
        <p>Seleccione el método donde recibe:</p>
        <div className="transactionsContainer">
            {ctx.selectedCoin2.type == "crypto" && <>
                <Crypto2 />
            </>}
            {ctx.selectedCoin2.type == "fiat" && <>
                <Fiat2 />
            </>}
        </div>

    </>)
}
export default Transaccion2