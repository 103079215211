import Crypto from './internal1/crypto'
import Fiat from './internal1/fiat'
import { Redirect } from "react-router-dom/cjs/react-router-dom.min"
const Transaccion1 = ({ isLogin }) => {
    if (isLogin === false) {
        return <Redirect to='/' />
    }
    return (<>
        <p>¿Cómo desea Pagar?</p>
        <div className="transactionsButtons">
            <Crypto />
            <Fiat />
        </div>
    </>)
}
export default Transaccion1