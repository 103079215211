import Loader from '../../components/loader/Loader'
import { DataContext } from '../../context/DataContext'
import { useContext } from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import { useState } from "react"
import { apiUrl } from '../../services/apiService'
import AlertModal from "../../components/modals/alertModal"
import { useEffect } from 'react'

const Perfil = () => {

    const { user, setLoadingText, setLoading } = useContext(DataContext)
    const ctx = useContext(DataContext)

    useEffect(() => {
        // eslint-disable-next-line no-lone-blocks
        //console.log(user)} 
        ctx.setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [selectedImage1, setSelectedImage1] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const [selectedImage3, setSelectedImage3] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [previewImage2, setPreviewImage2] = useState(null);
    const [previewImage3, setPreviewImage3] = useState(null);

    const [errorMessage1, setErrorMessage1] = useState('');
    const [errorMessage2, setErrorMessage2] = useState('');
    const [errorMessage3, setErrorMessage3] = useState('');

    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/tiff'];

    const handleImageChange = (e, setImage, setPreview, setErrorMessage) => {

        const file = e.target.files[0]
        if (file && allowedImageTypes.includes(file.type)) {
            setErrorMessage('')
            setImage(file);
            const reader = new FileReader();
            reader.onload = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setImage(null);
            setPreview(null);
            setErrorMessage('Por favor, selecciona una imagen válida (JPEG, PNG o GIF).');
        }
    }

    const handleUpload = () => {
        if (selectedImage1 && selectedImage2 && selectedImage3) {
            setLoading(true)
            setLoadingText('Cargando imagenes, esto puede tardar unos minutos.')

            const formData = new FormData()
            formData.append('image', selectedImage1)
            formData.append('image', selectedImage2)
            formData.append('image', selectedImage3)

            fetch(apiUrl + 'upload/' + user.dni, {
                method: 'POST',
                body: formData,
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Network response was not ok, status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    //console.log('Imágenes cargadas con éxito:', data);
                    ctx.setAlertModal({ status: true, text: "Imagenes cargadas con exito", type: "success" });
                })
                .catch(error => {
                    ctx.setAlertModal({ status: true, text: "Error al cargar las imagenes", type: "warning" });
                    console.error('Error al cargar las imágenes:', error);
                }).finally(() => {
                    setLoading(false)
                    setLoadingText("")
                    setSelectedImage1(null)
                    setSelectedImage2(null)
                    setSelectedImage3(null)
                    setPreviewImage(null)
                    setPreviewImage2(null)
                    setPreviewImage3(null)
                })

            //console.log('imágenes cargadas con éxito');
        } else {
            alert('Debe seleccionar las 3 imagenes')
            console.warn('Seleccione ambas imágenes antes de cargar.');
        }
    };
    
    return (<>
        {ctx.alertModal.status && <AlertModal setAlertModal={ctx.setAlertModal} alertModal={ctx.alertModal} />}
        {ctx.loading && <Loader />}
        <motion.div className="p-3" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }} exit={{ opacity: 0 }}>
            <div className="bancos">
                <h4 className={ctx.darkMode ? 'textDark' : "textLight"}>Datos personales</h4>
                {/* <button onClick={() => setAddData(true)}> <i className='bi-plus-circle' /> {ctx.userName ? "Editar" : "Agregar"} </button> */}
            </div>
            <div className='datos mt-3'>
                {/* <div className="datosList">
                    <div>
                        <i className='bi bi-credit-card-2-front mx-2' />
                        <b className='mx-1'>ID: </b> {user._id ? user._id : <> Sin id</>}
                    </div>
                </div> */}
                <div className="datosList">
                    <div>
                        <i className='bi-person mx-2' />
                        {user ? user.name : <> Nombre no asignado</>}
                    </div>
                </div>
                <div className="datosList">
                    <div>
                        <i className='bi bi-credit-card-2-front mx-2' />
                        {user ? user.dni : <> Documento de Identidad</>}
                    </div>
                </div>

                <div className="datosList">
                    <div>
                        <i className='bi-envelope mx-2' />
                        {user ? user.email : <> Correo electrónico no asignado</>}
                    </div>
                </div>
                <div className="datosList">
                    <div>
                        <i className='bi-telephone mx-2' />
                        {user ? user.phone : <>Teléfono no asignado</>}
                    </div>
                </div>
                <div className="datosList">
                    <div>
                        {user.kyc ? <div className='text-success'> <i className='bi bi-check-circle' /> Usuario verificado con KYC</div> : <div className='text-danger'> <i className='bi bi-x' /> Usuario no verificado (KYC)</div>}
                    </div>
                </div>
            </div>
        </motion.div>
        <hr className='my-5' />

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}
            className='p-3 mb-5'>
            <h4 className={ctx.darkMode ? 'textDark' : "textLight"}>KYC (Conoce a tu Cliente)</h4>
            <div className={ctx.darkMode ? 'textDark pb-4' : "textLight pb-4"}>
                {!user.kyc ? <>
                    La verificación se valida en un promedio de 30 minutos,
                    Por favor envie los 3 requisitos en los siguientes campos.
                </>
                    : "Cuenta Verificada"}<br />

            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-sm-6 col-md-4'>
                        <div className='datosList kyc'>
                            <div className={user.kyc ? 'kycVerificationSuccess' : "kycVerification"}>
                                <b>Foto frontal del documento <span className='info'><i className="bi bi-info-circle"></i></span></b>
                                <h1> {!user.kyc ? <i className='bi bi-exclamation-triangle' /> : <i className='bi bi-check-circle'></i>}  </h1>
                                <p>{user.kyc ? 'Verificada' : "No verificada"}</p>
                                {previewImage && <img src={previewImage} alt="Preview" className='preview-kyc pb-4' />}

                                {errorMessage1 && <p className='w-100 p-3 text-danger'>
                                    <i className='bi bi-exclamation-triangle'></i> {errorMessage1}
                                </p>}
                                {!user.kyc && <>
                                    <label htmlFor='file-input' className="file-label">
                                        <i className='bi bi-image' />
                                        {/* 'linear-gradient(311deg,rgba(37,22,125,1) 0%' */}
                                        {selectedImage1 ? < > Cambiar Imagen </> : <> Cargar Imagen </>}
                                    </label>
                                </>}
                                <input accept="image/*" onChange={(e) => handleImageChange(e, setSelectedImage1, setPreviewImage, setErrorMessage1)} id="file-input" type="file" style={{ display: "none" }} />
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4'>
                        <div className='datosList kyc'>
                            <div className={user.kyc ? 'kycVerificationSuccess' : "kycVerification"}>
                                <b>Foto posterior del documento <span className='info'><i className="bi bi-info-circle"></i></span></b>
                                <h1> {!user.kyc ? <i className='bi bi-exclamation-triangle' /> : <i className='bi bi-check-circle'></i>}  </h1>
                                <p>{user.kyc ? 'Verificada' : "No verificada"}</p>
                                {previewImage2 && <img src={previewImage2} alt="Preview" className='preview-kyc pb-4' />}
                                {errorMessage2 && <p className='w-100 p-3 text-danger'>
                                    <i className='bi bi-exclamation-triangle'></i> {errorMessage2}
                                </p>}
                                {!user.kyc && <>
                                    <label htmlFor='file-input2' className="file-label">
                                        <i className='bi bi-image' />
                                        {selectedImage2 ? < > Cambiar Imagen </> : <> Cargar Imagen </>}
                                    </label>
                                    <input accept="image/*" onChange={(e) => handleImageChange(e, setSelectedImage2, setPreviewImage2, setErrorMessage2)} id="file-input2" type="file" style={{ display: "none" }} />
                                </>}
                            </div>
                        </div>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4'>
                        <div className='datosList kyc'>
                            <div className={user.kyc ? 'kycVerificationSuccess' : "kycVerification"}>
                                <b>Foto con tu documento (selfie) <span className='info'><i className="bi bi-info-circle"></i></span></b>
                                <h1> {!user.kyc ? <i className='bi bi-exclamation-triangle' /> : <i className='bi bi-check-circle'></i>}  </h1>
                                <p>{user.kyc ? 'Verificada' : "No verificada"}</p>
                                {previewImage3 && <img src={previewImage3} alt="Preview" className='preview-kyc pb-4' />}
                                {errorMessage3 && <p className='w-100 p-3 text-danger'>
                                    <i className='bi bi-exclamation-triangle'></i> {errorMessage3}
                                </p>}
                                {!user.kyc && <>
                                    <label htmlFor='file-input3' className="file-label">
                                        <i className='bi bi-image' />
                                        {selectedImage3 ? < > Cambiar Imagen </> : <> Cargar Imagen </>}
                                    </label>
                                    <input accept="image/*" onChange={(e) => handleImageChange(e, setSelectedImage3, setPreviewImage3, setErrorMessage3)} id="file-input3" type="file" style={{ display: "none" }} />
                                </>
                                }

                            </div>
                        </div>
                    </div>
                    {!user.kyc &&
                        <div className="row">
                            <div className="col-12 text-center p-4">
                                {selectedImage1 && selectedImage2 && selectedImage3 ?
                                    <button className='btn btn-primary' onClick={handleUpload}> <i className='bi bi-upload' />  Subir Imágenes</button> :
                                    <button className='btn btn-primary-disabled' disabled> <i className='bi bi-upload' /> Debe cargar todas la imagenes</button>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </motion.div>
    </>)
}
export default Perfil