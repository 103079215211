const AddressComponent = () => {
    return ( 
        <div className="efectivoWrap">
            <div className='text-light'> Efectivo en Ventanilla </div>
            <div>
                Av del Ejército 768 Miraflores Lima, Perú <br></br>
                <a target="_blank" className='linkMap' href="https://www.google.com.pe/maps/place/bitcoins+y+criptomonedas+en+Per%C3%BA/@-12.114006,-77.0497125,17z/data=!4m5!3m4!1s0x9105c9eb5422ccad:0x1f7e302001fc68!8m2!3d-12.114006!4d-77.0475238?hl=es-419&shorturl=1" rel="noreferrer"> <i className='bi bi-geo-alt'></i> Ver en google Map</a>
            </div>
        </div>
     );
}
 
export default AddressComponent;