import { useState, useEffect } from 'react';
import styled from 'styled-components'

const Btn = styled.button`
    background: rgb(37,22,125);
    background: linear-gradient(311deg, rgba(37,22,125,1) 0%, rgba(111,16,107,1) 82%);
   
    color: #fff;
    position: fixed;
    bottom: 0;
    margin: 10px;
    margin-bottom: 30px;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;

    :hover{
        border: 3px solid #57235d;
        box-shadow: -2px -2px 20px #7d168f;
    }

`

const ButtonGame = ({setModal}) => {

    const [ scrollBtn, setScrollBtn ] = useState(null)

    useEffect(() => {
        window.addEventListener('scroll', () => setScrollBtn(window.scrollY));
      }, [scrollBtn]);

    return (
        <Btn style={{ opacity: scrollBtn > 2370 ? 0 : 1 }} onClick={ () => setModal(true)}>Game</Btn>
    )
}

export default ButtonGame;