import tokensBSC from "../tokens/bsc/tokensBSC"
import tokensETH from "../tokens/eth/tokensETH"
import tokensMATIC from "../tokens/matic/tokensMATIC"
import Web3 from "web3"
const provider = window.ethereum
const web3 = new Web3(provider)

const usdtContractBSC = new web3.eth.Contract(tokensBSC.usdt.abi, tokensBSC.usdt.address)
const busdContractBSC = new web3.eth.Contract(tokensBSC.busd.abi, tokensBSC.busd.address)

const usdtContractETH = new web3.eth.Contract(tokensETH.usdt.abi, tokensETH.usdt.address)
const busdContractETH = new web3.eth.Contract(tokensETH.busd.abi, tokensETH.busd.address)

const usdtContractMATIC = new web3.eth.Contract(tokensMATIC.usdt.abi, tokensMATIC.usdt.address)
const busdContractMATIC = new web3.eth.Contract(tokensMATIC.busd.abi, tokensMATIC.busd.address)

const selectorDeRed = (id) => {
    //red de binance
    if (id == 56) return { usdtContract: usdtContractBSC, busdContract: busdContractBSC }
    
    //red de eth
    if (id == 1) return { usdtContract: usdtContractETH, busdContract: busdContractETH }
    
    //red de matic
    if (id == 137) return { usdtContract: usdtContractMATIC, busdContract: busdContractMATIC }
    
}
export default selectorDeRed