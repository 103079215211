import { DataContext } from "../../context/DataContext"
import { useContext } from "react"
import { motion } from 'framer-motion/dist/framer-motion'
const CoinsModal = () => {

    const ctx = useContext(DataContext)
    const { coinList } = useContext(DataContext)

    return (<>
        {ctx._coinsModal &&
            <motion.div className="modal-wrapper" animate={{ opacity: 1 }} transition={{ duration: 0.3 }} exit={{ opacity: 1 }}>
                <motion.div className="modal-display" initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }} transition={{ type: 'spring', duration: .3 }} exit={{ opacity: 0, scale: 0 }} >
                    <div className="modalHeader">
                        <div>
                            Elija una moneda
                        </div>
                        <div>
                            <i className="bi-x x" onClick={() => ctx._setCoinsModal(false)} />
                        </div>
                    </div>
                    <div className="modalContent">
                        {coinList && coinList.map((item, index) => {
                            return (
                                <div key={index}>
                                    {item.symbol == ctx.selectedCoin1.symbol || item.symbol == ctx.selectedCoin2.symbol ? <>
                                        <div className='coinListInactive'>
                                            <img src={item.img} alt="" />
                                            <div className='mx-2'>
                                                <div>
                                                    <p>{item.name}</p>
                                                    <h4>{item.symbol}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                        <div onClick={() => {
                                            ctx._setCoinsModal(false)
                                            ctx.direction === 1 ? ctx.setSelectedCoin1(item) : ctx.setSelectedCoin2(item)
                                        }} className='coinList'>
                                            <img src={item.img} alt="" />
                                            <div className='mx-2'>
                                                <div>
                                                    <p>{item.name}</p>
                                                    <h4>{item.symbol}</h4>
                                                </div>
                                            </div>
                                        </div>

                                    }
                                </div>
                            )
                        })}
                    </div>
                </motion.div>
            </motion.div>
        }
    </>)
}
export default CoinsModal


/* 
<div onClick={() => { executeSelection(item) }} className='coinList'>
    <img src={item.img} alt="" />
    <div className='mx-2'>
        <div>
            <p>{item.name}</p>
            <h4>{item.text}</h4>
        </div>
    </div>
</div>} */