import axios from "axios"
import errorManager from "./errorManager/errorManager"
//config authorization token 
const token = localStorage.getItem('loginAuth')
axios.defaults.headers = {'authorization': `Bearer ${token}`}

const req = {
    get:async (url)=>{
        try {
            const res = await axios.get(url)
            return res.data
        } catch (error) {
            errorManager(error)
            return { error: true, msg: `${error}`}
        }
    },
    put:async (url, body)=>{
        //console.log(url, body)
        try {
            const res = await axios.put(url, body)
            return res.data
        } catch (error) {
            errorManager(error)
            return { error: true, msg: `${error}`}
        }
    },
    post:async (url,body)=>{
        try {
            const res = await axios.post(url,body)
            
            return res.data
        } catch (error) {
            errorManager(error)
            return { error: true, msg: `${error}`}
        }
    }
}
export default req