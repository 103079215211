import { useState, useEffect } from 'react';
import './modal.css';
import Card from '../cardMemory/Card';

import bitcoin from '../../img/crypto/bitcoin.webp';
import ethereum from '../../img/crypto/ethereum.webp';
import tether from '../../img/crypto/tether.webp';
import riple from '../../img/crypto/riple.webp';
import dogecoin from '../../img/crypto/dogecoin.webp';
import binance from '../../img/crypto/binance.webp';
import matic from '../../img/crypto/matic.webp';
import tron from '../../img/crypto/tron.webp';

import data from './data';
import Confetti from 'react-confetti';
import { motion } from 'framer-motion/dist/framer-motion'

const Modal = ({setModal, cards}) => {

    const [ par, setPar ] = useState({ 
        par1: {id : null, status: false, name: ''},
        par2: {id: null, status: false, name: ''}
    });
    const [dataCards, setDataCards] = useState([]);

    const [ matches, setMatches] = useState(0);

    const [ win, setWin] = useState(false);

    const compareWin = () => data.every( elemento => elemento.status === true)

    useEffect(() => {
        reset();
    }, []);

    const handleEnd = async () => {
        setWin(false);
        setMatches(0);
        reset();
    }  

    const rand = (max, min) =>  Math.random() * (max - min) + min;

    const reset = () => { 
        let dataArray = [];
        data.map( d => {
            //la propiedad status a false
            d.status = false;
            let numRand = Math.round(rand(1, 16));  
            if(dataArray.length === 16) return;
            while(dataArray.map(card => card.id).includes(numRand)){
                numRand = Math.round(rand(1, 16));
            }
            dataArray.push(data[numRand - 1]);
        })
        setDataCards(dataArray);
        setPar({ 
            par1: {id : null, status: false, name: ''},
            par2: {id: null, status: false, name: ''}
        });
      }

    const crypto = {
        'bitcoin': { name: bitcoin },
        'ethereum': { name: ethereum },
        'tether': { name: tether },
        'riple': { name: riple },
        'dogecoin': { name: dogecoin },
        'binance': { name: binance },
        'matic': { name: matic },
        'tron': { name: tron},
    } 
    
    return (<>
        {win && <Confetti />}
        <motion.div 
            className="modal-content-memory"
            animate={{ opacity: 1, backgroundColor: "#000" }}
            transition={{ duration: 0.3}}
            exit={{ opacity: 1, backgroundColor: "#000" }}
        >
            <motion.div 
                className="modal-memory"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ type: 'spring', duration: .3}}
                exit={{ opacity: 0, scale: 0 }} 
            >
                <div className="modal-header-memory">Memoriza los pares 😁</div>
                <div className="hr-memory"></div>
                <div className="modal-score-memory">
                    <div className='score-movie-memory'>
                        <span>Total de movimientos</span>
                        <span className='score-points-memory'>{matches}</span>
                    </div>
                </div>
                <div className="modal-body-memory">
                    {dataCards.length > 0 && (
                        dataCards.map( d => (
                            <Card key={d.id} data={d} setPar={setPar} par={par} setMatches={setMatches} matches={matches} picture={crypto[d.name.toLowerCase()].name} compareWin={compareWin} setWin={setWin} />
                        ))
                    )}
                </div>
                <div className="modal-footer-memory">
                    <button onClick={() => handleEnd()}>
                        Reiniciar Juego
                    </button>
                    <button onClick={ () => { handleEnd(); setModal(false); setWin(false); }}>
                        Terminar Juego
                    </button>
                </div>
            </motion.div>
        </motion.div>
    </>);
}
 
export default Modal;