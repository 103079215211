/**
 * @fileoverview manejo para localstorage
 * @version 1
 * @author Jason Hernandez <kaltre10@gmail.com>
 * @copyright kaltre@gmail.com
 * ----
 */


/**
 * Obtiene los datos de localStorage 
 * @param {string} nameLocalStorage
 * @return {object}  
 */
const getStorage = (nameLocalStorage) => localStorage.getItem(nameLocalStorage)

/**
 * Modificar los datos de localStorage
 * @param {string} nameLocalStorage
 * @param {string} newData  
 */
const setStorage = (nameLocalStorage, newData) => {
    localStorage.setItem(nameLocalStorage, newData)
}

export {
    getStorage,
    setStorage,
}
    
