const ToDate = ({desde, hasta, setDesde, setHasta}) => {

    return ( 
        <div className="date-container">
            <label>Desde</label>
            <input type='date' name='desde' value={desde} onChange={e => setDesde(e.target.value)}/>
            <label>Hasta:</label>
            <input type='date' name='hasta' value={hasta} onChange={e => setHasta(e.target.value)}/>
        </div>
     );
}
 
export default ToDate;