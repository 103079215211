export const isValidEmail = email => {
    // Expresión regular para validar correo electrónico
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    return emailPattern.test(email);
  }

 export const isValidPassword = password => {
     // Al menos una mayúscula, un número y 6 caracteres
    const passwordPattern = /^(?=.*[A-Z])(?=.*\d).{6,}$/; 
    return passwordPattern.test(password);
  }