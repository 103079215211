import { useContext } from 'react'
import styles from '../../css/landing/card.module.css'
import { DataContext } from '../../context/DataContext'
const Card = ({children, minWidth, minHeight}) => {

    const { darkMode } = useContext(DataContext)

    return ( 
        <div style={{ 
                    minWidth: `${minWidth}%`,
                    maxWidth: `${minWidth}%`,
                    minHeight: `${minHeight}px`,
                    maxHeight: `${minHeight}px`
                }} 
            className={darkMode ? styles.card : styles.cardLight }>
            {children}
        </div>
     );
}
 
export default Card;