import Web3 from "web3"
const web3 = new Web3(window.ethereum)
const getTransactionConfig = async (chainId, value) => {
    let gas, gasPrice, convertedWeis
    if (chainId == 56) {
        convertedWeis = web3.utils.toWei(value.toString(), "ether")
        gas = "150000"
        gasPrice = web3.utils.toWei("7", "gwei")
    }

    if (chainId == 1) {
        convertedWeis = web3.utils.toWei(value.toString(), "mwei")
        gas = "400000"
        gasPrice = web3.utils.toWei("9", "gwei")
    }

    if (chainId == 137) {
        convertedWeis = web3.utils.toWei(value.toString(), "mwei")
        gas = "1000000"
        gasPrice = web3.utils.toWei("40", "gwei")
    }

    let config = {
        convertedWeis, gasPrice, gas
    }
    return config
}
export default getTransactionConfig