export const useWeb3 = () => {

    const getWallet = async () => {
        const res = await window.ethereum.request({ method: "eth_requestAccounts" })
        //alert("ddfdg")
        ////console.log(res)
        return res
    }

    /* const getTokens = async () => {
        const wallet = await getWallet()
        const balanceOf = await contract.methods.balanceOf(wallet).call()
        setUsdtBalance(web3.utils.fromWei(balanceOf, "ether"))
    } */

    /* const send = async (_amount) => {
        const amount = _amount.toString()
        const from = await getWallet()
        const res = await contract.methods.transfer(ownerAddress, web3.utils.toWei(amount, "ether")).send({ from })
        return res
    } */

    const getChainId = async () => {
        const id = await window.ethereum.request({ method: 'eth_chainId' })
        return id
    
    }

    return { getWallet, getChainId }
}