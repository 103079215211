import { useContext } from "react"
import { DataContext } from "../../context/DataContext"
import { useTransactions } from '../../hooks/useTransactions'
import FilterTransaction from '../modals/filterTransaction'
import DetailsTransaction from '../../components/detailsTransaction/detailsTransaction'
import Loader from '../preLoad/PreLoad'
import './transactionDashboard.css'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'

const TransactionsDashboard = () => {

    const { darkMode, redName } = useContext(DataContext);
    const { transactions, desde, hasta, transactionSelected, load, setDesde, setHasta, dataStatus, modal, filter, showDetails, setModal, setFilter, getTransactions, setShowDetails, handleShowTransaction } = useTransactions()
    const sliceFunction = (val)=>{
        if(val){
            return val.slice(-6) 
        }else{
            return 0
        }
    }
    return <>
        <AnimatePresence>
            {modal && <FilterTransaction filter={filter} desde={desde} hasta={hasta} setDesde={setDesde} setHasta={setHasta} setModal={setModal} setFilter={setFilter} getTransactions={getTransactions} />}
        </AnimatePresence>

        <div className="transactionsMenu">
        {!showDetails 
        ? 
            <>
                <h4 className={darkMode ? "text" : "textLight"}>Historial <br />de transacciones</h4>
                <button
                    onClick={() => setModal(true)}> <i className="bi bi-funnel-fill" /> {dataStatus[filter].msg}
                </button>
            </>
        : 
            <>
                <h4 className={darkMode ? "text" : "textLight"}>Detalle de <br />Transacción #
                {sliceFunction(transactionSelected._id)}</h4>
                <button
                    onClick={() => setShowDetails(false)}> <i className="bi bi-caret-left-fill" />Volder </button>
            </>

        }
        </div>

        <AnimatePresence>{load && (<Loader />)}</AnimatePresence>
        {transactions.length === 0 && !load && <motion.h5 className={darkMode ? "text text-center" : "textLight text-center"} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
            No hay transacciones
        </motion.h5>}
        <AnimatePresence>
            {transactions && !showDetails && transactions.map(item => {
                
                let date = new Date(item.date);
                let time = `${String(date.getFullYear())}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

                return (

                    <motion.div 
                        key={item._id} 
                        className="transactionItem" 
                        initial={{ opacity: 0, x: 200, y: -100, scale: .7 }} 
                        animate={{ opacity: 1, x: 0, y: 0, scale: 1 }} 
                        transition={{ type: 'spring', duration: .3 }} 
                        onClick={() => {
                            handleShowTransaction(item)
                        }}
                    >
                        <div className="transactions-container d-none d-sm-none d-md-none d-lg-flex">
                            <div className="transactions-item"><span>N° Transacción: </span><span>{item._id.slice(-6)}</span></div>
                            <div className="transactions-item"><span>Enviaste: </span><span>{item.amountSend} {item.moneySend}</span></div>
                            <div className="transactions-item"><span>Recibes: </span><span>{item.amountReceive} {item.moneyReceive}</span></div>
                            <div className="transactions-item"><span>Fecha: </span><span>{time}</span></div>
                            <div className="transactions-item"><span>Red: </span><span>{item.network}</span></div>
                            <div className="transactions-item"><span>Banco recibe: </span><span>{item.bank ? item.bank.name : "n/a"}</span></div>
                            {/* <div className="transactions-item"><span>Estado: </span><span>{dataStatus[item.status].msg}</span></div> */}
                            <div className="transactions-item"><span className={`transaction-icon ${dataStatus[item.status].class}`}>{dataStatus[item.status].icon}</span><span>{dataStatus[item.status].msg}</span></div>
                        </div>
                        <div className="d-md-block d-lg-none">
                            <div className='container pt-3'>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="transactions-item"><span>N° Transacción: </span><span>{item._id.slice(-6)}</span></div>
                                        <div className="transactions-item"><span>Enviaste: </span><span>{item.amountSend} {item.moneySend}</span></div>
                                        <div className="transactions-item"><span>Recibes: </span><span>{item.amountReceive} {item.moneyReceive}</span></div>
                                        <div className="transactions-item"><span>Fecha: </span><span>{time}</span></div>
                                    </div>
                                    <div className="col-6">
                                        <div className="transactions-item"><span>Red: </span><span>{item.network}</span></div>
                                        <div className="transactions-item"><span>Banco recibe: </span><span>{item.bank ? item.bank.name : "n/a"}</span></div>
                                        <div className="transactions-item"><span>Estado: </span><span>{dataStatus[item.status].msg}</span><span className={`transaction-icon ${dataStatus[item.status].class}`}>{dataStatus[item.status].icon}</span></div>
                                    </div>
                                    {/* <div className="col-12">
                                        <div className="transactions-item"><span className={`transaction-icon ${dataStatus[item.status].class}`}>{dataStatus[item.status].icon}</span></div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )

            })}
        </AnimatePresence>
        {showDetails &&
            <DetailsTransaction redName={redName} setShowDetails={setShowDetails} transactionSelected={transactionSelected} dataStatus={dataStatus} />
        }

    </>
}
export default TransactionsDashboard

/* amountReceive: 11.41
amountSend: 3
date: "2022-08-06T04:11:57.551Z"
id: "0xa2a679bf2edf34d7cc65ae702963459f958be585f5266f0206b415c566b16662"
moneyReceive: "SOLES"
moneySend: "USDT"
network: "BSC"
status: 1
wallet */