import bsc from '../../img/redes/bsc.webp'
import eth from '../../img/redes/eth.webp'
import matic from '../../img/redes/matic.webp'
import metamask from '../../img/metamask.webp'
import { useContext } from 'react'
import { DataContext } from '../../context/DataContext'
const ModalRedes = () => {
    const { connectChain, setRedesModal } = useContext(DataContext)
    return (<div className="modal-wrapper">
        <div className="modal-display-redes text-center">
            <div> 
                <div>
                    <img className='meta' height={"60px"} src={metamask} alt="" />
                </div>
                <div>
                    <p>Elija la red que desea usar</p>
                </div>
                <div className='redes-img'>
                    <div className='redImages'>
                        <img onClick={() => connectChain(56)} src={bsc} alt="" />
                        BSC
                    </div>
                    <div className='redImages'>
                        <img onClick={() => connectChain(1)} src={eth} alt="" /> 
                        ETH
                    </div>
                    {/* <div className='redImages'>
                        <img onClick={() => connectChain(137)} src={matic} alt="" /> 
                        POLYGON
                    </div> */}
                </div>
                <div>
                    <button className='px-5 mt-4' onClick={() => setRedesModal(false)}> Cancelar </button>
                </div>
            </div>
        </div>
    </div>)
}
export default ModalRedes