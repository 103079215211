import { DataContext } from "../../context/DataContext"
import { useContext } from "react"
import { motion } from 'framer-motion/dist/framer-motion'

const AddBankModal = () => {

    const { sendForm,
        setAddBanks, setName, setMoney,
        setType, setNumber, setTitular,
        validForm, addBanks
    } = useContext(DataContext)

    return (<>{addBanks && <>
        <motion.div
            className='bgtransparentDark'
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 1 }}
        >
            <motion.div
                className='modal-display'
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ type: 'spring', duration: .3 }}
                exit={{ opacity: 0, scale: 0 }}
            >
                <div className='modalHeader'>
                    <div>
                        Agregar bancos
                    </div>
                    <div>
                        <i onClick={() => setAddBanks(false)} className='bi-x x'></i>
                    </div>
                </div>
                <div className='backOptions'>
                <form onSubmit={(e) => sendForm(e)}>
                    
                        <span> Elija su banco </span>
                        <select onChange={(e) => setName(e.target.value)} className='form-control' name="" id="">
                            <option value="1"> - Elija un banco -</option>
                            <option value="BCP">BCP</option>
                            <option value="BBVA">BBVA</option>
                            <option value="Scotiabank">ScotiaBank</option>
                            <option value="Interbank">Interbank</option>
                        </select>

                        <span> Moneda </span>
                        <select onChange={(e) => setMoney(e.target.value)} className='form-control' name="" id="">
                            <option value="1"> - Elija una moneda - </option>
                            <option value="soles">Soles</option>
                            <option value="dolares">Dolares</option>
                        </select>

                        <span> Tipo de cuenta </span>
                        <select onChange={(e) => setType(e.target.value)} className='form-control' name="" id="">
                            <option value="1"> - Elija un tipo de cuenta - </option>
                            <option value="Ahorros">Ahorros</option>
                            <option value="Corriente">Corriente</option>
                        </select>

                        <span> Numero de cuenta </span>
                        <input id="nroCuenta" type="text" minLength="6" maxLength="13" pattern="[0-9]{6,13}" onChange={(e) => setNumber(e.target.value)} className='form-control' placeholder='Escriba su numero de cuenta' title="Solo permite número de cuenta de banco valido" required />

                        <span> Titular </span>
                        <input id="titular" type="text" minLength="3" maxLength="60" pattern="[ A-Za-z]{3,60}" onChange={(e) => setTitular(e.target.value)} className='form-control' placeholder='Nombre del titular' title="Solo permite letras. Tamaño mínimo: 3. Tamaño máximo: 60" required />

                        {!validForm ?
                            <button className="saveBtn"> Guardar </button> :
                            <button className="saveBtnDisabled" disabled> Guardar </button>
                        }
                    </form>
                </div>
            </motion.div>
        </motion.div>
    </>}
    </>
    )
}
export default AddBankModal