import { useContext, useEffect } from "react";
import { DataContext } from "../../../context/DataContext";
// import LogoBank from '../../logoBank/logoBank'
import logoBsc from '../../../img/redes/bsc.webp'
import wallet from '../../../img/wallet.webp'
import BankComponent from '../../bankComponent/BankComponent'
import AddressComponent from '../../AddressComponent/AddressComponent'

const Transfer = () => {
    const ctx = useContext(DataContext)
   
    useEffect(() => {
        ctx.checkAmountSbs()
    }, [])

    return (<>
        {ctx.sendMethod === 5 && <div className="p-2">
            Método de Pago
            <h4 className="text-warning"> Transferencia </h4>

            {ctx.adminBank.name && 
                <BankComponent dataBank={ctx.adminBank}/>
            }

            <p className="mt-2">Ingrese el número de operación</p>

            <input id="nroOperacion" onChange={(e) => ctx.setTransactionId(e.target.value)} type="text" className="transaction-input w-100 mb-3" value={!ctx.transactionId ? "" : ctx.transactionId} />

            Método donde recibe

            {ctx.reciveMethod === 1 ? (<>
                    <h4 className="text-warning"> Transferencia </h4>
                    <BankComponent dataBank={ctx.reciveBank}/>
                    </>):
                (!ctx.reciveUser) && (<><h4 className="text-warning"> Efectivo </h4><AddressComponent /></>)
                    }

            {ctx.reciveUser && (
                <div className={"btnTransaction mt-3"}>
                <div className='btnInTrans px-2'>
                    <div>
                        <img height="30px" src={ctx.reciveMethod === 2 ? logoBsc : wallet} alt="" />
                    </div>
                    <div className='mx-2'>
                        {ctx.reciveMethod === 2 ? "Binance Pay" : "Wallet Externa"}
                    </div>
                </div>
                </div>
            )}

            

            {ctx.reciveUser && ctx.reciveMethod === 2 && <>
                Correo ingresado
                <h4 className="text-warning"> {ctx.reciveUser} </h4>
            </>}

            {ctx.reciveMethod === 3 && <>
                Wallet ingresada
                <h4 className="text-warning"> {ctx.reciveUser} </h4>
                Red
                <h4 className="text-warning">{ctx.redName[ctx.reciveNetwork]}</h4>
            </>}

            {ctx.dataSbs.status && (<>
                    <div className="d-flex" style={{
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <span className='info' onClick={() => ctx.setAlertModal({ status: true, text: 'Cantidades equivalente mayores a 5000 USD debe agregar información adicional', type: "warning", link: false })}><i className="bi bi-info-circle"></i></span>
                        <p className='transaction-text my-2'>Origen de los Fondos:</p>
                    </div>
                    
                    <div className='check-confirm my-2'>   
                        <select onChange={e => ctx.setDataSbs({ ...ctx.dataSbs, origen: e.target.value })} className='form-control'>
                            <option value="">- Seleccione -</option>
                            <option value="001" selected={ctx.dataSbs.origen === "001"}>Ahorros</option>
                            <option value="002" selected={ctx.dataSbs.origen === "002"}>Alquiler de Bienes Muebles</option>
                            <option value="003" selected={ctx.dataSbs.origen === "003"}>Alquiler de Bienes Inmuebles</option>
                            <option value="004" selected={ctx.dataSbs.origen === "004"}>Donacion/Sorteo</option>
                            <option value="005" selected={ctx.dataSbs.origen === "005"}>Ingreso por Trabajo Independiente</option>
                            <option value="006" selected={ctx.dataSbs.origen === "006"}>Ingreso por Trabajo Dependiente</option>
                            <option value="007" selected={ctx.dataSbs.origen === "007"}>Ingresos por regalia</option>
                            <option value="008" selected={ctx.dataSbs.origen === "008"}>Prestamos</option>
                            <option value="009" selected={ctx.dataSbs.origen === "009"}>Venta de Bien Mueble</option>
                            <option value="010" selected={ctx.dataSbs.origen === "010"}>Venta de Bien Inmueble</option>
                            <option value="099" selected={ctx.dataSbs.origen === "099"}>Otros</option>
                        </select>
                    </div>
    
                    <div className="d-flex" style={{
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <span className='info' onClick={() => ctx.setAlertModal({ status: true, text: 'Cantidades equivalente mayores a 5000 USD debe agregar información adicional', type: "warning", link: false })}><i className="bi bi-info-circle"></i></span>
                        <p className='transaction-text my-2'>Persona Política ?:</p>
                    </div>
                    <div className='check-confirm my-2'>
                        <select onChange={e => ctx.setDataSbs({ ...ctx.dataSbs, politico: e.target.value })} className='form-control'>
                            <option value="">- Seleccione -</option>
                            <option value="NO" selected={ctx.dataSbs.politico === "NO"}>NO</option>
                            <option value="SI" selected={ctx.dataSbs.politico === "SI"}>SI</option>
                        </select>
                    </div>
                </>)}

            <div className='check-confirm mb-4'>
                <input checked={ctx.confirmInfo} onChange={(e) => ctx.setConfirmInfo(e.target.checked)} id="flexCheckDefault" type="checkbox" name="" className="mx-2 form-check-input" />
                <label className="form-check-label" htmlFor='flexCheckDefault'>
                    Confirmo que la información es correcta
                </label>
            </div>

            {(ctx.confirmInfo && ctx.transactionId && !ctx.dataSbs.status) || 
                (ctx.confirmInfo && ctx.transactionId && ctx.dataSbs.status && ctx.dataSbs.politico !== '' && ctx.dataSbs.origen !== '') ?
                <button onClick={ctx.sendTransaction} className='btnContinueSuccess w-100'>
                    Continuar <i className="bi bi-arrow-right-square-fill" />
                </button>
                :
                <button className='btnContinue w-100'>
                    Continuar <i className="bi bi-arrow-right-square-fill" />
                </button>
            }
        </div>}
    </>);
}

export default Transfer;