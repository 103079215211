import LogoBank from "../logoBank/logoBank"

const BankComponent = (props) => {
    
    const { name, number, titular, type, money } = props.dataBank;

    return ( 
        <div style={{margin: "0 auto", display: "flex", justifyContent: "center"}}  className='mt-2'>
                <div className="bList">
                    <div className="bListIn">
                        <LogoBank name={name} />
                        <div>
                            <div>
                                <span>{name} | </span>
                                <span> {number}</span>   
                            </div>
                            <span>{titular}</span>
                            <div style={{display: "flex", justifyContent: "space-between", gap: "5px"}}>
                                <span>{type}</span>{" "}
                                <span>{money}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
     );
}
 
export default BankComponent;