import { useContext, useEffect } from "react"
import { DataContext } from "../../../context/DataContext"
// import LogoBank from "../../logoBank/logoBank"
import bank from '../../../img/bank.png'
import BankComponent from '../../bankComponent/BankComponent'
import AddressComponent from '../../AddressComponent/AddressComponent'
const Fiat2 = () => {
    const ctx = useContext(DataContext)

    useEffect(() => {
        ( async () => {
            const banksAdmin = await ctx.getAdminBanks();
            ctx.setBanksAdmin(banksAdmin);
        })()
    }, []);

    return (<>
        <button onClick={() => { ctx.setReciveMethod(1); ctx.setConfirmRecive(false); ctx.setReciveBank(ctx.nullId); ctx.setReciveBankId(ctx.nullId); }} className={ctx.reciveMethod == 1 ? "btnTransactionSuccess" : "btnTransaction"}>
            <div className='btnInTrans'>
                <div>
                    <i className="item-method bi bi-bank" style={{ fontSize: "30px" }}></i>
                </div>
                <div className='mx-2'>
                    Transferencia Bancaria
                </div>
            </div>
            <div className={ctx.reciveMethod == 1 ? "transFontSuccess" : "transFont"}>
                <i className='bi bi-check-circle'></i>
            </div>
        </button>

        <button onClick={() => { ctx.setReciveBank(ctx.nullId); ctx.setReciveBankId(ctx.nullId); ctx.setReciveMethod(2); ctx.setConfirmRecive(false) }} className={ctx.reciveMethod == 2 ? "btnTransactionSuccess" : "btnTransaction"}>
            <div className='btnInTrans'>
                <div>
                    <i className="item-method bi bi-cash-coin" style={{ fontSize: "30px" }}></i>
                </div>
                <div className='mx-2'>
                    Efectivo en ventanilla
                </div>
            </div>
            <div className={ctx.reciveMethod == 2 ? "transFontSuccess" : "transFont"}>
                <i className='bi bi-check-circle'></i>
            </div>
        </button>
        {ctx.reciveMethod == 1 && <>
            <div className="bank-container">
                <div onClick={() => ctx.setAddBanks(true)} className="bank-item">
                    <img src={bank} alt='' />
                    Agregar Banco
                </div>
                <div onClick={() => ctx.setSelectBank(true)} className="bank-item">
                    <img src={bank} alt='' />
                    Seleccionar Banco
                </div>
            </div>
        </>}

        {ctx.reciveBank !== ctx.nullId && ctx.reciveBank != false && 
            <BankComponent dataBank={ctx.reciveBank} />
        }

        {ctx.reciveMethod == 2 && <>
            <div className="mt-3">
              <input id="CheckDefault" checked={ctx.confirmRecive} onChange={(e) => { ctx.setConfirmRecive(e.target.checked); ctx.setReciveBankId(ctx.nullId); ctx.setReciveBank(ctx.nullId) }} type="checkbox" name="" className="mx-2"  />
              <label className="mb-2 form-check-label" htmlFor='CheckDefault'>
                Confirmo retirar por Ventanilla
              </label> 
            </div>
            <AddressComponent />
        </>
        }

        {/* {//console.log({
            reciveMethod: ctx.reciveMethod,
            confirmRecive: ctx.confirmRecive,
            reciveMetho2d: ctx.reciveMethod,
            reciveBank: ctx.reciveBank
        })} */}

        {(ctx.reciveMethod === 2 && ctx.confirmRecive) | (ctx.reciveMethod === 1 && ctx.reciveBank && ctx.confirmRecive) ? <>
            <button onClick={() => ctx.setTransactionSteap(3)} className='btnContinueSuccess mt-3'>
                Continuar <i className="bi bi-arrow-right-square-fill" />
            </button>
        </> : <>
            <button className='btnContinue mt-3'>
                Continuar <i className="bi bi-arrow-right-square-fill" />
            </button>
        </>
        }
    </>)
}

export default Fiat2