import dobleArrow from '../../img/dobleArrow.svg'
import { DataContext } from '../../context/DataContext'
import { useContext } from 'react'
const InvertSwap = ()=>{
    const ctx = useContext(DataContext)
    return (
        <div onClick={ctx.invert} className="dobleArrowWrap">
            <div className="dobleArrowCircle">
                <img src={dobleArrow} alt="" />
            </div>
        </div>
    )
}
export default InvertSwap