import { useContext } from "react"
import { DataContext } from "../../context/DataContext"
import { motion } from 'framer-motion/dist/framer-motion'
import LogoBank from "../logoBank/logoBank"
const SelectBankModal = () => {
    const ctx = useContext(DataContext)

    return (<>
        {ctx.selectBank && <>
            <motion.div className='bgtransparentDark' animate={{ opacity: 1 }} transition={{ duration: 0.3 }} exit={{ opacity: 1 }}>
                <motion.div className='modal-display' initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }} transition={{ type: 'spring', duration: .3 }} exit={{ opacity: 0, scale: 0 }}>
                    <div className="modalHeader">
                        <div>
                            <i className="bi bi-bank"></i>  Seleccione un Banco
                        </div>
                        <div>
                            <i onClick={() => ctx.setSelectBank(false)} className='bi-x x'></i>
                        </div>
                    </div>
                    <div className="pb-3">
                        <div className="bankList">

                            {ctx.userBanks.length === 0 &&
                                <h6>No tienes bancos registrados!</h6>}

                            {ctx.userBanks.length !== 0 && ctx.userBanks.map(item => item.money === ctx.selectedCoin2.category &&
                                <div key={item._id} onClick={() => {
                                    ctx.setConfirmRecive(true)
                                    ctx.setReciveBank(item)
                                    ctx.setReciveBankId(item._id)
                                    ctx.setSelectBank(false)
                                }} className="bankItem">
                                    <LogoBank name={item.name} />
                                    <div>
                                        {item.name} - {item.money}<br />
                                        <p className='secondaryText'>
                                            {item.titular} <br />
                                            {item.number}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </>}
    </>)
}
export default SelectBankModal