import { motion } from 'framer-motion/dist/framer-motion'

const ModalConfirm = ({ text, handleAcept, handleCancel, payload }) => {

    const handle = () => {
        handleAcept(...Object.values(payload))
        handleCancel(false)
    }

    return (<motion.div 
                className="modal-wrapper"
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3}}
                exit={{ opacity: 1 }}
            >
        <motion.div 
            className="modal-display p-4"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ type: 'spring', duration: .3}}
            exit={{ opacity: 0, scale: 0 }} 
        >
            <div className="centerAlertModal">
                <i style={{ color: "rgb(223, 203, 55)" }}
                    className="bi bi-exclamation-circle-fill">
                </i>
                <p>
                    {text}
                </p>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 text-center">
                            <button style={{ backgroundColor: '#27ae60', margin: '5px' }} onClick={() => handle()}>
                                Aceptar
                            </button>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 text-center">
                            <button style={{ backgroundColor: '#e74c3c' }} onClick={() => handleCancel(false)}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    </motion.div>)
}
export default ModalConfirm