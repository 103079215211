import { useContext } from 'react'
import styles from '../../css/landing/details.module.css'
import Card from './Card'
import { motion } from 'framer-motion/dist/framer-motion'
import { DataContext } from '../../context/DataContext'

const Details = () => {

    const { darkMode } = useContext(DataContext)

    return ( 
        <div className={darkMode ? styles.details : styles.detailsLight }>
            <div className={styles.textDetails}>
                <motion.h3 
                    className={styles.titleDetails}
                    initial={{ opacity: 0, y: -100  }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >Nuestro compromiso es facilitar el acceso al mundo de las criptomonedas</motion.h3>
                <motion.h2
                    className={darkMode ? styles.subTitleDetails : styles.subTitleDetailsLight }
                    initial={{ opacity: 0, x: -150  }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                >Transfiere dinero sin fronteras <span className={styles.spanTitleDetails}> y aprovecha las ventajas de la economía digital.</span></motion.h2>
                <motion.p 
                    className={darkMode ? styles.paTitleDetails : styles.paTitleDetailsLight }
                    initial={{ opacity: 0, x: 150  }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                >Bitmarketperu es la plataforma más fácil para intercambiar activos criptos por Soles o Dólares, implementando tecnología web3 conectado directamente con el monedero Metamask</motion.p>
            </div>
            
            <div className={styles.containerCard}>
                <motion.div
                    style={{ 
                        minWidth: '25%',
                        maxWidth: '25%',
                        minHeight: '200px',
                        maxHeight: '200px',
                        display: `flex`,
                        justifyContent: 'center',
                    }}
                    initial={{ opacity: 0, x: -150 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <Card>
                        <div className={styles.cardIcon}><i className="bi bi-piggy-bank-fill"></i></div>
                        <h3 className={darkMode ? styles.titleCard : styles.titleCardLight}><i className="bi bi-plus"></i>Ahorra y mantén el control de tu dinero</h3>
                    </Card>
                </motion.div>
                
                <motion.div
                    style={{ 
                        minWidth: '25%',
                        maxWidth: '25%',
                        minHeight: '200px',
                        maxHeight: '200px',
                        display: `flex`,
                        justifyContent: 'center',
                    }}
                    initial={{ opacity: 0, y: 150 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <Card>
                        <div className={styles.cardIcon}><i className="bi bi-cash-coin"></i></div>
                        <h3 className={darkMode ? styles.titleCard : styles.titleCardLight}><i className="bi bi-plus"></i>Más de 1 millón de dólares en intercambios</h3>
                    </Card>
                </motion.div>
                <motion.div
                    style={{ 
                        minWidth: '25%',
                        maxWidth: '25%',
                        minHeight: '200px',
                        maxHeight: '200px',
                        display: `flex`,
                        justifyContent: 'center',
                    }}
                    initial={{ opacity: 0, x: 150 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                >
                    <Card>
                        <div className={styles.cardIcon}><i className="bi bi-shield-fill-check"></i></div>
                        <h3 className={darkMode ? styles.titleCard : styles.titleCardLight}><i className="bi bi-currency-bitcoin"></i>Seguridad Blockchain <br />descentralizada  y pública</h3>
                    </Card>
                </motion.div>
            </div>
        </div>
     );
}
 
export default Details;