import { useState } from "react";
import { apiUrl } from "../../services/apiService";
import axios from "axios";

const Email = () => {

    const [image, setimage] = useState(null);

    const handleFileChange = (event) => {
        
        const file = event.target.files
        //console.log(file)
        setimage(file  )
    }

    const handleUpload = () => {

        //console.log(image)
        const formData = new FormData();

        formData.append('image',image)

        /* Object.keys(image).forEach((fieldName) => {
            if (image[fieldName]) {
                formData.append(fieldName, image[fieldName]);
            }
        }); */

        //console.log( JSON.stringify (formData))

        fetch(apiUrl + 'email', {
            body:JSON.stringify(formData),
            headers: {
                'Accept':'application/json',
                'Content-Type': 'multipart/form-data',
            },
            method:'post'
        })
            .then(res =>res.json())
            .then(response => {
                alert('se envio correctamente')
                //console.log(response)
                // Manejar la respuesta
            })
            .catch(error => {
                // Manejar errores
                alert('ocurrio un error')
            });
    };


    /*  const handleUpload = (e) => {
         //console.log("subiendo")
 
         e.preventDefault()
 
         if (!image1) return alert('debe cargar todas las imagenes 1')
         if (!image2) return alert('debe cargar todas las imagenes 2')
         if (!image3) return alert('debe cargar todas las imagenes 3')
 
         const selectedImages = [image1, image2, image3]
 
         if (selectedImages.length === 2) {
 
             //console.log(selectedImages)
 
             const formData = new FormData()
 
             //console.log(formData)
 
             formData.append('image', selectedImages);
 
             axios.post(apiUrl + 'email', { image: selectedImages })
                 .then(data => {
                     alert("Se a enviado con exito")
                     //console.log(data)
                 })
                 .catch(error => {
                     alert("Ocurrio un error")
                 });
         }
     }; */

    return (
        <div className="p-4 m-5 bg-primary">

            <input type="file" multiple onChange={handleFileChange} className="form-control-file" name="image" />
            {/* <input type="file" onChange={handleFileChange} className="form-control-file" name="image" />
            <input type="file" onChange={handleFileChange} className="form-control-file" name="image" /> */}
            <button onClick={handleUpload} >Enviar</button>

        </div>
    )
}
export default Email