import { useContext, useState } from "react"
import styles from '../../css/landing/footer.module.css'
import logo from '../../img/logoAzul.webp'
import { DataContext } from '../../context/DataContext'
import instagram from '../../img/instagram.webp'
import facebook from '../../img/facebook.webp'
import youtube from '../../img/youtube.webp'
import whatsapp from '../../img/whatsapp.webp'
import Bar from './Bar'
import pdf from '../../terminos.pdf'
import ModalCustom from '../../components/modals/modalCustom'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import kycImg from '../../img/kyc-bitmarketperu.webp'

const Footer = () => {

    const { darkMode } = useContext(DataContext)
    const [infoKyc, setInfoKyc] = useState(false)

    return ( <>
        <Bar />
        <AnimatePresence>
            {infoKyc && 
                <ModalCustom setInfoKyc={setInfoKyc}>
                    <div><img src={kycImg} alt=""/></div>
                    <h5>Verificacion KYC (Conoce a Tu Cliente)</h5>
                    <p>KYC: por sus siglas en inglés “Know Your Customer (Conoce a Tu Cliente)”, es la práctica que realizan las compañías para verificar la identidad de sus clientes cumpliendo con las exigencias legales y las normativas y regulaciones vigentes. Nuestro proceso de comprobación KYC se realiza por medio de correo electrónico posteriormente de creada la cuenta con nuestra plataforma en el apartado "Perfil" con verificacion en un tiempo aproximado de 30 minutos.</p>
                    <div className="centerAlertModal">
                        <button onClick={() => setInfoKyc(false)}>
                            Entendido
                        </button>
                    </div>
                </ModalCustom>
            }     
        </AnimatePresence>   
        <motion.footer 
            // initial={{ opacity: 0 }}
            // whileInView={{ opacity: 1 }}
            // transition={{ duration: 1.5 }}
            className={darkMode ? styles.footer : styles.footerLight}
        >
            <div className={styles.logo}>
                <div className='nav-footer-logo mb-1'>
                    <div className="d-flex" style={{ gap: "5px" }}>
                        <LazyLoadImage effect="blur" width="40px" height="40px" src={logo} alt="" srcSet="" /> 
                        <div className={styles.footerLogo}>
                            <span className={`${!darkMode && "nav-text-logo"}`}>BitmarketPerú</span>
                            <span className={darkMode ? styles.ruc : styles.rucLigth}>RUC: 20609364212</span>
                        </div>
                    </div>
                    
                    <p className={darkMode ? styles.paragraph : styles.paragraphLight }>Somos la plataforma más facil para intercambiar activos cryptos por fiat.</p>

                    <div className={styles.social}>
                        <a href="#!" target="_blank"><LazyLoadImage effect="blur" className={styles.imgSocial} src={facebook} alt="" /></a>
                        <a href="#!" target="_blank"><LazyLoadImage effect="blur" className={styles.imgSocial} src={instagram} alt="" /></a>
                        <a href="#!" target="_blank"><LazyLoadImage effect="blur" className={styles.imgSocial} src={youtube} alt="" /></a>
                        <a href="https://api.whatsapp.com/send?phone=51955269142" target="_blank" rel="noreferrer"><LazyLoadImage effect="blur" className={styles.imgSocial} src={whatsapp} alt="whatsapp" /></a>
                    </div>
                </div>
               
                
            </div>
            
            <div className={styles.about}>
                <h3 className={darkMode ? styles.title : styles.titleLight }>Nosotros</h3>
                <div className={darkMode ? styles.aboutItem : styles.aboutItemLigth }>
                    <i className="bi bi-envelope"></i>
                    <a href="mailto: bitmarketperu@gmail.com" target="_blank" rel="noreferrer"><p className={darkMode ? styles.paragraph : styles.paragraphLight }>bitmarketperu@gmail.com</p></a>
                </div>
                <div className={darkMode ? styles.aboutItem : styles.aboutItemLigth }>
                    <i className="bi bi-whatsapp"></i>
                    <a className={darkMode ? styles.link : styles.linkLigth } href="https://api.whatsapp.com/send?phone=51955269142" target="_blank" rel="noreferrer"><p>955 269 142</p></a>
                </div>
                <div className={darkMode ? styles.aboutItem : styles.aboutItemLigth }>
                    <i className="bi bi-calendar2-check"></i>
                    <div>
                        <p>Lunes - viernes <br />9:00am / 6:00pm</p>
                    </div>  
                </div>
                <div className={darkMode ? styles.aboutItem : styles.aboutItemLigth }>
                    <i className="bi bi-calendar2-check"></i>
                    <div>
                        <p>Sábados 9:00am / 1:00pm</p>
                    </div>  
                </div>
                <div className={darkMode ? styles.aboutItem : styles.aboutItemLigth }>
                    <i className="bi bi-geo-alt"></i>
                    <a className={darkMode ? styles.link : styles.linkLigth } href="https://goo.gl/maps/yZhxJrb3R7TZjcyz6" target="_blank" rel="noreferrer"><p>Av del Ejército 768 Miraflores, Lima Perú</p></a>
                </div>
            </div>
          
            <div className={styles.source}>
                <h3 className={darkMode ? styles.title : styles.titleLight }>Recursos</h3>
                <div className={darkMode ? styles.aboutItem : styles.aboutItemLigth }>
                    <i className="bi bi-journals"></i>
                    <a href="https://blog.bitmarketperu.com" target="_blank" rel="noreferrer"><p className={darkMode ? styles.paragraph : styles.paragraphLight }>Blog</p></a>
                </div>
                <div className={darkMode ? styles.aboutItem : styles.aboutItemLigth }>
                    <i className="bi bi-hand-thumbs-up"></i>
                    <a href={pdf} target="_blank" rel="noreferrer"><p className={darkMode ? styles.paragraph : styles.paragraphLight }>Terminos y condiciones</p></a>
                </div>
                <div className={darkMode ? styles.aboutItem : styles.aboutItemLigth }>
                    <i className="bi bi-book"></i>
                    <a href="#!" target="_blank"><p className={darkMode ? styles.paragraph : styles.paragraphLight }>Libro de Reclamacion</p></a>
                </div>
                <div className={darkMode ? styles.aboutItem : styles.aboutItemLigth }>
                    <i className="bi bi-journal-check"></i>
                    <a href="https://blog.bitmarketperu.com/como-usar-bitmarketperu/" target="_blank" rel="noreferrer"><p className={darkMode ? styles.paragraph : styles.paragraphLight }>Guia de uso</p></a>
                </div>
                <div className={darkMode ? styles.aboutItem : styles.aboutItemLigth }>
                    <i className="bi bi-person-bounding-box"></i>
                    <button style={{ backgroundColor: "transparent", border: "none", padding: 0}} onClick={() => setInfoKyc(true)}><p className={darkMode ? styles.paragraph : styles.paragraphLight }>KYC (Conoce a Tu Cliente)</p></button>
                </div>
            </div>
            <div className={styles.newsletter}>
                <h3  className={darkMode ? styles.title : styles.titleLight }>Mantente Informado</h3>
                <div style={{ display: 'flex' }}>
                    <input id="periodico" className={styles.inputNewLetter} type="text" placeholder="Ingresa tu correo" />
                    <button className={styles.buttonNewLetter}>Suscribirme</button>
                </div>
            </div>
        </motion.footer>
        <Bar />
        <p className={darkMode ? styles.copy : styles.copyLigth }>© 2022 bitmarketperu.com propiedad de <a className={darkMode ? styles.link : styles.linkLigth } href="https://ewforex.net/" target="_blank" rel="noreferrer" title="ewforex.net">ewforex</a></p>
    </>);
}
 
export default Footer;