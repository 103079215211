import { useContext, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import logo from '../../img/logoAzul.webp';
import DarkMode from '../../components/darkMode/DarkMode';
import bsc from '../../img/redes/bsc.webp';
import matic from '../../img/redes/matic.webp';
import eth from '../../img/redes/eth.svg';
import Resume from '../../services/resumeWallet';
import { DataContext } from '../../context/DataContext';
import { getStorage, setStorage } from '../../services/localStorage';
import styles from '../../css/login/login.module.css';
import Confirm from '../../components/modals/Confirm'
import { NavLink } from 'react-router-dom'

// const [, setModal] = useState(false);

const NavMovil = () => {

    const { setDarkMode, wallet, setRedesModal, darkMode, chain, isLoggedIn, setIsLoggedIn } = useContext(DataContext);
    const [confirm, setConfirm] = useState(false)
    const handleDarlk = () => {
        const local = getStorage('bitmarketperu');
        if (local === 'light') {
            setStorage('bitmarketperu', 'dark');
            setDarkMode(true);
        } else {
            setStorage('bitmarketperu', 'light');
            setDarkMode(false);
        }
    };
    const handleLogout = () => {
        localStorage.removeItem("loginAuth");
        setIsLoggedIn(false)
        setConfirm(false)
    }


    return (<>
        {confirm && <Confirm
            onConfirm={handleLogout}
            onCancel={setConfirm}
            text="¿Cerrar Sesión?"
        />}
        <div className='d-lg-none nav-section-1 nav-mobile'>
            <div className='logo-mobile'>
                <LazyLoadImage effect='blur' src={logo} alt="" />
                <span className={`${!darkMode && 'nav-text-logo'}`}>BitmarketPerú</span>
            </div>
            <DarkMode setDarkMode={() => handleDarlk()} darkMode={darkMode} />
            {/* <div onClick={() => setModal(true)} className='btn-mobil-game'>
                <div className='container-ico-game'>
                    <i className="bi bi-controller ico-mobil-game"></i>
                </div>
            </div> */}
            {isLoggedIn ?
                (<div onClick={() => setConfirm(true)} className={`btn-nav-login nav-link`}>
                    Cerrar Sesión
                </div>)
                : (<NavLink to='/login' className={`btn-nav-login nav-link`}>
                    Iniciar Sesión
                </NavLink>)

            }
            <div className='nav-section-2'>
                <button onClick={() => setRedesModal(true)} className='connect-btn'>
                    <div className='external-circle'>
                        {chain && chain == 1 && <div className='internal-circle'>
                            <img className='redLogoWallet' src={eth} alt="" />
                        </div>}
                        {chain && chain == 56 && <div className='internal-circle'>
                            <img className='redLogoWallet' src={bsc} alt="" />
                        </div>}
                        {chain && chain == 137 && <div className='internal-circle'>
                            <LazyLoadImage effect='blur' className='redLogoWallet' src={matic} alt="" />
                        </div>}
                        {!chain && <>
                            <div className='internal-circle'>
                                <i className='bi-wallet2 text-white' ></i>
                            </div>
                        </>}
                    </div>
                    <div style={{ fontSize: '16px' }}>
                        {wallet ? Resume(wallet) : <>Conectar Wallet</>}
                    </div>
                </button>
            </div>
        </div>
    </>);
}

export default NavMovil;