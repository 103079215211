import { useState, useContext } from 'react'
import Bank from '../../components/bank/bank'
import MenuRendererMobile from '../../components/menu/menuMobile'
import MenuRenderer from '../../components/menu/menu'
import dashboardMenu from '../../pages/dashboard/dashboardMenu.json'
import Perfil from './perfil'
import TransactionsDashboard from '../../components/dashboard/transactionDashboard'
import { DataContext } from "../../context/DataContext"
import Memory from '../../components/modalMemory/Modal'

import NavMovil from '../../components/NavMovil/NavMovil'

const Dashboard = () => {

    const [menu, setMenu] = useState(3)
    const [modal, setModal] = useState(false)
    const { darkMode } = useContext(DataContext)

    // const dataStyle = { background: !darkMode && 'linear-gradient(311deg, rgba(118,197,255,1) 0%, rgba(208,235,255,1) 50%, rgba(175,221,255,1) 100%)', }

    return (<>
        {modal ? (<Memory setModal={setModal} />)
            : <> <NavMovil />

                <div className='container-dashboard' /*style={dataStyle}*/>
                    <div className="container-xxl">
                        <div className='mobileMargin'>
                            <div className="row">
                                <div className="col-2 sideMenu d-none d-sm-none d-lg-block">
                                    <div className={darkMode ? 'dashHeader' : "dashHeaderLight"}>
                                        <h3> Dashboard </h3>
                                        <p> Bienvenido </p>
                                    </div>
                                    <ul>
                                        <MenuRenderer valueMenu={menu} setMenu={setMenu} menuConfig={dashboardMenu} darkMode={darkMode}/>
                                    </ul>
                                </div>
                                <div style={{ paddingLeft: '0px', paddingRight: '0px' }} className="col-12 d-block d-sm-block d-lg-none">
                                    <div className='sideMenuMobile'>
                                        
                                        <MenuRendererMobile valueMenu={menu} setMenu={setMenu} menuConfig={dashboardMenu} darkMode={darkMode}/>
                                    </div>
                                </div>
                                <div className="col-lg-10 col-md-12 text-white">

                                    {menu === 3 && <TransactionsDashboard />}
                                    {menu === 1 && <Perfil />}
                                    {menu === 2 && <Bank />}

                                </div>
                            </div>
                        </div>
                        <div className='mobileSeparator d-lg-none'></div>
                    </div>
                </div>
            </>}
    </>)
}
export default Dashboard