import { useEffect, useState, useContext } from "react"
import axios from "axios"
import { DataContext } from "../../context/DataContext"
import Loader from "../loader/Loader"
import { apiUrl } from "../../services/apiService"
import req from "../../services/req"
const Porcentajes = () => {

    const { darkMode, loading, setLoading, validateReq, setLoadingText } = useContext(DataContext)

    const [usdtDolares, setUsdtDolares] = useState(0)
    const [usdtSoles, setUsdtSoles] = useState(0)
    const [solesUsdt, setSolesUsdt] = useState(0)
    const [dolaresUsdt, setDolaresUsdt] = useState(0)
    const [btcCompra, setBtCompra] = useState(0)
    const [btcVenta, setBtcVenta] = useState(0)

    const [config, setConfig] = useState(false)

    useEffect(() => {
        getConfig()
    }, [])

    const getConfig = async () => {

        let token = localStorage.getItem('loginAuth')

        let headers = { 'Container-Type': 'application/json', 'Authorization': `Bearer ${token}` }

        const res = await (await fetch(apiUrl + "config", { headers })).json()

        /* const res = await req.get(apiUrl + "config")
        validateReq(res,"GetConfig") */
        
        const config = res.response
        setConfig(config)
        //console.log(config)
        setUsdtDolares(parseFloat(config.dolOut))
        setUsdtSoles(parseFloat(config.solOut))
        setDolaresUsdt(parseFloat(config.dolInp))
        setSolesUsdt(parseFloat(config.solInp))
        setBtCompra(config.buyBit)
        setBtcVenta(config.sellBit)
    }

    const save = async () => {
        setLoading(true)
        setLoadingText("Guardando tipos de cambio")
        const body = {
            ...config,
            solInp: solesUsdt,
            dolOut: usdtDolares,
            dolInp: dolaresUsdt,
            solOut: usdtSoles,
            buyBit: btcCompra,
            sellBit: btcVenta
        }
        //console.log(body)
        try {
            await axios.put(apiUrl + "config", body)
            await getConfig()
            setLoading(false); setLoadingText(false);
        } catch (error) {
            //console.log(error)
            setLoading(false); setLoadingText(false);
        }
    }

    return (<div className="mt-4 mh-100vh">
        {loading && <Loader />}
        <div>
            <div className={darkMode ? 'changes' : 'changesLight'}>
                <div>
                    USDT - Dolares
                </div>
                <div className={darkMode ? 'adminPercent' : 'adminPercentLight'}>
                    <input id='usdtDolares' min="0" max="100" step="0.1" onChange={(e) => setUsdtDolares(e.target.value)} type="number" value={usdtDolares} />
                    <div> % </div>
                </div>
            </div>
            <div className={darkMode ? 'changes' : 'changesLight'}>
                <div>
                    USDT - Soles
                </div>
                <div className={darkMode ? 'adminPercent' : 'adminPercentLight'}>
                    <input id="usdtSOles" min="0" max="100" step="0.1" onChange={(e) => setUsdtSoles(e.target.value)} type="number" value={usdtSoles} />
                    <div> % </div>
                </div>
            </div>
            <div className={darkMode ? 'changes' : 'changesLight'}>
                <div>
                    Soles - USDT
                </div>
                <div className={darkMode ? 'adminPercent' : 'adminPercentLight'}>
                    <input id="solesUsdt" min="0" max="100" step="0.1" onChange={(e) => setSolesUsdt(e.target.value)} type="number" value={solesUsdt} />
                    <div> % </div>
                </div>
            </div>
            <div className={darkMode ? 'changes' : 'changesLight'}>
                <div>
                    Dolares - USDT
                </div>
                <div className={darkMode ? 'adminPercent' : 'adminPercentLight'}>
                    <input id="dolaresUsdt" min="0" max="100" step="0.1" onChange={(e) => setDolaresUsdt(e.target.value)} type="number" value={dolaresUsdt} />
                    <div> % </div>

                </div>
            </div>
            <hr />
            <div className={darkMode ? 'changes' : 'changesLight'}>
                <div>
                    Compra BTC
                </div>
                <div className={darkMode ? 'adminPercent' : 'adminPercentLight'}>
                    <input id="compraBtc" min="0" max="100" step="0.1" onChange={(e) => setBtCompra(e.target.value)} type="number" value={btcCompra} />
                    <div> % </div>

                </div>
            </div>
            <div className={darkMode ? 'changes' : 'changesLight'}>
                <div>
                    Venta BTC
                </div>
                <div className={darkMode ? 'adminPercent' : 'adminPercentLight'}>
                    <input id="ventaBtc" min="0" max="100" step="0.1" onChange={(e) => setBtcVenta(e.target.value)} type="number" value={btcVenta} />
                    <div> % </div>

                </div>
            </div>
            <div className="saveChanges">
                <button onClick={save} > Guardar cambios</button>
            </div>
        </div>
    </div>)
}
export default Porcentajes