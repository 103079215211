import axios from "axios"
import { useEffect, useContext, useState } from "react"
import { DataContext } from '../../context/DataContext'
import Loader from "../loader/Loader"
import { apiUrl } from "../../services/apiService"
import { jwtDecode } from "jwt-decode"
import AlertModal from "../modals/alertModal"
const Usuarios = () => {

    const { wallet, loading, setLoading, darkMode, setLoadingText,alertModal,setAlertModal } = useContext(DataContext)

    const [users, setUsers] = useState(false)
    const [modal, setModal] = useState(false)
    const [user, setUser] = useState(false)
    const [name, setName] = useState(false)
    const [email, setEmail] = useState(false)
    const [phone, setPhone] = useState(false)
    const [kyc, setKyc] = useState(false)
    const [dni, setDni] = useState(false)

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        let token = localStorage.getItem('loginAuth')
        let decodedToken = jwtDecode(token)
        //console.log(decodedToken)
        const res = await (await fetch(apiUrl + "user", {
            headers: {
                'Container-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })).json()

        //console.log(res.response)
        //console.log(res)
        setUsers(res.response)
    }

    /* const last = (id) => {
        let x = id.toString()
        return x.substr(x.length - 8, 8)
    } */

    const sendFormData = async (e) => {
        setLoading(true)
        setLoadingText("Enviando datos del usuario")
        setModal(false)
        e.preventDefault()
        let body = {
            _id: user._id,
            wallet,
            name,
            email,
            phone,
            kyc,
            dni
        }
        try {
            await axios.put(apiUrl + "user/", body)
            await getUsers()    
        } catch (error) {
            setAlertModal({ status: true, text: 'A ocurrido un error', type: "warning", link: false })
        }

        setLoading(false); 
        setLoadingText(false);
    }

    const userData = (user) => {
        setUser(user)
        setName(user.name)
        setPhone(user.phone)
        setEmail(user.email)
        setKyc(user.kyc)
        setDni(user.dni)
        setModal(true)
    }

    const styleColor = {
        color: darkMode ? "white" : "#1c0942",
    }

    return (<div className="mh-100vh">
        {loading && <Loader />}
        <AlertModal setAlertModal={setAlertModal} alertModal={alertModal} />
        {modal && <div className="bgtransparentDark">
            <div className='modal-display'>
                <div className='modalHeader'>
                    <div>
                        Editando Usuario <br />
                        <div className="lb">{user._id}</div>
                    </div>
                    <div>
                        <i onClick={() => setModal(false)} className='bi-x x'></i>
                    </div>
                </div>
                <div className="usuariosModalBody">
                    <form action="" onSubmit={(e) => sendFormData(e)}>
                        <span> <i className='bi-people' /> Nombre completo</span>
                        <input id="nombre" onChange={(e) => setName(e.target.value)} value={name} className='form-control mb-2' type="text" placeholder='Escriba el nombre' required />

                        <span> <i className='bi-telephone' /> Numero de Documento </span>
                        <input id="dni" value={dni} onChange={(e) => setDni(e.target.value)} className='form-control mb-2' type="text" placeholder='Numero de documento' required />

                        <span> <i className='bi-envelope' /> Correo Electronico </span>
                        <input id="email" value={email} onChange={(e) => setEmail(e.target.value)} className='form-control mb-2' type="email" placeholder='Correo electronico' required />

                        <span> <i className='bi-telephone' /> Numero de telefono </span>
                        <input id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} className='form-control mb-2' type="text" placeholder='Numero de telefono' required />

                        <span> <i className="bi bi-person-bounding-box"></i> KYC </span>
                        <input id="kyc" className="form-check-input" type="checkbox" value={kyc} onChange={(e) => setKyc(!kyc)} checked={kyc} />

                        <div className="saveChanges">
                            <button> Guardar </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>}
        <div className="container mt-1">
            <div className="row">
                <div className="col-12">
                    <h3 style={styleColor}> <i className="bi-people"></i> Usuarios</h3>
                </div>
            </div>
            <div style={styleColor} className="row yellow">
                <div className="col-3">Nombre</div>
                <div className="col-2">Dni</div>
                <div className="col-4">Email</div>
                <div className="col-2">Telefono</div>
                <div className="col-1"></div>
            </div>
            {users && users.map((item) => <div key={item._id} style={styleColor} className="row my-3">
                <div className="col-3">{!item.name ? "n/a" : item.name}</div>
                <div className="col-2">{item.dni}</div>
                <div className="col-4">{!item.email ? "n/a" : item.email}</div>
                <div className="col-2">{!item.phone ? "n/a" : item.phone}</div>
                <div className="col-1">
                    <button onClick={() => userData(item)}> <i className="bi-wrench"></i> </button>
                </div>
                <div className="col-12 adminUserWallet"> {item.wallet} </div>
            </div>
            )}
        </div>
    </div>)
}
export default Usuarios