const Load = () => {
    return (<>{
        <div className="bgtransparentDark">
            <div className="wrapperLoader text-center">
                <div className="spinner-border yellow" role="status">
                    <span className="sr-only"></span>
                </div>
                <div className="p-3">
                    cargando....
                </div>
            </div>
        </div>
    }
    </>
    )
}

export default Load