const E404 = () => {
    return (
        <div className="error404">
            <div className="text-center w-100">
                <h1>404</h1>
                <p>Dirección inválida</p>
                <form action="./#/">
                    <button className="btn">Volver al inicio</button>
                </form>
            </div>
        </div>
    )
}
export default E404