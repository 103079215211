import { useEffect, useState, useContext } from "react"
import req from "../services/req"
import { apiUrl } from "../services/apiService"
/* import { useWeb3 } from "./useWeb3" */
import validateReq from "../services/errorManager/validateError"
import socket from '../socket'
import { DataContext } from "../context/DataContext"
import { useChat } from './useChat';
import { jwtDecode } from "jwt-decode"

export const useTransactions = () => {

    /*const { getWallet } = useWeb3()*/
    const [transactions, setTransactions] = useState([])
    const date = new Date()
    const toDay = `${String(date.getFullYear())}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
    const toDay1 = `${String(date.getFullYear())}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
    const [desde, setDesde] = useState(toDay)
    const [hasta, setHasta] = useState(toDay1)
    const [modal, setModal] = useState(false)
    const [filter, setFilter] = useState(0)
    const [load, setLoad] = useState(true)
    const [showDetails, setShowDetails] = useState(false)
    const [transactionSelected, setTransactionSelected] = useState(null)
    const { wallet, userLevel, user } = useContext(DataContext);
    const [transactionLength, setTransactionLength] = useState(0)
    const USER_ADMIN = 1;

    const dataStatus = {
        0: { msg: "Todas", icon: "" },
        1: { msg: "Cancelada", icon: <i className="bi bi-x-circle-fill"></i>, class: "transaction-status-danger" },
        2: { msg: "Finalizada", icon: <i className="bi bi-check-circle-fill"></i>, class: "transaction-status-success" },
        3: { msg: "En Proceso", icon: <i className="bi bi-hourglass-split"></i>, class: "transaction-status-warnign" }
    }

    const { startSocket } = useChat();

    const startSocketTransactions = () => {
        socket.on('transactions', async transactions => {
            if (userLevel === USER_ADMIN) {
                getTransactionsAll()

            } else {

                if (transactions.filter(t => t.wallet === wallet).length > 0 &&
                    transactions.filter(t => t.wallet === wallet).length !== transactionLength) {

                    setTransactionLength(transactions.filter(t => t.wallet === wallet).length)
                    getTransactions()

                }

                if (transactionSelected && transactions.length > 0) {
                    const dataTransaction = transactions.filter(t => t._id === transactionSelected._id)[0]
                    setTransactionLength(transactions.filter(t => t.wallet === wallet).length)
                    getTransactions()
                    setTransactionSelected(dataTransaction)
                    // //console.log(dataTransaction)
                }
            }
        })

        // if(transactionSelected && transactions.length > 0) {
        //     if(transactions?.filter(t => t._id === transactionSelected._id)[0]){
        //         setTransactionLength(transactions.filter(t => t.wallet === wallet).transactions.filter(t => t._id === transactionSelected._id)[0])
        //     }
        //     // setTransactionSelected(transactions.filter(t => t._id === transactionSelected._id)[0])
        // }
        // handleShowTransaction(transactions)
    }

    const getTransactions = async () => {
        setLoad(true)
        const token = localStorage.getItem('loginAuth')
        if(!token) return null
        const user = jwtDecode(token)
    
         const res = await req.get(apiUrl + `transactions/${user.dni}/${desde}/${hasta}`)
         
         if (res.response) {
             const transactionFilter = res.response.filter(t => {
                 if (filter === 0) return t
                 if (t.status === filter) return t
             }).reverse()
           
             setTransactions(transactionFilter)
             setTransactionLength(transactionFilter.length)
         } else {
             setTransactions([])
             setTransactionLength(0)
             // alert("No responde el servidor")
         }
        setLoad(false)
    }

    const getTransactionsAll = async () => {
        setLoad(true)
        const res = await req.get(apiUrl + `transactions/${desde}/${hasta}`)
        const transactionFilter = res.response.filter(t => {
            if (filter === 0) return t
            if (t.status === filter) return t
        }).reverse()

        setTransactions(transactionFilter)

        setLoad(false)
        return
    }

    const handleShowTransaction = (item) => {
        setTransactionSelected(item)
        setShowDetails(true)
        startSocket() //socket chat
        startSocketTransactions()
    }

    useEffect(() => {
        startSocketTransactions();
        if (userLevel === USER_ADMIN && Object.keys(user).length) {
            getTransactionsAll();
        } else {
            getTransactions()
        }
    }, [userLevel, wallet, transactionSelected])

    return {
        transactions,
        dataStatus,
        desde,
        hasta,
        transactionSelected,
        setDesde,
        setHasta,
        modal,
        setModal,
        filter,
        setFilter,
        getTransactions,
        load,
        showDetails,
        setShowDetails,
        handleShowTransaction,
        getTransactionsAll
    }
}

////console.log(desde," - ",hasta)
//const res = await req.get(apiUrl + `transactions/${wallet}/${desde}/${hasta}`)