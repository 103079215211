import { useState,useEffect } from "react"
import { jwtDecode } from "jwt-decode"
const useGetUserTokenInLocalStorage = ()=>{

    const [userDataToken, setUserDataToken] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem('loginAuth')
        if(token){
            const decoded = jwtDecode(token)
            setUserDataToken(decoded)
        }else{
            //console.log("No user token")
            setUserDataToken(false)
        }
    }, [setUserDataToken])
    return {
        userDataToken, setUserDataToken
    }
}

export default useGetUserTokenInLocalStorage