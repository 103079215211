export default [
    { id: 1, name: 'Bitcoin', status: false},
    { id: 2, name: 'Bitcoin', status: false},
    { id: 3, name: 'Ethereum', status: false},
    { id: 4, name: 'Ethereum', status: false},
    { id: 5, name: 'Binance', status: false},
    { id: 6, name: 'Binance', status: false},
    { id: 7, name: 'Tether', status: false},
    { id: 8, name: 'Tether', status: false},
    { id: 9, name: 'Matic', status: false},
    { id: 10, name: 'Matic', status: false},
    { id: 11, name: 'Dogecoin', status: false},
    { id: 12, name: 'Dogecoin', status: false},
    { id: 13, name: 'Riple', status: false},
    { id: 14, name: 'Riple', status: false},
    { id: 15, name: 'Tron', status: false},
    { id: 16, name: 'Tron', status: false}
]