/* wallets en las redes para recibir usdt "usdtWallets" */

// const bsc = "0x18be2220e90f6ac68b7d6dd59a1310c4c1478f4d"
const bsc = "0xd04069cfd89f38f5baac6c8269b08789cada3b04"
// const eth = "0x18be2220e90f6ac68b7d6dd59a1310c4c1478f4d"
const eth = "0xd04069cfd89f38f5baac6c8269b08789cada3b04"
// const matic = "0x18be2220e90f6ac68b7d6dd59a1310c4c1478f4d"
const matic = "0xd04069cfd89f38f5baac6c8269b08789cada3b04"
// const avax = "0x18be2220e90f6ac68b7d6dd59a1310c4c1478f4d"
const avax = "X-avax16rn2msehz7q4rk8vqthgca6y47munxxqp02e4g"
// const sol = "EyPHe5vZYyeBE1NYg4R4nUi83RTg8sLEPAvDGPXvyBmH"
const sol = "Ewt1WwMwJGd2bmAHwj3iTqm4WoFvrjVR64JNvZSi64ns"
// const trx = "TBTHwRGqSME23jAAmv9GN4wLvTZhvxiWaH"
const trx = "TBuStHF29ikcrzJBcmm3DLfm1dcXAcGwS3"
// const btc = "1449RpidrHcUA8n2zoE6MFLcwRX8Psfmg3"
const btc = "1KxYWffttjo8FJGy378XWuC76SLiVAT82m"

const settings = {
    email: "bitmarketperu@gmail.com",
    binancePayEmail: "fernandoluna51@hotmail.com",
    nullItem: "000000000000000000000000",
    binanceWallet: "1KxYWffttjo8FJGy378XWuC76SLiVAT82m",
    minBtc: 0.0025,
    minSoles: 50,
    minUsd: 10,
    // minUsdt: 10,
    minUsdt: 5,
    bitcoinWallet: "1KxYWffttjo8FJGy378XWuC76SLiVAT82m",
    usdtWallets: ["", eth, bsc, matic, avax, sol, trx, btc],
    /*********limit*********/
    /***********************/
    maxUsd: 25000,
    maxSoles: 100000,
    amountSbs: 5000
}


export default settings