import req from "../services/req"
import { apiUrl } from "../services/apiService"
import { useState } from "react"
import { setStorage } from "../services/localStorage"
import { useEffect } from "react"
import { jwtDecode } from "jwt-decode"
const useLogin = (setDni, setLoad, setIsLoggedIn, setAlertModal, setKyc, getSwapData, config, setConfig, getUserBanks, setBanksAdmin) => {

    const [formData, setFormData] = useState({ email: '', password: '' })
    const [user, setUser] = useState({})

    useEffect(() => {
        const token = localStorage.getItem('loginAuth')
         if(token){
            const decoded = jwtDecode(token)
            setUser(decoded)
        }
    }, [])

    const emailLogin = async () => {

        setLoad(true)

        try {
            const response = await req.post(`${apiUrl}login/login`, formData)

            if (!response.error) {
                // token de inicio de sesión

                //insertar datos del usuario
                setKyc(response?.response?.kyc)
                setDni(response?.response?.dni)
             
                setUser(response?.response)

                setStorage('loginAuth', response.response.token)
              
                getUserBanks(response?.response?.dni)
   
                setBanksAdmin(response?.response?.banksAdmin)

                setIsLoggedIn(true)
                if (response.response.config[0]) {
                    getSwapData(response.response.config[0])
                } else {
                    getSwapData(false)
                }
            } else {
                setAlertModal({
                    status: true,
                    type: 'danger',
                    text: 'Correo o Contraseña invalida. Por favor, inténtalo nuevamente.'
                })
            }
            setLoad(false)
        } catch (error) {
            console.error('Error during login:', error);
            setAlertModal({
                status: true,
                type: 'danger',
                message: 'Ocurrió un error durante el inicio de sesión. Por favor, inténtalo nuevamente.',
            });
            setLoad(false)

        }

    }

    return {
        emailLogin,
        formData,
        setFormData,
        user, setUser
    }
}

export default useLogin