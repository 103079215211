import React from "react";
import { Switch, Route } from "react-router-dom";
import E404 from "./pages/404/404";
import Dashboard from "./pages/dashboard/dashboard";
import Swap from "./pages/swap/swap";
import Admin from "./pages/admin/admin";
import Landing from "./pages/landing/Landing";
import Transaction from "./pages/transaction/transaction";
import Asistbot from "./services/asistbot/asistbot";
import Tutorial from "./pages/tutorial/Landing";
import Login from './pages/login/Login';
import Register from './pages/login/Register';
import RememberPass from './pages/rememberPass/RememberPass';
import PrivateRoute from "./PrivateRoute";
import Email from "./pages/email/Email";

const Router = () => {
    return (
        <Switch>
            <Route path="/" exact>
                <Landing />
            </Route>
            <Route path="/home">
                <Landing />
            </Route>
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <Route path="/swap">
                <Swap />
            </Route>
            <Route path="/admin">
                <Admin/>
            </Route>
            <Route path="/transaction">
                <Transaction />
            </Route>
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/register">
                <Register />
            </Route>
            <Route path="/forgot">
                <RememberPass />
            </Route>
            <Route path="/asistbot">
                <Asistbot />
            </Route>
            <Route path="/tutorial">
                <Tutorial />
            </Route>
            <Route path="/email">
                <Email />
            </Route>
            <Route path="/*">
                <E404/>
            </Route>
        </Switch>
    )
}
export default Router