import ToDate from '../toDay/ToDate'
import { motion } from 'framer-motion/dist/framer-motion'

const filterTransaction = ({ filter, desde, hasta, setDesde, setHasta, setModal, setFilter, getTransactions }) => {

    return (
        <motion.div 
            className="modal-wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: .3}}
            exit={{ opacity: 0 }}
        >
            <motion.div 
                className="modal-display"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ type: 'spring', duration: .3}}
                exit={{ opacity: 0, scale: 0 }}    
            >
                <div className="modalHeader">
                    <div>
                        <i className="bi bi-funnel-fill" /> Filtrar Transacciones
                    </div>
                    <div>
                        <i onClick={() => setModal(false)} className="bi-x x" />
                    </div>
                </div>
                <div className="modalContent p-3">
                    <button className={filter === 0 ? "btn-active" : ""} onClick={() => setFilter(0)}>Todas</button>
                    <button className={filter === 1 ? "btn-active" : ""} onClick={() => setFilter(1)}>Cancelada</button>
                    <button className={filter === 2 ? "btn-active" : ""} onClick={() => setFilter(2)}>Finalizada</button>
                    <button className={filter === 3 ? "btn-active" : ""} onClick={() => setFilter(3)}>En Proceso</button>
                    <ToDate 
                        desde={desde}
                        hasta={hasta}
                        setDesde={setDesde}
                        setHasta={setHasta}
                    />
                    <button onClick={() => {
                        getTransactions()
                        setModal(false)
                    }} className='btn-filter'> Filtrar </button>
                </div>
            </motion.div>
        </motion.div>
    )
}
export default filterTransaction