
const errorManager = (error)=>{
    if (error.response) {
        //alert("Error Response")
        /* //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers); */
       // //console.log(error.response.data.error.message)
    } else if (error.request) {
        ////console.log("Error Request")
       // alert(error.request);
    } else {
        ////console.log("Error Message")
        //alert('Error: ', error.message);
    }
    ////console.log(error.config);
}

export default errorManager