import styled from 'styled-components'
import { motion } from 'framer-motion/dist/framer-motion'

const Container = styled.div`
    width: 100%;
    display: grid;
    place-items: center;
    position: relative;
`

const PreLoad = ()=>
<Container>
    <motion.div 
        className="spinner-border yellow" 
        role="status"
        initial={{ opacity: 0, position: "absolute", top: '50px' }}
        animate={{ opacity: 1 }}
        transition={{ duration: .2 }}
        exit={{ opacity: 0 }}
    >
        <span className="sr-only"></span>
    </motion.div>
</Container>

export default PreLoad