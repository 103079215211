import { useEffect, useState } from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import './detailsTransaction.css'
import LogoBank from '../logoBank/logoBank'
import { useChat } from '../../hooks/useChat.jsx'
// const getChainText = (chainId) => chainId == '0x1' ? "ETH" : chainId == '0x38' ? "BSC" : "MATIC"
/* const getChainText = (chainId) => chainId == 1 ? "ETH" : chainId == 56 ? "BSC" : "MATIC" */
const DetailsTransaction = ({ setTransactionSelected, setShowDetails, transactionSelected, dataStatus, handleShowTransaction, redName }) => {

    const { _id, id, moneySend, amountSend, moneyReceive, amountReceive, date, network, bank, bankAdmin, status, payMethod, reciveMethod, reciveNetwork, reciveUser } = transactionSelected

    const { dataMsg, down, msg, setMsg, handleMsg, startSocket, getChat } = useChat(_id)

    const [content, setContent] = useState({ operation: true, chat: true });

    const setTime = (date) => {
        let fecha = new Date(date)
        let time = `${String(fecha.getFullYear())}-${String(fecha.getMonth() + 1).padStart(2, '0')}-${String(fecha.getDate()).padStart(2, '0')}`;
        let hour = `${String(fecha.getHours()).padStart(2, '0')} : ${String(fecha.getMinutes()).padStart(2, '0')}`;
        return { time, hour }
    }

    const sliceFunction = (val) => {
        if (val) {
            return val.slice(-6)
        } else {
            return 0
        }
    }

    useEffect(() => {

        startSocket();
        getChat();

    }, []);
    

    const styleContentHidden = {
        maxHeight: !content.operation && '75px',
        padding: !content.operation && '25px 0px 55px 35px',
        gridTemplateAreas: `"transaction date send"
                         "hash pay recive"
                         "network through status"
                         "banku banka banka"`,
        position: "relative"
    }

    const styleContetDisplay = {
        display: !content.operation && 'none',
    }

    const styleChatHidden = {
        display: !content.chat && 'none',

    }

    const styleChatDisplay = {
        maxHeight: !content.chat && '75px'
    }

    const styleStatus = {
        opacity: status !== 3 ? .6 : 1
    }

    return (<>
        <motion.div className="details-container" initial={{ opacity: 0, x: 200, y: -100, scale: .7, position: 'relative' }} animate={{ opacity: 1, x: 0, y: 0, scale: 1 }} transition={{ type: 'spring', duration: .3 }} >

        <div className='details-card-chat' style={styleChatDisplay}>
                <div
                    onClick={() => setContent({ ...content, chat: !content.chat })}
                    className={!content.chat ? 'arrow-content' : 'arrow-content-open'}>
                    {!content.chat ? (
                        <i className="bi bi-box-arrow-down-left"></i>
                    ) :
                        (
                            <i className="bi bi-box-arrow-up-left"></i>
                        )}
                </div>
                <div className='chat'>
                    {status === 3 ? 
                        <div className='chat-title'>
                            <span className='chat-online'></span>
                            <span>Online | Chat Disponible</span>
                        </div>
                    
                    :
                        <div className='chat-title'>
                            <span className='chat-close'></span>
                            <span>Operacion Terminada</span>
                        </div>
                    }
                    
                    <div
                        ref={down}
                        style={styleChatHidden}
                        className={dataMsg.length > 0 ? 'chat-active' : 'chat-content'}>
                        {dataMsg.length <= 0 ? (<>
                            <div className='chat-icon'><i className="bi bi-chat-left-text-fill"></i></div>
                            <p>Estamos disponible para cualquier consulta</p>
                        </>)
                            : (<>
                               
                                {(dataMsg.map(m => (
                                    // m.idTransaction === transactionSelected._id && (
                                    <div key={m._id} className={m.idUser.level === 0 ? "chat-content-item" : "chat-content-item-admin"} >
                                        <span className="chat-content-time">{setTime(m.date).hour}</span>
                                        <p className={m.idUser.level === 0 ? "chat-content-p" : "chat-content-admin"}>{m.description}</p>
                                    </div>
                                    // )

                                )))}
                               
                            </>)
                        }

                    </div>
                    <div
                        className='chat-from'
                        style={{ ...styleStatus, ...styleChatHidden }}>
                        <input
                            onKeyDown={e => e.key === "Enter" && handleMsg()}
                            type='text'
                            name='msg'
                            value={msg}
                            placeholder='Escribe un mensaje...'
                            onChange={e => setMsg(e.target.value)}
                            autoComplete="off"
                            disabled={status !== 3}
                        />
                        <button
                            onClick={() => handleMsg()}
                            disabled={status !== 3}
                        >Enviar</button>
                    </div>
                </div>
            </div>

            <div className="details-card" style={styleContentHidden} >
                <div className={!content.operation ? 'arrow-content' : 'arrow-content-open'} onClick={() => setContent({ ...content, operation: !content.operation })}>
                    {!content.operation ?
                        <i className="bi bi-box-arrow-down-left"></i>
                        :
                        <i className="bi bi-box-arrow-up-left"></i>
                    }
                </div>

                <div className='details-item-transaction'>
                    <span className="item-title">N° Transacción</span> 
                    <span>#{sliceFunction(_id)}</span>
                </div>
                <div style={styleContetDisplay} className='details-item-hash'>
                    <span className="item-title">Hash/Id</span> 
                    <span>{id}</span>
                </div>
                <div style={styleContetDisplay} className='details-item-send'>
                    <span className="item-title">Enviaste </span> 
                    <span>{amountSend} {moneySend}</span>
                </div>
                <div style={styleContetDisplay} className='details-item-recive'>
                    <span className="item-title">Recibes</span> 
                    <span>{amountReceive} {moneyReceive}</span>
                </div>
                <div style={styleContetDisplay} className='details-item-date'>
                    <span className="item-title">Fecha</span> <span>{setTime(date).time} | {setTime(date).hour}</span>
                </div>
                <div style={styleContetDisplay} className='details-item-network'>
                    <span className="item-title">Red</span>
                    <span>{network}</span>
                </div>
                <div style={styleContetDisplay} className='details-item-pay'>
                    <span className="item-title">Metodo de pago</span>
                    <span>{payMethod.method} {payMethod.method !== 'efectivo' && redName[payMethod.red]} </span>
                    {/* {//console.log(payMethod.pay, payMethod.red, redName[payMethod.red])} */}
                    {/* {payMethod.pay} */}
                    {/* <span> {payMethod.method} | {getChainText(payMethod.red)}</span>
                    <span> {payMethod.method} | {getChainText(payMethod.red)}</span> */}
                </div>
                <div style={styleContetDisplay} className='details-item-through'>
                    <span className="item-title">Mediante</span> 
                    <span style={{ position: 'absolute', maxWidth: '170px', top: '15px'}}> {(payMethod.pay)} </span>
                </div>
                <div style={styleContetDisplay} className='methodRecive'>
                    <span className="item-title">Recibes en</span>
                    {/* {!bank && reciveMethod} */}
                    {!bank && !bankAdmin ? <span> Efectivo </span>
                    : !bank && reciveNetwork === 'n/a' && reciveUser === 'n/a' && <span> Efectivo </span>}
                    {!bank && reciveUser !== 'n/a' && <span> {reciveUser} {redName[reciveNetwork]} </span>}
                    {bank !== null && <>
                    <div className='bank-card'>
                        <div className='cardBank'>
                            <div className='card-img'><LogoBank name={bank.name}/></div>
                            <div className='card-content'>
                                <div>{bank.name}</div>
                                <div>{bank.number}</div>
                            </div>
                            <div className='card-footer'>
                                <div>{bank.type}</div>
                                <div>{bank.money}</div>
                            </div>
                        </div>
                    </div>
                        
                    </>}
                    
                </div>
                
                {bankAdmin !== null && <>
                    <div style={styleContetDisplay} className='details-item-bank-a'>
                    <span className="item-title">Banco bitmarketperu:</span> 
                    <div className='bank-card'>
                        <div style={{width: '50%'}} className='cardBank'>
                            <div className='card-img'><LogoBank name={bankAdmin.name}/></div>
                            <div className='card-content'>
                                <div>{bankAdmin.name}</div>
                                <div>{bankAdmin.titular}</div>
                                <div>{bankAdmin.number}</div>
                            </div>
                            <div className='card-footer'>
                                <div>{bankAdmin.type}</div>
                                <div>{bankAdmin.money}</div>
                            </div>
                        </div>
                    </div>
                   
                </div>
                </>}
                <div style={styleContetDisplay} className='details-item-status'>
                    <span className="item-title">Estado</span> 
                    <div style={{maxWidth: "max-content"}}>
                        <span>{dataStatus[status].msg}</span>
                        <span className={`transaction-icon ${dataStatus[status].class}`}>{dataStatus[status].icon}</span>
                    </div>
                </div>
            </div>

            
        </motion.div>
    </>)
}

export default DetailsTransaction;