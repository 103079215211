import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import styles from '../../css/landing/franja.module.css'

const Franja = () => {

    const { darkMode } = useContext(DataContext);

    return ( 
        <div className={darkMode ? styles.franja : styles.franjaLight}>
            <p>USD - USDT</p>
            <p>PEN - USDT</p>
            <p>USDT - USD</p>
            <p>USDT - PEN</p>
        </div>
     );
}
 
export default Franja;