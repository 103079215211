import { useState, useContext, useRef } from 'react'
import  socket from '../socket'
import { DataContext } from '../context/DataContext'
import { apiUrl } from '../services/apiService'

export const useChat = (idTransaction) => {

    const { user } = useContext(DataContext)
    const down = useRef()
    const [msg, setMsg ] = useState('')
    const [ dataMsg, setDataMsg ] = useState([]);
    const userAdmin = 1;

    const token = localStorage.getItem('loginAuth')
    
    const startSocket = () => {
        socket.on('chat', async chat => {
            if(chat.filter( c => (c.idUser._id === user._id && c.idTransaction === idTransaction) || (c.idTransaction === idTransaction)).length > 0){
                // //console.log("exec")
                setDataMsg(chat);
                if(down.current){
                    // down.current.scrollIntoView()
                    down.current.scrollTop = down.current.scrollHeight;
                }
            }
                
        });
    }

    const getChat = () => {
        fetch(`${apiUrl}chat/${idTransaction}`);
    }

    const handleMsg = () => {

        //console.log('enviando un mensaje')

        if(msg === '') return;

        fetch(`${apiUrl}chat`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idTransaction: idTransaction,
                description: msg,
                idUser: user._id
            })
        })
        .catch( err=> console.error(err))
        setMsg('');
    }

    return {
        dataMsg, down, msg, setMsg, handleMsg, startSocket, getChat
    }
}