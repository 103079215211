import settings from "../services/settings"
const useValidateErrorSwap = (kyc, setError, setBtnStatus, userDataToken,input2,input1,
    selectedCoin1,selectedCoin2) => {

    const validateError = () => {
        /* //console.log(`se ejecuta el validador desde hook/useValidateErrorSwap.jsx`)
        //console.log('el kys es: ' + kyc)
        //console.log("useValidate error: user token: ", userDataToken) */

        if (!userDataToken.kyc) {
            setError("Debe verificar su identidad (KYC)")
            setBtnStatus(false)
            return
        }

        if (Number(input2) === 0 || Number(input1) === 0) {
            setBtnStatus(false)
            setError(`Error al obtener la cotizacion`)
            return;
        }


        if (selectedCoin1.symbol == "USD" && selectedCoin2.symbol == "SOL") {
            setError("No se realizan cambios de Dólares a Soles")
            setBtnStatus(false)
        } else if (selectedCoin1.symbol == "SOL" && selectedCoin2.symbol == "USD") {
            setError("No se realizan cambios de Soles a Dólares")
            setBtnStatus(false)
        } else if (selectedCoin1.symbol == "USDT" && selectedCoin2.symbol == "BTC") {
            setError("No se realizan cambios de USDT a BTC")
            setBtnStatus(false)
        } else if (selectedCoin1.symbol == "BTC" && selectedCoin2.symbol == "USDT") {
            setError("No se realizan cambios de BTC a USDT")
            setBtnStatus(false)
        } else {
            if (selectedCoin1.symbol == "USDT" && input1 < settings.minUsdt) {
                setError(`Monto minimo de ${settings.minUsdt} USDT`)
                setBtnStatus(false)
            } else if (selectedCoin1.symbol == "USD" && input1 < settings.minUsd) {
                setError(`Monto minimo de ${settings.minUsd} USD`)
                setBtnStatus(false)
            } else if (selectedCoin1.symbol == "BTC" && input1 < settings.minBtc) {
                setError(`Monto minimo de ${settings.minBtc} BTC`)
                setBtnStatus(false)
            } else if (selectedCoin1.symbol == "SOL" && input1 < settings.minSoles) {
                setError(`Monto minimo de ${settings.minSoles} Soles`)
                setBtnStatus(false)
            } else {
                setError(false)
                setBtnStatus(true)
            }
        }

        

        //monto maximo de intercambio en usd
        if (selectedCoin1.symbol === "USD" && input1 > settings.maxUsd) {
            setError(`Monto maximo es ${settings.maxUsd} USD`)
            setBtnStatus(false)
            return;
        }

        //monto maximo de intercambio en soles
        if (selectedCoin1.symbol === "SOL" && input1 > settings.maxSoles) {
            setError(`Monto maximo es ${settings.maxSoles} SOLES`)
            setBtnStatus(false)
            return;
        }

        //monto maximo de intercambio en usd
        if (selectedCoin2.symbol === "USD" && input2 > settings.maxUsd) {
            setError(`Monto maximo es ${settings.maxUsd} USD`)
            setBtnStatus(false)
            return;
        }

        //monto maximo de intercambio en soles
        if (selectedCoin2.symbol === "SOL" && input2 > settings.maxSoles) {
            setError(`Monto maximo es ${settings.maxSoles} SOLES`)
            setBtnStatus(false)
            return;
        }

        setError(false)
        setBtnStatus(true)
    }
    return {
        validateError
    }
}
export default useValidateErrorSwap