import { motion } from 'framer-motion/dist/framer-motion'
import { useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { Link } from 'react-router-dom';

//type = success || warning
const AlertModal = () => {

    const ctx = useContext(DataContext)
    const handleDefault = () => null;

    const handle = ctx.alertModal?.handleBTN || handleDefault;

    const typeIcon = (ctx.alertModal.type === "success") ? <i
        style={{ color: "rgb(40, 156, 66)" }}
        className="bi bi-check-circle"></i>
        : <i style={{ color: "rgb(223, 203, 55)" }} className="bi bi-exclamation-circle-fill"></i>

    return (<> {ctx.alertModal.status &&
        <motion.div className="modal-wrapper" animate={{ opacity: 1 }} transition={{ duration: 0.3 }} exit={{ opacity: 1 }}>
            <motion.div className="modal-display p-4" initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }} transition={{ type: 'spring', duration: .3 }} exit={{ opacity: 0, scale: 0 }} >
                <div className="centerAlertModal">
                    {typeIcon}
                    <p> {ctx.alertModal.text}</p>

                    {ctx.alertModal.redirect 
                        ? <Link to={ctx.alertModal.redirect}>
                              <button onClick={() => {
                                ctx.setAlertModal({ status: false, text: '', type: "success", link: false, redirect: false });
                            }}>ok</button>
                        </Link>
                        :   (<button onClick={() => {
                                ctx.setAlertModal({ status: false, text: '', type: "success", link: false });
                                handle()
                            }}> Ok
                            </button>)
                    }

                    <div className="pt-4 linkMetamask">
                        {ctx.alertModal.link && <a target="_blank" href={ctx.alertModal.link} rel="noreferrer"> ¿Como usar metamask?, Click aqui </a>}
                    </div>
                </div>
            </motion.div>
        </motion.div>
    }
    </>
    )
}
export default AlertModal