export const config = {
    APIURL: 'https://somosdev.pw/api/v1/',
    SOCKETURL: 'https://somosdev.pw'
} 

// export const config = {
//     APIURL: 'http://localhost:5000/api/v1/',
//     SOCKETURL: 'http://localhost:5000'
// }

// export const config = {
//     APIURL: 'http://localhost:5000/api/v1/',
//     SOCKETURL: 'http://localhost:5000'
// }

/*  export const config = {
    APIURL: 'http://storege.somosdev.pw/api/v1/',
    SOCKETURL: 'http://storege.somosdev.pw'
}  */