import { NavLink, Link } from 'react-router-dom'
import logo from '../../img/logoAzul.webp'
import { DataContext } from '../../context/DataContext'
import { useContext, useEffect, useState } from 'react'
import Resume from '../../services/resumeWallet'
import DarkMode from '../darkMode/DarkMode'
import { getStorage, setStorage } from '../../services/localStorage'
import ModalRedes from '../modals/ModalRedes'
import bsc from '../../img/redes/bsc.webp'
import matic from '../../img/redes/matic.webp'
import eth from '../../img/redes/eth.svg'
import { useTransactions } from '../../hooks/useTransactions'
import socket from '../../socket'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from '../../css/home/home.module.css'
import Confirm from '../../components/modals/Confirm'

const Nav = () => {

    const { getTransactionsAll } = useTransactions()
    const { wallet, redesModal, setRedesModal, darkMode, setDarkMode, chain, userLevel, isLoggedIn, setIsLoggedIn } = useContext(DataContext)
    const [confirm, setConfirm] = useState(false)
    const [bell, setBell] = useState(0)

    const handleLogout = () => {
        // cerrar session
        localStorage.removeItem("loginAuth");
        setIsLoggedIn(false)
        setConfirm(false)
    }

    useEffect(() => {
        if (userLevel === 1) getNotifications()
    }, [userLevel])

    const getNotifications = async () => {
        let not = await getTransactionsAll() || []
        let notifications = not.filter(item => item.status == 3)
        /* //console.log(notifications)
        //console.log(notifications.length) */
        setBell(notifications.length)
    }

    const handleDarlk = () => {
        let local = getStorage('bitmarketperu')
        if (local === 'light') {
            setStorage('bitmarketperu', 'dark')
            setDarkMode(true)
        } else {
            setStorage('bitmarketperu', 'light')
            setDarkMode(false)
        }
    }

    //start websocket connection
    (() => {
        if (userLevel === 1) {
            socket.on('transactions', async transactions => {
                const notify = transactions.filter(item => item.status === 3)
                setBell(notify.length)
            })
        }
    })()

    return (
        <div>
            {confirm && <Confirm
                onConfirm={handleLogout}
                onCancel={setConfirm}
                text="¿Cerrar Sesión?"
            />}
            {redesModal && <ModalRedes />}
            <div className='d-none d-md-none d-lg-block p-2'>
                <div className={`${darkMode ? "principal-nav" : "principal-nav-light"}`}>
                    <div className='container-nav'>
                        <NavLink to='/home' className='nav-section-1' style={{ cursor: 'pointer' }}>
                            <LazyLoadImage
                                effect="blur"
                                src={logo}
                                alt="logo bitmarketperu"
                                srcSet=""
                            />
                            <span className={`${!darkMode && "nav-text-logo"}`}>BitmarketPerú</span>
                        </NavLink>
                        <div className='nav-section-2-nav'>
                            <DarkMode setDarkMode={() => handleDarlk()} darkMode={darkMode} />
                            <NavLink to='/home' className='nav-link'>
                                Inicio
                            </NavLink>
                            <a href='https://blog.bitmarketperu.com' className='nav-link' target='_blank' rel='noreferrer'>
                                Blog
                            </a>

                            {isLoggedIn &&
                                <NavLink to='/dashboard' className='nav-link'>
                                    Perfil
                                </NavLink>
                            }

                            <NavLink to='/swap' className='nav-link'>
                                Swap
                            </NavLink>
                            {isLoggedIn ?
                                <div onClick={() => setConfirm(true)} className={`btn-nav-login nav-link`}>
                                    Cerrar Sesión
                                </div>
                                : <NavLink to='/login' className={`btn-nav-login nav-link`}>
                                    Iniciar Sesión
                                </NavLink>
                            }


                            {userLevel == 1 && <>
                                <NavLink to='/admin' className='nav-link'>
                                    Admin
                                </NavLink>

                                <div className='mx-3'>
                                    {bell && bell > 0 ? <NavLink to='/admin' className='nav-link'> <div className='yellow'>
                                        {bell} <i className='bi-bell-fill' />
                                    </div> </NavLink> : <div>
                                        0 <i className='bi-bell-fill' />
                                    </div>}
                                </div></>}
                            <div className='nav-section-2-nav'>
                                <button onClick={() => setRedesModal(true)} className='connect-btn'>
                                    <div className='external-circle'>
                                        {chain && chain == 1 && <div className='internal-circle'>
                                            <LazyLoadImage effec="blur" className='redLogoWallet' src={eth} alt="" />
                                        </div>}
                                        {chain && chain == 56 && <div className='internal-circle'>
                                            <LazyLoadImage effec="blur" className='redLogoWallet' src={bsc} alt="" />
                                        </div>}
                                        {chain && chain == 137 && <div className='internal-circle'>
                                            <LazyLoadImage effec="blur" className='redLogoWallet' src={matic} alt="" />
                                        </div>}
                                        {!chain && <>
                                            <div className='internal-circle'>
                                                <i className='bi-wallet2 text-white' ></i>
                                            </div>
                                        </>}
                                    </div>
                                    <div>
                                        {wallet ? Resume(wallet) : <>Conectar Wallet</>}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobileMenu d-lg-none'>
                <NavLink to='/home' className='nav-linkMobile'>
                    <i className='bi bi-house'></i>
                    Inicio
                </NavLink>
                <a href='https://blog.bitmarketperu.com' className='nav-linkMobile' target='_blank' rel='noreferrer'>
                    <i className="bi bi-journals"></i>
                    Blog
                </a>
                {isLoggedIn &&
                    <NavLink to='/dashboard' className='nav-linkMobile'>
                        <i className='bi bi-person'></i>
                        Perfil
                    </NavLink>
                }
                <NavLink to='/swap' className='nav-linkMobile'>
                    <i className='bi bi-arrow-left-right'></i>
                    Swap
                </NavLink>
                {userLevel === 1 && <NavLink to='/admin' className='nav-linkMobile'>
                    <i className='bi bi-cash'></i>
                    Admin
                </NavLink>}
            </div>
        </div >
    )
}
export default Nav