import { useContext } from "react"
import { DataContext } from "../../../context/DataContext"
import logoMetamask from '../../../img/metamask.webp'
import logoBsc from '../../../img/redes/bsc.webp'
import wallet from '../../../img/wallet.webp'
const Crypto = () => {
    const ctx = useContext(DataContext)
    return (<>
        {ctx.selectedCoin1.symbol == "BTC" && <>
            <button onClick={() => ctx.setSendMethod(2)} className={ctx.sendMethod == 2 ? "btnTransactionSuccess" : "btnTransaction"}>
                <div className='btnInTrans'>
                    <div>
                        <img height="30px" src={logoBsc} alt="" />
                    </div>
                    <div className='mx-2'>
                        Binance Pay
                    </div>
                </div>
                <div className={ctx.sendMethod == 2 ? "transFontSuccess" : "transFont"}>
                    <i className='bi bi-check-circle'></i>
                </div>
            </button>

            <button onClick={() => ctx.setSendMethod(3)} className={ctx.sendMethod == 3 ? "btnTransactionSuccess" : "btnTransaction"}>
                <div className='btnInTrans'>
                    <div>
                        <img height="30px" src={wallet} alt="" />
                    </div>
                    <div className='mx-2'>
                        Billetera Externa
                    </div>
                </div>
                <div className={ctx.sendMethod == 3 ? "transFontSuccess" : "transFont"}>
                    <i className='bi bi-check-circle'></i>
                </div>
            </button>
        </>}

        {ctx.selectedCoin1.symbol == "USDT" && <>
            <button onClick={() => ctx.setSendMethod(1)} className={ctx.sendMethod == 1 ? "btnTransactionSuccess" : "btnTransaction"}>
                <div className='btnInTrans'>
                    <div>
                        <img height="30px" src={logoMetamask} alt="" />
                    </div>
                    <div className='mx-2'>
                        Metamask
                    </div>
                </div>
                <div className={ctx.sendMethod == 1 ? "transFontSuccess" : "transFont"}>
                    <i className='bi bi-check-circle'></i>
                </div>
            </button>

            <button onClick={() => ctx.setSendMethod(2)} className={ctx.sendMethod == 2 ? "btnTransactionSuccess" : "btnTransaction"}>
                <div className='btnInTrans'>
                    <div>
                        <img height="30px" src={logoBsc} alt="" />
                    </div>
                    <div className='mx-2'>
                        Binance Pay
                    </div>
                </div>
                <div className={ctx.sendMethod == 2 ? "transFontSuccess" : "transFont"}>
                    <i className='bi bi-check-circle'></i>
                </div>
            </button>

            <button onClick={() => ctx.setSendMethod(3)} className={ctx.sendMethod == 3 ? "btnTransactionSuccess" : "btnTransaction"}>
                <div className='btnInTrans'>
                    <div>
                        <img height="30px" src={wallet} alt="" />
                    </div>
                    <div className='mx-2'>
                        Billetera Externa
                    </div>
                </div>
                <div className={ctx.sendMethod == 3 ? "transFontSuccess" : "transFont"}>
                    <i className='bi bi-check-circle'></i>
                </div>
            </button>
        </>}

        {ctx.selectedCoin1.symbol == "BTC" && <>
            {ctx.sendMethod ? <>
                <button onClick={() => ctx.setTransactionSteap(2)} className='btnContinueSuccess mt-3'>
                    Continuar <i className="bi bi-arrow-right-square-fill" />
                </button>
            </> : <>
                <button className='btnContinue mt-3'>
                    Continuar <i className="bi bi-arrow-right-square-fill" />
                </button>
            </>}
        </>}
        
        {ctx.selectedCoin1.symbol == "USDT" && <>
            {ctx.sendMethod ? <>
                <button onClick={() => ctx.setTransactionSteap(2)} className='btnContinueSuccess mt-3'>
                    Continuar <i className="bi bi-arrow-right-square-fill" />
                </button>
            </> : <>
                <button className='btnContinue mt-3'>
                    Continuar <i className="bi bi-arrow-right-square-fill" />
                </button>
            </>}
        </>}
    </>)
}

export default Crypto