/* import { useContext } from "react"
//
//import Web3 from "web3"
import { NavLink } from 'react-router-dom'
import Loader from "../../components/loader/Loader"
import { DataContext } from "../../context/DataContext" */
import { motion} from 'framer-motion/dist/framer-motion'

//const web3 = new Web3("https://bsc-dataseed.binance.org/")
import { useState } from "react"
import Header from './Header'
import Franja from './Franja'
import Details from './Details'
import Chart from './Chart'
import Secure from './Secure'
import Footer from './Footer'
import { useContext } from "react"
import { DataContext } from "../../context/DataContext"
import AlertModal from '../../components/modals/alertModal'
import Memory from '../../components/modalMemory/Modal'

import 'react-lazy-load-image-component/src/effects/blur.css';
import NavMovil from '../../components/NavMovil/NavMovil';

const Landing = ()=> {

    // const {loading} = useContext(DataContext)

    const { alertModal, setAlertModal } = useContext(DataContext)

    const [modal, setModal] = useState(false)

    return (<>
        {modal ? (<Memory setModal={setModal} />)
        : 
            (<div>
            {/* {loading && <Loader/>}*/}   
            {alertModal.status && <AlertModal setAlertModal={setAlertModal} alertModal={alertModal} />}
            <NavMovil />
            <Header />
            <motion.div>
                <Franja />
            </motion.div>
            <Details />
            <Chart />
            <motion.div>
                <Franja />
            </motion.div>
            <Secure />
            <Footer />
        </div>)
        }
        
        </>)
}

export default Landing