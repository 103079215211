import Porcentajes from "../../components/admin/porcentajes"
import Usuarios from "../../components/admin/usuarios"
import Bank from "../../components/bank/bank"
import Transacciones from "../../components/admin/transacciones"
import Config from "../../components/admin/config"
import { useState, useContext } from "react"
import adminMenu from '../../pages/admin/adminMenu.json'
import MenuRenderer from "../../components/menu/menu"
import { DataContext } from "../../context/DataContext"
import NavMovil from '../../components/NavMovil/NavMovil';
import { useEffect } from "react"
import { jwtDecode } from "jwt-decode"
import { Redirect } from 'react-router-dom'

const Admin = () => {

    const [menu, setMenu] = useState(5)
    const { darkMode } = useContext(DataContext);

    /*  useEffect(() => {
         isAdmin()
     }, []) */
    const isAdmin = () => {
        let token = localStorage.getItem('loginAuth')
        let decodedToken = jwtDecode(token)
        if (decodedToken?.level === 0) {
            return true
        }
        return false 
    }

    try {
        if (isAdmin()) { return <Redirect to="/dashboard" /> }
    } catch (error) {
        console.error(error);
        return <Redirect to="/dashboard" />
    }

  
    return (<>
        <NavMovil />
        <div className='container-adminxx' style={{ paddingBottom: "50px" }} /*style={dataStyle}*/>
            <div className="container-xxl">
                <div className="row mt-3">
                    <div className="col-12 col-lg-2">
                        <div className={darkMode ? 'adminHeader' : "adminHeaderLight"}>
                            <h4> Administrador </h4>
                            <p> Nivel 1 </p>
                        </div>
                        <ul>
                            <MenuRenderer setMenu={setMenu} menuConfig={adminMenu} darkMode={darkMode} />
                        </ul>
                    </div>
                    <div className="col-12 col-lg-10 text-white">
                        {menu === 5 && <Transacciones />}
                        {menu === 1 && <Porcentajes />}
                        {menu === 2 && <Usuarios />}
                        {menu === 3 && <Bank />}
                        {menu === 4 && <Config />}
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Admin