import usdt from "../img/monedas/usdt.webp"
import usd from "../img/monedas/usd.webp"
import sol from "../img/monedas/sol.webp"
import btc from "../img/monedas/bitcoin.webp"
let CoinList = [
    { name: "Tether",  symbol: "USDT", img: usdt, category: "usdt",type:"crypto" },
    { name: "Soles",   symbol: "SOL",  img: sol,  category: "soles",type:"fiat" },
    { name: "Dólares", symbol: "USD",  img: usd,  category: "dolares",type:"fiat" },
    { name: "Bitcoin", symbol: "BTC",  img: btc,  category: "btc",type:"crypto" }
]
export default CoinList