import { useState, useContext, useEffect } from "react"
import { DataContext } from "../../context/DataContext"
import Loader from "../loader/Loader"
import AddBankModal from "../modals/addBankModal"
import ModalConfirm from "../modals/ModalConfirm"
import LogoBank from "../logoBank/logoBank"
import AlertModal from '../../components/modals/alertModal'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'
import { jwtDecode } from 'jwt-decode'

const Bank = () => {
    const { addBanks, loading, userBanks,
        dni, setAddBanks, deleteBank, alertModal, setAlertModal, getUserBanks
    } = useContext(DataContext)

    useEffect(() => {
        const token = localStorage.getItem('loginAuth')
        if(token){
            const decodedToken = jwtDecode(token)
            getUserBanks(decodedToken.dni) 
        }

    }, []) 

    const [modalConfirm, setModalConfirm] = useState(false)
    const [bankSelected, setBankSelected] = useState({ idBank: "", dni: "" })

    const handleDelete = (idBank, dni) => {
        setBankSelected({ idBank, dni })
        setModalConfirm(true)
    }


    return (<div className="mh-100vh">
        {loading && <Loader />}
        <AnimatePresence key='123'>
            {addBanks && <AddBankModal />}
            {modalConfirm && <ModalConfirm text="¿Seguro que desea Eliminar?" handleAcept={deleteBank} handleCancel={setModalConfirm} payload={bankSelected} />}
            <AlertModal setAlertModal={setAlertModal} alertModal={alertModal} />
        </AnimatePresence>
        <motion.div
            className="bgDark"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}
        >
            <div className="bancos">
                <h4>Cuentas de banco</h4>
                <button onClick={() => setAddBanks(true)}> <i className='bi-plus-circle' /> Agregar banco</button>
            </div>

            {userBanks ? userBanks.map((item) => {
                return (
                    <div key={item._id} className='mt-1'>
                        <div className="bList">
                            <div className="bListIn">
                                <LogoBank name={item.name} />
                                <div>
                                    <h4>{item.name.toUpperCase()} - {item.money}</h4>
                                    <b>{item.number} <br /> {item.titular}</b>
                                    <h3>{item.type}</h3>
                                </div>
                            </div>
                            <i onClick={() => handleDelete(item._id, dni)} className='bi bi-trash deleteButton' />
                        </div>
                    </div>
                )
            }) :
                <>No posee bancos registrados</>}
        </motion.div>
    </div>)
}
export default Bank