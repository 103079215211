import { useEffect, useState } from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import './adminTransaction.css'
import LogoBank from '../logoBank/logoBank'
import { useChat } from '../../hooks/useChat.jsx'
import { getStorage } from '../../services/localStorage'
import copy from 'copy-to-clipboard'
import { config } from '../../config.js'
/* const getChainText = (chainId) => chainId == 1 ? "ETH" : chainId == 56 ? "BSC" : "MATIC" */

const AdminTransaction = ({ redName, setShowDetails, transactionSelected, dataStatus, getTransactionsAll }) => {

    const { _id, id, moneySend, amountSend, moneyReceive, amountReceive, date, network, bank, bankAdmin, status, payMethod, reciveNetwork, reciveUser, wallet, user } = transactionSelected

    const { dataMsg, down, msg, setMsg, handleMsg, startSocket, getChat } = useChat(_id)
    // const [dataUser, setdataUser] = useState(null)
    const [objectStyle, setObjectStyle] = useState({ cursor: "pointer" });

    // const [textCopy, setTextCopy ] = useState('');

    const setTime = (date) => {
        let fecha = new Date(date)
        let time = `${String(fecha.getFullYear())}-${String(fecha.getMonth() + 1).padStart(2, '0')}-${String(fecha.getDate()).padStart(2, '0')}`;
        let hour = `${String(fecha.getHours()).padStart(2, '0')} : ${String(fecha.getMinutes()).padStart(2, '0')}`;
        return { time, hour }
    }

    const handleStatus = async (status) => {
        let token = getStorage('bitmarketperu') || null
        await fetch(`${config.APIURL}transactions/${_id}/${status}`, {
            method: 'PUT',
            headers: {
                'Container-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        getTransactionsAll()
        setShowDetails(false)
    }

    /* const getUser = async (wallet) => {
        const query = await fetch(`${process.env.REACT_APP_APIURL}auth/${wallet}`)
        const user = await query.json()
        setdataUser(user.response)
    } */

    // useEffect(() => {
    //     getChat()
    //     const getUser = async (wallet) => {
    //         const query = await fetch(`${config.APIURL}auth/${wallet}`)
    //         const user = await query.json()
    //         setdataUser(user.response)
    //     }
    //     getUser(wallet)
    // }, [getChat,  wallet]);
    startSocket();

    const handleClick = () => {

        copy(reciveUser);
        setObjectStyle({ ...objectStyle, fontSize: "16px", position: "relative" });

        setTimeout(() => {
            setObjectStyle({ ...objectStyle, fontSize: "12px", position: "relative" });
        }, 100)

    }

    return (<>
        <motion.div
            className="details-container"
            initial={{ opacity: 0, x: 200, y: -100, scale: .7, position: 'relative' }}
            animate={{ opacity: 1, x: 0, y: 0, scale: 1 }}
            transition={{ type: 'spring', duration: .3 }}
        >
            <div className="details-card">
                <div className='details-item-transaction'>
                    <span className="item-title">N° Transacción</span>
                    <span>#{_id.slice(-6)}</span>
                </div>
                <div className='details-item-hash'>
                    <span className="item-title">Hash/Id</span>
                    <span>{id}</span>
                </div>
                <div className='details-item-send'>
                    <span className="item-title">Usuario envia</span>
                    <span>{amountSend} {moneySend}</span>
                </div>
                <div className='details-item-recive'>
                    <span className="item-title">Usuario Recibe</span>
                    <span>{amountReceive} {moneyReceive}</span>
                </div>
                <div className='details-item-date'>
                    <span className="item-title">Fecha</span>
                    <span>{setTime(date).time} | {setTime(date).hour}</span>
                </div>
                <div className='details-item-network'>
                    <span className="item-title">Red</span>
                    <span>{network}</span>
                </div>
                <div className='details-item-pay'>
                    <span className="item-title">Metodo de pago</span>
                    <span>{payMethod.method} {payMethod.method !== 'efectivo' && redName[payMethod.red]} </span>
                </div>
                <div className='details-item-through'>
                    <span className="item-title">Mediante</span>

                    {payMethod.pay !== 'fiat' && <span style={{ position: 'absolute', maxWidth: '170px', top: '15px' }}> {(payMethod.pay)} </span>}

                    {bankAdmin !== null && <>

                        <div className='bank-card'>
                            <div style={{ width: '100%' }} className='cardBank'>
                                <div className='card-img'><LogoBank name={bankAdmin.name} /></div>
                                <div className='card-content'>
                                    <div>{bankAdmin.name}</div>
                                    <div>{bankAdmin.titular}</div>
                                    <div>{bankAdmin.number}</div>
                                </div>
                                <div className='card-footer'>
                                    <div>{bankAdmin.type}</div>
                                    <div>{bankAdmin.money}</div>
                                </div>
                            </div>
                        </div>


                    </>}
                </div>
                <div className='methodRecive'>
                    <span className="item-title">Recibe en</span>
                    {!bank && !bankAdmin ? <span> Efectivo </span>
                        : !bank && reciveNetwork === 'n/a' && reciveUser === 'n/a' && <span> Efectivo </span>}
                    {!bank && reciveUser !== 'n/a' && <span> <i style={objectStyle} className="bi bi-clipboard" onClick={() => handleClick()}></i> {reciveUser} {redName[reciveNetwork]} </span>}
                    {bank !== null && <>
                        <div className='bank-card'>
                            <div className='cardBank'>
                                <div className='card-img'><LogoBank name={bank.name} /></div>
                                <div className='card-content'>
                                    <div>{bank.name}</div>
                                    <div>{bank.number}</div>
                                </div>
                                <div className='card-footer'>
                                    <div>{bank.type}</div>
                                    <div>{bank.money}</div>
                                </div>
                            </div>
                        </div></>}
                    {/* {reciveMethod === 'efectivo' && <>
                        <span> Efectivo </span>
                    </>} */}
                    {/* {bank !== null && <>
                    <div className='bank-card'>
                        <div className='cardBank'>
                            <div className='card-img'><LogoBank name={bank.name}/></div>
                            <div className='card-content'>
                                <div>{bank.name}</div>
                                <div>{bank.number}</div>
                            </div>
                            <div className='card-footer'>
                                <div>{bank.type}</div>
                                <div>{bank.money}</div>
                            </div>
                        </div>
                    </div>
                        
                    </>} */}

                </div>
                <div className='details-item-user'>
                    <span className="item-title">Usuario</span>
                    <span>{user && (<>
                        <span>{user.name}</span><br />
                        <span>{user.email}</span><br />
                        <span>{user.phone}</span><br />
                    </>)}
                    </span>
                </div>
                <div className='details-item-control'><span className="item-title">Modificar</span>
                    <span>
                        <button onClick={() => handleStatus(2)}>Finalizar</button>
                        <button onClick={() => handleStatus(1)}>Cancelar</button>
                    </span>
                </div>
                {/* <div className='details-item-bank card-bank-container'>     */}

                {/* <div className='bank-card'>
 
                        {moneyReceive == "SOLES" | moneyReceive == "USD" ? <>
                            <span className="item-title">Banco Donde Recibes</span>
                            {bank && (
                                <div className='cardBank'>
                                    <div className='card-img'>
                                        <LogoBank name={bank.name} />
                                    </div>
                                    <div className='card-title'>{bank.name}</div>
                                    <div className='card-content'>
                                        <span>{bank.titular}</span>
                                        <span>{bank.number}</span>
                                    </div>
                                    <div className='card-footer'>
                                        <span>{bank.type}</span>
                                        <span>{bank.money}</span>
                                    </div>
                                </div>
                            )}
                        </> : moneyReceive && <>
                            <> Recibe {moneyReceive} </><br />
                            {payMethod.red && <>red: {payMethod.red} <br /></>}
                            A Travez: {payMethod && payMethod.method == 1 ? <> Binance Pay </> : <> Wallet Externa</>}<br />
                            Direccion: {payMethod && payMethod.pay} <br />
                        </>
                        }

                    </div>
                    <div className='bank-card'>
                        {moneySend == "SOLES" | moneySend == "USD" ? <>
                            <span className="item-title">Banco Donde Envio</span>
                            {bankAdmin && (
                                <div className='cardBank'>
                                    <div className='card-img'>
                                        <LogoBank name={bankAdmin.name} />
                                    </div>
                                    <div className='card-title'>{bankAdmin.name}</div>
                                    <div className='card-content'>
                                        <span>{bankAdmin.titular}</span>
                                        <span>{bankAdmin.number}</span>
                                    </div>
                                    <div className='card-footer'>
                                        <span>{bankAdmin.type}</span>
                                        <span>{bankAdmin.money}</span>
                                    </div>
                                </div>
                            )}
                        </>:
                        moneySend && <>
                            <> Recibe {moneySend} </><br />
                            {payMethod.red && <>
                            red: {payMethod.red} <br /></>}
                            A Travez: {payMethod && payMethod.method == 1 ? "Binance Pay": "Wallet Externa"}<br />
                            Direccion: {payMethod && payMethod.pay} <br />
                        </>}
                    </div> */}

                {/* </div> */}
                <div className='details-item-status'>
                    <span className="item-title">Estado</span>
                    <span>{dataStatus[status].msg}</span>
                    <span className={`transaction-icon ${dataStatus[status].class}`}>{dataStatus[status].icon}</span>
                </div>
                {/* <div className='details-item-wallet'>
                    <span className="item-title">Wallet</span>
                    <span>qiweuqoiewfuiadbfjshdfbhsdf</span>
                </div> */}
                {/* <div className='details-item-user'>
                    <span className="item-title">Usuario</span>
                    <span>{true && (<>
                    Nombre: <span>Jason Hernandez</span><br />
                    Correo: <span>kaltre10@gmail.com</span><br />
                    Telefono: <span>917545622</span><br />
                </>) }
                </span></div> */}
                {/* <div className='details-item-control'><span className="item-title">Modificar</span> 
                    <span>
                        <button onClick={() =>handleStatus(2)}>Finalizar</button>
                        <button onClick={() =>handleStatus(1)}>Cancelar</button>
                    </span>
                </div> */}
            </div>

            <div className='details-card-chat' >
                <div className='chat'>
                    <div className='chat-title'>
                        <span className='chat-online'></span>
                        <span>Online</span>
                    </div>
                    <div ref={down} className={dataMsg.length > 0 ? 'chat-active' : 'chat-content'}>
                        {dataMsg.length <= 0 ? (<>
                            <div className='chat-icon'><i className="bi bi-chat-left-text-fill" /></div>
                            <p>Estamos disponible para cualquier consulta</p>
                        </>)
                            : (<>
                                {(dataMsg.map(m => (
                                    <div
                                        key={m._id} className={m.idUser.level === 0 ? "chat-content-item" : "chat-content-item-admin"}>
                                        <span className="chat-content-time">{setTime(m.date).hour}</span>
                                        <p className={m.idUser.level === 0 ? "chat-content-p" : "chat-content-admin"}>{m.description}</p>
                                    </div>
                                )))}
                            </>)
                        }

                    </div>
                    <div className='chat-from' style={{ opacity: status !== 3 ? .6 : 1 }}>
                        <input
                            onKeyDown={e => e.key === "Enter" && handleMsg()}
                            type='text'
                            name='msg'
                            value={msg}
                            placeholder='Escribe un mensaje...'
                            onChange={e => setMsg(e.target.value)}
                            autoComplete="off"
                            disabled={status !== 3}
                        />
                        <button
                            onClick={() => handleMsg()}
                            disabled={status !== 3}
                        >Enviar</button>
                    </div>
                </div>
            </div>
        </motion.div>
    </>)
}

export default AdminTransaction;