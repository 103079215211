import { useContext } from "react"
import { DataContext } from "../../../context/DataContext"
import logoBsc from '../../../img/redes/bsc.webp'
import wallet from '../../../img/wallet.webp'
const Crypto2 = () => {
    const ctx = useContext(DataContext)
    return (<>
 
        <button onClick={() => ctx.setReciveMethod(2)} className={ctx.reciveMethod == 2 ? "btnTransactionSuccess" : "btnTransaction"}>
            <div className='btnInTrans'>
                <div>
                    <img height="30px" src={logoBsc} alt="" />
                </div>
                <div className='mx-2'>
                    Binance Pay
                </div>
            </div>
            <div className={ctx.reciveMethod == 2 ? "transFontSuccess" : "transFont"}>
                <i className='bi bi-check-circle'></i>
            </div>
        </button>

        <button onClick={() => ctx.setReciveMethod(3)} className={ctx.reciveMethod == 3 ? "btnTransactionSuccess" : "btnTransaction"}>
            <div className='btnInTrans'>
                <div>
                    <img height="30px" src={wallet} alt="" />
                </div>
                <div className='mx-2'>
                    Billetera Externa
                </div>
            </div>
            <div className={ctx.reciveMethod == 3 ? "transFontSuccess" : "transFont"}>
                <i className='bi bi-check-circle'></i>
            </div>
        </button>

        {ctx.reciveMethod == 2 && <div className="my-3">
            Indique su correo de Binance Pay
            <input id="binanceCorreo" onChange={(e) => ctx.setReciveUser(e.target.value)} type="email" className="transaction-input w-100" value={!ctx.reciveUser ? "" : ctx.reciveUser} />
        </div>}

        {ctx.reciveMethod == 3 && <div className="">
            Indique la red donde recibe
            <div className="red-container">


                {ctx.selectedCoin2.symbol == "BTC" && <>
                    <div onClick={() => ctx.setReciveNetwork("7")} className={ctx.reciveNetwork == 7 ? 'red-item red-item-active' : 'red-item'}>
                        <p>Bitcoin</p>
                        <i className={ctx.reciveNetwork == 7 ? "bi bi-check-circle-fill red-check" : "bi bi-check-circle-fill"}></i>
                    </div>
                </>}

                <div onClick={() => ctx.setReciveNetwork("1")} className={ctx.reciveNetwork == 1 ? 'red-item red-item-active' : 'red-item'}>
                    <p>ETH</p>
                    <i className={ctx.reciveNetwork == 1 ? "bi bi-check-circle-fill red-check" : "bi bi-check-circle-fill"}></i>
                </div>

                <div onClick={() => ctx.setReciveNetwork("2")} className={ctx.reciveNetwork == 2 ? 'red-item red-item-active' : 'red-item'}>
                    <p>BSC</p>
                    <i className={ctx.reciveNetwork == 2 ? "bi bi-check-circle-fill red-check" : "bi bi-check-circle-fill"}></i>
                </div>

                {ctx.selectedCoin2.symbol == "USDT" && <>
                    <div onClick={() => ctx.setReciveNetwork("3")} className={ctx.reciveNetwork == 3 ? 'red-item red-item-active' : 'red-item'}>
                        <p>MATIC</p>
                        <i className={ctx.reciveNetwork == 3 ? "bi bi-check-circle-fill red-check" : "bi bi-check-circle-fill"}></i>
                    </div>
                    <div onClick={() => ctx.setReciveNetwork("4")} className={ctx.reciveNetwork == 4 ? 'red-item red-item-active' : 'red-item'}>
                        <p>AVAX</p>
                        <i className={ctx.reciveNetwork == 4 ? "bi bi-check-circle-fill red-check" : "bi bi-check-circle-fill"}></i>
                    </div>
                    <div onClick={() => ctx.setReciveNetwork("5")} className={ctx.reciveNetwork == 5 ? 'red-item red-item-active' : 'red-item'}>
                        <p>SOL</p>
                        <i className={ctx.reciveNetwork == 5 ? "bi bi-check-circle-fill red-check" : "bi bi-check-circle-fill"}></i>
                    </div>
                    <div onClick={() => ctx.setReciveNetwork("6")} className={ctx.reciveNetwork == 6 ? 'red-item red-item-active' : 'red-item'}>
                        <p>TRX</p>
                        <i className={ctx.reciveNetwork == 6 ? "bi bi-check-circle-fill red-check" : "bi bi-check-circle-fill"}></i>
                    </div>
                </>}
            </div>

            Indique su wallet
            <input onChange={(e) => ctx.setReciveUser(e.target.value)} type="text" className="transaction-input w-100" value={!ctx.reciveUser ? "" : ctx.reciveUser} />
        </div>}

        {ctx.reciveMethod == 2 && ctx.reciveUser || ctx.reciveMethod == 3 && ctx.reciveUser ? <>
            <button onClick={() => ctx.setTransactionSteap(3)} className='btnContinueSuccess mt-3'>
                Continuar <i className="bi bi-arrow-right-square-fill" />
            </button>
        </> : <>
            <button className='btnContinue mt-3'>
                Continuar <i className="bi bi-arroxsw-right-square-fill" />
            </button>
        </>
        }
    </>)
}

export default Crypto2