import { useContext } from "react"
import { DataContext } from "../../context/DataContext"
import { motion } from 'framer-motion/dist/framer-motion'
import styles from '../../css/landing/landing.module.css'
import picture from '../../img/key.webp'
import metamask from '../../img/metamask.webp'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Header = () => {

    const { darkMode } = useContext(DataContext);

    return ( 
        <div className={darkMode ? styles.header : styles.headerLight}>
            <div className={darkMode ? styles.headerTitle : styles.headerTitleLight}>
                <motion.h1
                    initial={{ opacity: 0, y: -50  }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <span className={styles.headerOverline}>Ahorra en Dólares con tu propia cuenta de</span><br />
                    <span className={styles.headerTextSpan}>Criptomonedas</span>
                    <p>
                        <span> con Metamask</span>
                        <a href="https://blog.bitmarketperu.com/que-es-metamask/" target="_blank" rel="noreferrer">
                            <LazyLoadImage 
                                style={{
                                    /* From https://css.glass */
                                    width: "60px",
                                    background: "rgba(255, 255, 255, 0.15)",
                                    borderRadius: "16px",
                                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                                    backdropFilter: "blur(8.3px)",
                                    border: "1px solid rgba(255, 255, 255, 0.27)",
                                    marginLeft: "10px",
                                    padding: "5px",
                                    marginTop: "5px"

                                }}
                                src={metamask} alt="" title="metamask" 
                                effect="blur"
                                width="60px"
                                height="60px"
                            />
                        </a>
                    </p>
                </motion.h1>
                <motion.p 
                    initial={{ opacity: 0, x: -50  }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className={darkMode ? styles.headerParrafo : styles.headerParrafoLight }>Somos la plataforma más fácil para intercambiar criptomonedas por dinero en Perú.
                </motion.p>
                <motion.div
                    initial={{ opacity: 0, y: 50, display: 'flex'  }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <Link className={styles.btnDetails} to="tutorial">Como Funciona?</Link>
                    <Link className={styles.btnTrade} to="swap">Cambiar Ahora</Link>
                </motion.div>
            </div>
            <div className={styles.headerPicture}>
                <motion.div
                    initial={{ opacity: 0, x: 50, y: 40 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                >
                    <LazyLoadImage 
                        className={styles.picture} 
                        src={picture} alt=""
                        effect="blur"
                        />
                </motion.div>
            </div> 
        </div>
     );
}
 
export default Header;