import { useContext } from 'react'
import styles from '../../css/landing/chart.module.css'
import styleBtn from '../../css/landing/landing.module.css'
import landing from '../../css/landing/landing.module.css'
import picture from '../../img/chart.webp'
import { motion } from 'framer-motion/dist/framer-motion'
import { DataContext } from '../../context/DataContext'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Chart = () => {

    const { darkMode } = useContext(DataContext)

    return ( 
        <div className={darkMode ? styles.chart : styles.chartLight }>
            <div className={styles.details}>
                <div 
                    className={styles.containerText}>
                    <motion.h3 
                        className={darkMode ? styles.titleChart : styles.titleChartLight}
                        initial={{ opacity: 0, y: -100 }}
                        whileInView={{ opacity: 1, y: 0}}
                        transition={{ duration: 1 }}
                    >Cambia tus criptos rápido y Fácil.</motion.h3>
                    <motion.div
                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0}}
                        transition={{ duration: 1 }}
                    >
                        {/* <h4 className={darkMode ? styles.subTitleChart : styles.subTitleChartLight }>sin registro, sin molestias</h4> */}
                        <p className={darkMode ? styles.paragraphChart : styles.paragraphChartLight}>Trabajamos con las redes Polygon, Smart Chain y Ethereum</p>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 100, display: 'flex' }}
                        whileInView={{ opacity: 1, y: 0}}
                        transition={{ duration: 1 }}
                    >
                        <a className={styleBtn.btnDetails} href="https://blog.bitmarketperu.com/como-usar-bitmarketperu/" target="_blank" rel="noreferrer">Como Funciona?</a>
                        <Link className={styleBtn.btnTrade} to="swap">Cambiar Ahora</Link>
                    </motion.div>
                </div>
               
            </div>
            <div className={darkMode ? styles.picture : styles.pictureLight }>
                <motion.div
                    initial={{ opacity: 0, x: 200 }}
                    whileInView={{ opacity: 1, x: 0}}
                    transition={{ duration: 1 }}
                >
                    <LazyLoadImage 
                        effect='blur'
                        className={styles.imgChart} 
                        src={picture} alt=""
                    />
                </motion.div>
            </div>
        </div> 
    );
}
 
export default Chart;