import { useContext, useEffect } from "react";
import { DataContext } from "../../../context/DataContext"
import bank from '../../../img/bank.png'
import SelectAdminBank from "../../modals/selectAdminBank";
// import LogoBank from "../../logoBank/logoBank";
import BankComponent from '../../bankComponent/BankComponent'
import AddressComponent from '../../AddressComponent/AddressComponent'
const Fiat = () => {
    const ctx = useContext(DataContext)

    useEffect(() => {
        ( async () => {
            const banksAdmin = await ctx.getAdminBanks();
            ctx.setBanksAdmin(banksAdmin);
        })()
    }, []);

    return (<>
        <SelectAdminBank />
        {ctx.selectedCoin1.symbol == "SOL" | ctx.selectedCoin1.symbol == "USD" ? <>

            <button onClick={() => { ctx.setSendMethod(4); ctx.setAdminBank(ctx.nullId) }} className={ctx.sendMethod == 4 ? "btnTransactionSuccess" : "btnTransaction"}>
                <div className='btnInTrans'>
                    <div>
                        <i className="item-method bi bi-cash-coin" style={{ fontSize: "30px" }}></i>
                    </div>
                    <div className='mx-2'>
                        Efectivo en Ventanilla
                    </div>
                </div>
                <div className={ctx.sendMethod == 4 ? "transFontSuccess" : "transFont"}>
                    <i className='bi bi-check-circle'></i>
                </div>
            </button>

            <button onClick={() => { ctx.setConfirmRecive(false); ctx.setSendMethod(5) }} className={ctx.sendMethod == 5 ? "btnTransactionSuccess" : "btnTransaction"}>
                <div className='btnInTrans'>
                    <div>
                        <i className="item-method bi bi-bank" style={{ fontSize: "30px" }}></i>
                    </div>
                    <div className='mx-2'>
                        Transferencia Bancaria
                    </div>
                </div>
                <div className={ctx.sendMethod == 5 ? "transFontSuccess" : "transFont"}>
                    <i className='bi bi-check-circle'></i>
                </div>
            </button>



            {ctx.sendMethod == 5 && <>
                <div className="bank-container">
                    <div onClick={() => { ctx.setBankList(ctx.banksAdmin); ctx.setSelectAdminBank(true) }} className="bank-item w-100">
                        <img src={bank} alt='' />
                        Seleccionar Banco
                    </div>
                </div>
            </>}

            <div className=" p-2 mw-350">

                {ctx.sendMethod == 4 && <div className="m-1">
                    <div className="mb-3 mt-2">
                        <input checked={ctx.confirmRecive} onChange={(e) => { ctx.setConfirmRecive(e.target.checked); ctx.setAdminBank(ctx.nullId) }} type="checkbox" name="" id="check" className="mx-2" /> 
                        <label htmlFor="check">
                            Confirmo pagar por ventanilla
                        </label>
                    </div>
                    <AddressComponent />
                </div>}
            </div>

            {ctx.adminBank.name && <>
                Transfiera a la siguiente cuenta de Banco:
                <BankComponent dataBank={ctx.adminBank} />
            </>}

            {ctx.sendMethod == 5 && ctx.adminBank != ctx.nullId || ctx.sendMethod == 4 && ctx.confirmRecive ? <>
                <button onClick={() => ctx.setTransactionSteap(2)} className='btnContinueSuccess mt-3'>
                    Continuar <i className="bi bi-arrow-right-square-fill" />
                </button>
            </> : <>
                <button className='btnContinue mt-3'>
                    Continuar <i className="bi bi-arrow-right-square-fill" />
                </button>
            </>}
        </> : <></>}
    </>);
}

export default Fiat;