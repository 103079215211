import styles from '../../css/landing/call.module.css'
import { useContext } from 'react'
import { DataContext } from '../../context/DataContext'

const Call = () => {

    const { darkMode } = useContext(DataContext)

    return ( 
        <div className={darkMode ? styles.call : styles.callLight}>
            <p>¿Tienes dudas? contáctanos para asesoramiento gratuito vía WhatsApp <i className="bi bi-whatsapp"></i> <br/><span className={styles.number}><a className={darkMode ? styles.link : styles.linkLight} href="https://api.whatsapp.com/send?phone=51955269142" target="_blank" rel="noreferrer">955 269 142</a></span></p>
        </div>
     );
}
 
export default Call;