import { useContext } from "react"
import { DataContext } from "../../context/DataContext"
import { motion } from 'framer-motion/dist/framer-motion'
import styles from '../../css/landing/tutorial.module.css'
import metamask from '../../img/metamask.webp'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import logo from '../../img/logoAzul.webp'
import swap from '../../img/swap.png'
import esperar from '../../img/esperar.png'
import Call from '../landing/Call'
import 'react-lazy-load-image-component/src/effects/blur.css';

const Header = () => {

    const { darkMode } = useContext(DataContext);

    return (<>
        <div className={darkMode ? styles.header : styles.headerLight}>
            <div className={darkMode ? styles.headerTitle : styles.headerTitleLight}>
                <motion.h1
                    initial={{ opacity: 0, y: -50  }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <span className={styles.headerOverline}>Como Cambiar en </span><br />
                    <span className={styles.headerTextSpan}>Bitmarketperu?</span>
                    <p>
                      
                    </p>
                </motion.h1>
            </div>
            <div className={darkMode ? styles.arrow : styles.arrowLight}><i className="bi bi-arrow-down"></i></div>
        </div>
        <div className={styles.containerMap}>
            <div className={darkMode ? styles.card : styles.cardLight}>
                <div className={styles.cardNum}>1</div>
                <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                        <LazyLoadImage 
                                style={{
                                    /* From https://css.glass */
                                    width: "60px",
                                    background: "rgba(255, 255, 255, 0.15)",
                                    borderRadius: "16px",
                                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                                    backdropFilter: "blur(8.3px)",
                                    border: "1px solid rgba(255, 255, 255, 0.27)",
                                    marginLeft: "10px",
                                    padding: "5px",
                                    marginTop: "5px"

                                }}
                                src={metamask} alt="Metamask" title="metamask" 
                                effect="blur"
                                width="60px"
                                height="60px"
                            />
                        <h2>Instalar Metamask</h2>
                    </div>
                </div>
                <p className={styles.cartContent}>Lo primero que debes hacer es instalar metamask, ya sea desde el teléfono móvil en la <a className={darkMode ? styles.paragraph : styles.paragraphLight } target="_blank" href="https://play.google.com/store/apps/details?id=io.metamask&hl=es_PE&gl=US" alt="Play Store - Metamask" rel="noreferrer">play store</a> o si es desde la computadora en la pagina oficial de <a className={darkMode ? styles.paragraph : styles.paragraphLight } target="_blank" href="https://metamask.io/" alt="Play Store - Metamask" rel="noreferrer">metamask</a> y seguir las instrucciones, para más información consulta nuestro tutorial <a className={darkMode ? styles.paragraph : styles.paragraphLight } target="_blank" href="https://blog.bitmarketperu.com/que-es-metamask/" alt="Que es Metamask?" rel="noreferrer">aquí</a>.
</p>
            </div>
        </div>
        {/* <div className={darkMode ? styles.arrow : styles.arrowLight}><i classNAme="bi bi-arrow-down"></i></div> */}
        <div className={styles.containerMap}>
            <div  className={darkMode ? styles.card : styles.cardLight}>
                <div className={styles.cardNum}>2</div>
                <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                        <LazyLoadImage 
                                style={{
                                    /* From https://css.glass */
                                    width: "60px",
                                    background: "rgba(255, 255, 255, 0.15)",
                                    borderRadius: "16px",
                                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                                    backdropFilter: "blur(8.3px)",
                                    border: "1px solid rgba(255, 255, 255, 0.27)",
                                    marginLeft: "10px",
                                    padding: "5px",
                                    marginTop: "5px"

                                }}
                                src={logo} alt="bitmarketperu.com" title="bitmarketperu.com" 
                                effect="blur"
                                width="60px"
                                height="60px"
                            />
                        <h2>Crear tu cuenta en bitmarketperu.com</h2>
                    </div>
                </div>
                <p className={styles.cartContent}>Desde el teléfono movil, por medio del "navegador de metamask" accedemos a bitmarketperu.com, le damos los permisos correspondientes y conectamos con la plataforma, por la computadora acceda por google chrome, luego en el perfil de su cuenta completamos nuestros datos y enviamos los requisitos que nos piden para verificar nuestra cuenta. para más información consulte nuestro <a className={darkMode ? styles.paragraph : styles.paragraphLight } target="_blank" href="https://blog.bitmarketperu.com/como-usar-bitmarketperu/" alt="Como usar Bitmarketperu?" rel="noreferrer">tutorial</a>.</p>
            </div>
        </div>
        {/* <div className={darkMode ? styles.arrow : styles.arrowLight}><i className="bi bi-arrow-down"></i></div> */}
        <div className={styles.containerMap}>
            <div className={darkMode ? styles.card : styles.cardLight}>
                <div className={styles.cardNum}>3</div>
                <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                        <LazyLoadImage 
                                style={{
                                    /* From https://css.glass */
                                    width: "60px",
                                    background: "rgba(255, 255, 255, 0.15)",
                                    borderRadius: "16px",
                                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                                    backdropFilter: "blur(8.3px)",
                                    border: "1px solid rgba(255, 255, 255, 0.27)",
                                    marginLeft: "10px",
                                    padding: "5px",
                                    marginTop: "5px"

                                }}
                                src={swap} alt="bitmarketperu.com" title="bitmarketperu.com" 
                                effect="blur"
                                width="60px"
                                height="60px"
                            />
                        <h2>Realizar el Intercambio</h2>
                    </div>
                </div>
                <p className={styles.cartContent}>Con su cuenta verificada puede realizar el intercambio desde la opcion "swap" del menu de bitmarketperu.com y seguir las instrucciones, recuerde colocar correctamente los datos como las monedas y la cantidad para que proceda de manera exitosa. para más información consulte nuestro <a className={darkMode ? styles.paragraph : styles.paragraphLight } target="_blank" href="https://blog.bitmarketperu.com/como-usar-bitmarketperu/" alt="Como usar Bitmarketperu?" rel="noreferrer">tutorial</a>.</p>
            </div>
        </div>
        {/* <div className={darkMode ? styles.arrow : styles.arrowLight}><i className="bi bi-arrow-down"></i></div> */}
        <div className={styles.containerMap}>
            <div  className={darkMode ? styles.card : styles.cardLight}>
                <div className={styles.cardNum}>4</div>
                <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                        <LazyLoadImage 
                                style={{
                                    /* From https://css.glass */
                                    width: "60px",
                                    background: "rgba(255, 255, 255, 0.15)",
                                    borderRadius: "16px",
                                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                                    backdropFilter: "blur(8.3px)",
                                    border: "1px solid rgba(255, 255, 255, 0.27)",
                                    marginLeft: "10px",
                                    padding: "5px",
                                    marginTop: "5px"

                                }}
                                src={esperar} alt="bitmarketperu.com" title="bitmarketperu.com" 
                                effect="blur"
                                width="60px"
                                height="60px"
                            />
                        <h2>Esperar unos minutos que la transaccion se complete</h2>
                    </div>
                </div>
                <p className={styles.cartContent}>Al enviar la transacción ya solo queda esperar unos minutos que verifiquen y se procese el envio a su cuenta correspondiente. para más información consulte nuestro <a  className={darkMode ? styles.paragraph : styles.paragraphLight } target="_blank" href="https://blog.bitmarketperu.com/como-usar-bitmarketperu/" alt="Como usar Bitmarketperu?" rel="noreferrer">tutorial</a>.</p>
            </div>
            <div  className={styles.call}>
                <Call />
            </div>
        </div>
    </>);
}
 
export default Header;