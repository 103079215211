import { useContext, useState } from "react"
import { HashRouter } from "react-router-dom"
import Router from "./router"
import Nav from "./components/nav/nav"
import ButtonGame from "./components/buttonGame/ButtonGame.jsx"
import Memory from './components/modalMemory/Modal'
import { DataContext } from "./context/DataContext"
import {Helmet} from "react-helmet"
import { AnimatePresence } from 'framer-motion/dist/framer-motion'

function App() {

  const {darkMode} = useContext(DataContext)

  const [modal, setModal] = useState(false)

  return (
    <HashRouter>
      <Helmet>‍
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href="./logoAzul.ico" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="application-name" content="bitmarketperu.com"/>
        <meta name="description" content="Somos la plataforma más facil para intercambio de cryptomonedas por dinero en efectivo en Perú" />
        <meta name="author" content="Fernando Luna" />
        <meta name="keywords" content="cryptomonedas, cryptomonedas perú, como intercambiar cryptomonedas en perú, comprar bitcoins, como cambiar bitcoins, vender bitcoins, usdt, bitmarketperu.com" />
        <meta data-n-head="true" content="cryptomonedas, cryptomonedas perú, como intercambiar cryptomonedas en perú, comprar bitcoins, como cambiar bitcoins, vender bitcoins, usdt, bitmarketperu.com" name="keywords" data-hid="keywords" /> 
        <meta name="robots" content="Index, Follow" /> 
        <meta name="distribution" content="global" /> 
        <meta property="og:type" content="website" /> 
        <meta property="og:url" content="https://bitmarketperu.com/" /> 
        <meta property="og:site_name" content="bitmarketperu.com" /> 
        <meta property="og:description" content="Somos la plataforma más facil para intercambio de cryptomonedas por dinero en efectivo en Perú." /> 
        <link rel='shortlink' href='https://bitmarketperu.com/' /> 
        <link rel="canonical" href="https://bitmarketperu.com/" /> 
        <meta property="og:locale" content="es_ES" /> 
        <meta property="og:title" content="Intercambio de Cryptomonedas | bimarketperu.com" />
        <title>Intercambio de Cryptomonedas | bimarketperu.com</title>
        <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
      </Helmet>
        <AnimatePresence>
          {modal && <Memory setModal={setModal} />}
        </AnimatePresence>
        {!modal && <div className={darkMode ? "bgDarkFondo" : "bgLightFondo"}>
          {/* <div className="fondo"> */}
            <Nav />
            <Router setModal={setModal} />
            <div className="memory d-none d-md-none d-lg-block" >
              <ButtonGame setModal={setModal} />
            </div>
          </div>
        }
      
    </HashRouter >
  )
}
export default App;
