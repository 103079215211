import { useContext, useState } from 'react';
import styles from '../../css/login/login.module.css';
import picture from '../../img/login.svg';
import AlertModal from '../../components/modals/alertModal';
import { DataContext } from '../../context/DataContext';
import NavMovil from '../../components/NavMovil/NavMovil';
import Load from './Load';
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import { isValidEmail, isValidPassword } from '../../services/validate';

const Login = () => {

  const { darkMode, alertModal, setAlertModal,
    isLoggedIn, load, 
    formData, setFormData, alert, setAlert,emailLogin
  } = useContext(DataContext)

  const [ passEye, setPassEye ] = useState(false); 

  const handleInputChange = ({ target }) => {
    const { name, value } = target
    setFormData(prevData => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!isValidEmail(formData.email)) {
      setAlert({ type: 'danger', message: 'Ingrese una dirección de correo válida.' })
      return
    }

    if (!isValidPassword(formData.password)) {
      setAlert({ type: 'danger', message: 'La contraseña debe tener al menos una letra mayúscula, un número y no menos de 6 caracteres.' })
      return
    }

    // iniciar sesion
    emailLogin()

  }

  const handleCloseAlert = () => {
    setAlert({
      type: '',
      message: ''
    });
  };

  

  if (isLoggedIn) { return <Redirect to="/dashboard" /> }

  return (<>
    {alertModal.status && <AlertModal setAlertModal={setAlertModal} alertModal={alertModal} />}
    {load && (<Load />)}
    <NavMovil />
    <div className={darkMode ? styles.title : styles.titleLight}>
      <h2>Iniciar Sesión</h2>
    </div>

    <div className={styles.container}>
      <div className={styles.image}>
        <img src={picture} alt="Imagen" />
      </div>
      <div className={styles.form}>
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <div className={styles.formGroup}>
            <label htmlFor="email">Correo:</label>
            <input
              type="text"
              id="email"
              name="email"
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="password">Contraseña:</label>
            <div className="input-group mb-3">
              <input type={ !passEye ? "password" : "text" } id="password" className="formPassword form-control " name="password" onChange={handleInputChange} />
              <div className="input-group-prepend">
                <span className="input-group-text input-eye"><i onClick={() => setPassEye(!passEye)} className={ passEye ? 'bi-eye' : 'bi-eye-slash' }></i></span>
              </div>
            </div>
          </div>
          {alert.message && (
            <div className={`alert alert-${alert.type} alert-dismissible`} role="alert">
              {alert.message}
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleCloseAlert}>
              </button>
            </div>
          )}
          <button className={styles.btn} type="submit">INGRESAR</button>
          <p className={darkMode ? styles.register : styles.registerLight}>
            <Link className={darkMode ? styles.linkRegister : styles.linkRegisterLight} to="./forgot"> ¿Olvidaste tu contraseña?</Link>
          </p>
        </form>
        <p className={darkMode ? styles.register : styles.registerLight}>
          ¿Eres nuevo usuario?
          <Link className={darkMode ? styles.linkRegister : styles.linkRegisterLight} to="./register"> REGÍSTRATE AQUI</Link>
        </p>
      </div>
    </div>
  </>);
};

export default Login;
