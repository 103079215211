import Metamask from './internal3/Metamask'
import BiancePay from './internal3/BinancePay'
import ExternalWallet from './internal3/ExternalWallet'
import Efective from './internal3/efective'
import { Redirect } from "react-router-dom/cjs/react-router-dom.min"
import Transfer from './internal3/transfer'
const Transaccion3 = ({ isLogin }) => {
    if (isLogin === false) {
        return <Redirect to='/' />
    }
    return (<>
        <Metamask />
        <BiancePay />
        <ExternalWallet />
        <Transfer />
        <Efective />
    </>)
}
export default Transaccion3