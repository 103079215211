const MenuRendererMobile = ({ valueMenu, setMenu, menuConfig, darkMode }) => {

    const menu = menuConfig.map(({ id, name }) => {

        let styleData = { 
            background: valueMenu === id && "rgba(0, 0, 0, 0.3)",
            color: valueMenu === id && "aliceblue" 
        }

        return (
            <div key={id} className={darkMode ? 'adminMenuMobile' : "adminMenuMobileLight"} style={styleData} >
                <div onClick={() => setMenu(id)}>
                    {name}
                </div>
            </div>
        )
    })

    return menu
}
export default MenuRendererMobile