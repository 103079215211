const MenuRenderer = ({ valueMenu, setMenu, menuConfig, darkMode, setConfirm }) => {
    const menu = menuConfig.map(({ id, icon, name }) => {

        let styleData = {
            background: valueMenu === id && "rgba(0, 0, 0, 0.3)",
            color: valueMenu === id && "aliceblue",
            borderRadius: "8px"
        }

        const handleClick = () => {
            if(id === 4){
                setConfirm(true)
            }else{
                setMenu(id)
            }
        }

        return(
            <li 
                key={id} 
                className={ darkMode ? 'adminMenu' : "adminMenuLight" }
                style={styleData}
            >
                <div onClick={handleClick}>
                    <i className={icon} /> {name}
                </div>
            </li>
        )
        
    })

    return menu
}
export default MenuRenderer