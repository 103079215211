import { useContext, useState } from 'react';
import styles from '../../css/login/login.module.css';
import picture from '../../img/forgot.svg';
import AlertModal from '../../components/modals/alertModal';
import { DataContext } from '../../context/DataContext';
import NavMovil from '../../components/NavMovil/NavMovil';
import { apiUrl } from "../../services/apiService";
import req from '../../services/req';
import Loader from '../../components/loader/Loader';
const RememberPass = () => {

  const { darkMode, alertModal, setAlertModal, setLoading,loading } = useContext(DataContext);

  const [alert, setAlert] = useState({ type: '', message: '' })

  const isValidEmail = email => {
    // Expresión regular para validar correo electrónico
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  const handleSubmit = async e => {
    e.preventDefault()
    
    const email = e.target.email.value
    if (!isValidEmail(email)) {
      
      setAlert({ type: 'danger', message: 'Ingrese una dirección de correo válida.' });
      return
    }
    setLoading(true)
    const body = { email }
    const response = await req.post(apiUrl + 'login/rememberpass/', body)
    if (response.status) {
      setAlertModal({ status: true, text: response.message, type: "success", link: false })
    } else {
      setAlertModal({ status: true, text: response.message, type: "warning", link: false })
    }
    setLoading(false)
  }

  const handleCloseAlert = () => {
    setAlert({
      type: '',
      message: ''
    });
  };


  return (<>
    {loading && <Loader />}
    {alertModal.status && <AlertModal setAlertModal={setAlertModal} alertModal={alertModal} />}
    <NavMovil />
    <div className={darkMode ? styles.title : styles.titleLight}>
      <h2>Recuperar contraseña</h2>
    </div>

    <div className={styles.container}>
      <div className={styles.image}>
        <img src={picture} alt="Imagen" />
      </div>
      <div className={styles.form}>
        <p>¿Olvidaste tu Contraseña? <br />Recupérala ingresando tu Correo Electrónico.</p>
        <form onSubmit={e => handleSubmit(e)} className={styles.formContainer}>
          <div className={styles.formGroup}>
            <label htmlFor="email">Correo:</label>
            <input type="email" id="email" name="email" required />
          </div>
          {alert.message && (
            <div className={`alert alert-${alert.type} alert-dismissible`} role="alert">
              {alert.message}
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => handleCloseAlert()}>
              </button>
            </div>
          )}
          <button className={styles.btn} type="submit">Obtener contraseña</button>
        </form>
      </div>
    </div>
  </>);
};

export default RememberPass;
