import { useContext, useState, useEffect } from "react"
import { DataContext } from "../../context/DataContext"
import Transaccion1 from "../../components/transactions/transaction1"
import Transaccion2 from "../../components/transactions/transaction2"
import Transaccion3 from "../../components/transactions/transaction3"
import Time from "../../components/time/Time"
import Loader from "../../components/loader/Loader"
import AlertModal from "../../components/modals/alertModal"
import CoinList from '../../services/coinList'
import { apiUrl } from '../../services/apiService'
import settings from '../../services/settings'
import { Redirect } from "react-router-dom/cjs/react-router-dom.min"
const Transaction = () => {

    const ctx = useContext(DataContext)

    // useEffect(() => {
    //     // const token = localStorage.getItem("loginAuth")
    //     // if (token === null) {
    //     if (ctx.isLoggedIn === false) {
    //         return <Redirect to='/' />
    //     }
    // }, [ctx.isLoggedIn])
   
    

    const [clock, setClock] = useState({ hour: 0, minutes: 0, secounds: 59 })


    let interval = null;
    // const [ money, setMoney ] = useState(({name: ctx.selectedCoin1.name, symbol: ctx.selectedCoin1.symbol}))
    // const [ money2, setMoney2 ] = useState(({name: ctx.selectedCoin2.name, symbol: ctx.selectedCoin2.symbol}))
    // const dataStyle = {
    //     background: !ctx.darkMode
    //         && 'linear-gradient(311deg, rgba(118,197,255,1) 0%, rgba(208,235,255,1) 50%, rgba(175,221,255,1) 100%)',
    // }

    const setPricesSoles = async (btc) => {
        try {

            let Proxy = await (await fetch(apiUrl + "proxy")).json()
            let resProxy = Proxy.response || [{ com_divisa: 0 }, { ven_divisa: 0 }]
            let precioCompra = 1 / Number(resProxy[0].com_divisa)
            let precioVenta = 1 / Number(resProxy[0].ven_divisa)
            ctx.setUsdtPrice({
                compra: resProxy[0].com_divisa,
                venta: resProxy[0].ven_divisa
            })
            setPrices(precioCompra, precioVenta, btc)
        } catch (error) {
            // //console.log(error)
            setPrices(0, 0, 0)
        }
    }

    const setPrices = (compra, venta, btc) => {
        // //console.log(compra, venta, btc)
        let _coinList = []
        CoinList.map((item) => {
            if (item.symbol == "SOL") {
                item.compra = compra
                item.venta = venta
                _coinList.push(item)
            }
            if (item.symbol == "USD") {
                item.compra = 1
                item.venta = 1
                _coinList.push(item)

            }
            if (item.symbol == "BTC") {
                item.compra = btc
                item.venta = btc
                _coinList.push(item)
            }
            if (item.symbol == "USDT") {
                item.compra = 1
                item.venta = 1
                _coinList.push(item)
            }
        })
        // //console.log("coin: ", _coinList[0], _coinList[1])

        // ctx.setCoinList(_coinList)
        // ctx.setSelectedCoin1(_coinList[0])
        // ctx.setSelectedCoin2(_coinList[1])

        // /* //console.log(_coinList) */

    }

    const resetCalc = async () => {
        // ctx.calc(ctx.input1, false)
        //console.log(ctx.input1, ctx.input2, ctx.comision, ctx.selectedCoin1.name)
        let btc = await ctx.getPricesOfCryptoInBinance()
        await setPricesSoles(btc)
        await calc(ctx.input1, 1)
    }

    const calc = (value, index) => {
        index ? down(value) : up(value)
    }

    const down = (value) => {
        let comision = calcComision()
        ctx.setComision(comision)
        ctx.setInput1(value)
        let subtotal = value * ctx.selectedCoin1.compra / ctx.selectedCoin2.compra
        let total = subtotal - (subtotal * comision / 100)
        ctx.selectedCoin2.symbol == "BTC" ? total = total.toFixed(6) : total = total.toFixed(2)
        if (isNaN(total)) total = 0;
        ctx.setInput2(Number(total))
        validateError()
    }

    const up = (value) => {
        let comision = calcComision()
        ctx.setInput2(value)
        let subtotal = value / ctx.selectedCoin1.compra * ctx.selectedCoin2.compra
        let total = subtotal + (subtotal * comision / 100)
        ctx.selectedCoin1.symbol == "BTC" ? total = total.toFixed(6) : total = total.toFixed(2)
        if (isNaN(total)) total = 0;
        ctx.setInput1(Number(total))
        validateError()

    }

    const calcComision = () => {
        if (ctx.selectedCoin1.symbol === "USDT" && ctx.selectedCoin2.symbol === "SOL") {
            return ctx.config.solOut
        }
        if (ctx.selectedCoin1.symbol === "SOL" && ctx.selectedCoin2.symbol === "USDT") {
            return ctx.config.solInp
        }
        if (ctx.selectedCoin1.symbol === "SOL" && ctx.selectedCoin2.symbol === "USD" || ctx.selectedCoin1.symbol === "USD" && ctx.selectedCoin2.symbol === "SOL") {
            return 0
        }
        if (ctx.selectedCoin1.symbol === "USD" && ctx.selectedCoin2.symbol === "USDT") {
            return ctx.config.dolInp
        }
        if (ctx.selectedCoin1.symbol === "USDT" && ctx.selectedCoin2.symbol === "USD") {
            return ctx.config.dolOut
        }
        if (ctx.selectedCoin1.symbol === "BTC") {
            return ctx.config.buyBit
        }
        if (ctx.selectedCoin2.symbol === "BTC") {
            return ctx.config.sellBit
        }
    }

    const validateError = () => {
        if (!ctx.wallet) {
            ctx.setError("Debe conectar su Metamask")
            ctx.setBtnStatus(false)
            return
        }
        else {
            if (!ctx.kyc) {
                ctx.setError("Debe verificar su identidad (KYC)")
                ctx.setBtnStatus(false)
                return
            }
        }
        if (ctx.selectedCoin1.symbol === "USD" && ctx.selectedCoin2.symbol === "SOL") {
            ctx.setError("No se realizan cambios de Dólares a Soles")
            ctx.setBtnStatus(false)
        } else if (ctx.selectedCoin1.symbol === "SOL" && ctx.selectedCoin2.symbol === "USD") {
            ctx.setError("No se realizan cambios de Soles a Dólares")
            ctx.setBtnStatus(false)
        } else if (ctx.selectedCoin1.symbol === "USDT" && ctx.selectedCoin2.symbol === "BTC") {
            ctx.setError("No se realizan cambios de USDT a BTC")
            ctx.setBtnStatus(false)
        } else if (ctx.selectedCoin1.symbol === "BTC" && ctx.selectedCoin2.symbol === "USDT") {
            ctx.setError("No se realizan cambios de BTC a USDT")
            ctx.setBtnStatus(false)
        } else {
            if (ctx.selectedCoin1.symbol === "USDT" && ctx.input1 < settings.minUsdt) {
                ctx.setError(`Monto minimo de ${settings.minUsdt} USDT`)
                ctx.setBtnStatus(false)
            } else if (ctx.selectedCoin1.symbol === "USD" && ctx.input1 < settings.minUsd) {
                ctx.setError(`Monto minimo de ${settings.minUsd} USD`)
                ctx.setBtnStatus(false)
            } else if (ctx.selectedCoin1.symbol === "BTC" && ctx.input1 < settings.minBtc) {
                ctx.setError(`Monto minimo de ${settings.minBtc} BTC`)
                ctx.setBtnStatus(false)
            } else if (ctx.selectedCoin1.symbol === "SOL" && ctx.input1 < settings.minSoles) {
                ctx.setError(`Monto minimo de ${settings.minSoles} Soles`)
                ctx.setBtnStatus(false)
            } else {
                ctx.setError(false)
                ctx.setBtnStatus(true)
            }
        }

        if (Number(ctx.input2) === 0) {
            ctx.setBtnStatus(false)
            return;
        }

        //monto maximo de intercambio en usd
        if (ctx.selectedCoin1.symbol === "USD" && ctx.input1 > settings.maxUsd) {
            ctx.setError(`Monto maximo es ${settings.maxUsd} USD`)
            ctx.setBtnStatus(false)
            return;
        }

        //monto maximo de intercambio en soles
        if (ctx.selectedCoin1.symbol === "SOL" && ctx.input1 > settings.maxSoles) {
            ctx.setError(`Monto maximo es ${settings.maxSoles} SOLES`)
            ctx.setBtnStatus(false)
            return;
        }

        //monto maximo de intercambio en usd
        if (ctx.selectedCoin2.symbol === "USD" && ctx.input2 > settings.maxUsd) {
            ctx.setError(`Monto maximo es ${settings.maxUsd} USD`)
            ctx.setBtnStatus(false)
            return;
        }

        //monto maximo de intercambio en soles
        if (ctx.selectedCoin2.symbol === "SOL" && ctx.input2 > settings.maxSoles) {
            ctx.setError(`Monto maximo es ${settings.maxSoles} SOLES`)
            ctx.setBtnStatus(false)
            return;
        }
    }


    const handleTime = () => {
        let newSeconds = clock.secounds + 1;
        let newMinutes = clock.minutes;

        interval = setInterval(async function () {
            //console.log('ok')
            newSeconds--;

            if (newSeconds === -1 && newMinutes > 0) {
                newMinutes--;
                setClock({ ...clock, minutes: newMinutes, secounds: clock.secounds });
                newSeconds = clock.secounds;
            } else if (newSeconds === -1 && newMinutes === 0) {

                ctx.setStatusTime(true);
                newSeconds = clock.secounds;
                newMinutes = clock.minutes;
                setClock({ ...clock, minutes: newMinutes, secounds: newSeconds })
                closeInterval();
                await resetCalc();

                handleTime()

            } else {
                setClock({ ...clock, minutes: newMinutes, secounds: newSeconds })

            }

        }, 1000);
    }

    function closeInterval() {
        clearInterval(interval);
    }

    useEffect(() => {

        if (ctx.timeClock) {
            clearInterval(ctx.timeClock)
            ctx.timeClock = null;
        } else {
            clearInterval(ctx.timeClock)
            ctx.timeClock = null;
            handleTime()
        }
        return (() => closeInterval())
        // if(!ctx.timeClock){
        // handleTime()
        // }
    }, [])


    if(!ctx.userDataToken){
        return <Redirect to='/' />
    }


   

    return (<div className="transactionBody" /*style={dataStyle}*/>
        <Loader />
        <AlertModal />
        <div className="transactionContainer">
            <div className="transactionIn">
                <div className="steapSuccess">
                    <div> <i className="bi bi-check-circle"></i> </div>
                    <div>Método de envio</div>
                </div>

                <div className={ctx.transactionSteap > 1 ? "hrSuccess" : "hrNoSuccess"} />

                <div className={ctx.transactionSteap > 1 ? "steapSuccess" : "steapNoSuccess"} >
                    <div> <i className="bi bi-check-circle"></i> </div>
                    <div>Cómo recibe</div>
                </div>

                <div className={ctx.transactionSteap > 2 ? "hrSuccess" : "hrNoSuccess"} />

                <div className={ctx.transactionSteap > 2 ? "steapSuccess" : "steapNoSuccess"}>
                    <div> <i className="bi bi-check-circle"></i> </div>
                    <div>Finalizar Operación</div>
                </div>
            </div>
        </div>
        <div className="transactionContainer mt-4">
            <div style={{ width: '100%', maxWidth: '450px' }}>
                <div className="transactionHeader">
                    <button onClick={ctx.backSteap} className="backButton"> <i className="bi bi-arrow-left-square vol"></i> <div> Volver</div> </button>
                    <div className="title-transaction">
                        Usted está cambiando <b className="bChange">{ctx.input1} {ctx.selectedCoin1.name} ({ctx.selectedCoin1.symbol})</b> por <b className="bChange"> {ctx.input2} {ctx.selectedCoin2.name} ({ctx.selectedCoin2.symbol})</b>
                        <span style={{ color: "#f5da0c" }}>
                            (<Time hour={clock.hour} minutes={clock.minutes} secounds={clock.secounds} to="des" resetCalc={resetCalc} />)
                        </span>
                    </div>
                </div>
                <div className="transactionDisplay">
                    {ctx.transactionSteap === 1 && <Transaccion1 isLogin={ctx.isLoggedIn} />}
                    {ctx.transactionSteap === 2 && <Transaccion2 isLogin={ctx.isLoggedIn} />}
                    {ctx.transactionSteap === 3 && <Transaccion3 isLogin={ctx.isLoggedIn} />}
                </div>
            </div>
        </div>
    </div>)
}
export default Transaction