import React, { createContext, useEffect, useState } from 'react'
import CoinList from '../services/coinList'
import { useWeb3 } from '../hooks/useWeb3'
import { getStorage, setStorage } from '../services/localStorage'
import { apiUrl } from '../services/apiService'
import Web3 from 'web3'
import req from '../services/req'
import selectorDeRed from '../services/selectorDeRed'
import validateReq from '../services/errorManager/validateError'
import errorManager from '../services/errorManager/errorManager'
import chains from '../services/chainList'
import settings from '../services/settings'
import getTransactionConfig from "../services/getTransactionConfig"
import useLogin from '../hooks/useLogin'
import useValidateErrorSwap from '../hooks/useValidateErrorSwap'
import useGetUserTokenInLocalStorage from '../hooks/useGetUserTokenInLocal.Storage'
import { jwtDecode } from 'jwt-decode'

const web3 = new Web3("https://mainnet.infura.io/v3/")

export const DataContext = createContext()
export const DataProvider = ({ children }) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [alert, setAlert] = useState({
        type: '',
        message: ''
    });

    //const networks = [1,56,137]
    const { getWallet, getChainId } = useWeb3()
    const nullId = "000000000000000000000000"
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingText, setLoadingText] = useState(false)
    const [validForm, setValid] = useState(false)

    const [userName, setUserName] = useState(false)
    const [phone, setPhone] = useState(false)
    const [email, setEmail] = useState(false)
    const [dni, setDni] = useState(false)
    const [wallet, setWallet] = useState(false)
    const [userLevel, setUserLevel] = useState(0)
    const [kyc, setKyc] = useState(false)

    /*const [userData,setUserData] = useState(false) */
    const [config, setConfig] = useState(false)
    const [darkMode, setDarkMode] = useState(true)
    const [comision, setComision] = useState(0)


    const [addBanks2, setAddBanks2] = useState(false)


    const [titular, setTitular] = useState(false)
    const [banksAdmin, setBanksAdmin] = useState([])
    const [redesModal, setRedesModal] = useState(false)
    const [chain, setChain] = useState(1)
    const [usdt, setUsdt] = useState(false)
    const [btcPrice, setBtcPrice] = useState(false)
    const [porcentajeCompraBTC, setPorcentageCompraBTC] = useState(false)
    const [porcentajeVentaBTC, setPorcentageVentaBTC] = useState(false)
    const [alertModal, setAlertModal] = useState({ status: false, text: '', type: "success", link: false, redirect: false })
    const [usdPrice, setUsdtPrice] = useState(false)
    const [modalCrypto, setModalCrypto] = useState(false)
    const [coinList, setCoinList] = useState(false)
    const [steap, setSteap] = useState(1)
    const [red, setRed] = useState(false)

    const [userBanks, setUserBanks] = useState([])

    const getChainText = (chainId) => chainId == '0x1' ? "ETH" : chainId == '0x38' ? "BSC" : "MATIC"

    /* Estado del swap  */
    const [_coinsModal, _setCoinsModal] = useState(false)
    const [selectedCoin1, setSelectedCoin1] = useState(false)
    const [selectedCoin2, setSelectedCoin2] = useState(false)
    const [direction, setDirection] = useState(false)
    const [input1, setInput1] = useState(100)
    const [input2, setInput2] = useState(0)
    const [btnStatus, setBtnStatus] = useState(false)
    const [error, setError] = useState(false)
    const [selectBank, setSelectBank] = useState(false)

    const [transactionSteap, setTransactionSteap] = useState(1)
    const [sendMethod, setSendMethod] = useState(false)
    const [reciveMethod, setReciveMethod] = useState(false)
    const [reciveBank, setReciveBank] = useState(nullId)
    const [reciveBankId, setReciveBankId] = useState(nullId)
    const [adminBank, setAdminBank] = useState(nullId)
    const [confirmRecive, setConfirmRecive] = useState(false)
    const [confirmInfo, setConfirmInfo] = useState(false)
    const [selectedChain, setSelectedChain] = useState(1)
    const [transactionId, setTransactionId] = useState("")
    const [sendRed, setSendRed] = useState(false)
    const [bankList, setBankList] = useState(false)
    const [selectAdminBank, setSelectAdminBank] = useState(false)
    const [reciveUser, setReciveUser] = useState(false)
    const [reciveNetwork, setReciveNetwork] = useState("n/a")
    const [dataSbs, setDataSbs] = useState({ status: false, origen: "", politico: "" })
    const [statusTime, setStatusTime] = useState(false)

    const [number, setNumber] = useState(false)
    const [addBanks, setAddBanks] = useState(false)
    const [name, setName] = useState(1)
    const [money, setMoney] = useState(1)
    const [type, setType] = useState(1)

    const getUserBanks = async (dni) => {
        setLoading(true)
        const token = localStorage.getItem('loginAuth')
        const decodedToken = jwtDecode(token)
        if (!dni) dni = decodedToken.dni

        const res = await fetch(apiUrl + 'bank/' + dni, {
            headers: {
                'Container-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const userBanks = await res.json()
        //console.log("UserBanks: ", userBanks.response)
        setUserBanks(userBanks.response)
        setLoading(false)
    }

    // useEffect(() => {
    //     const token = localStorage.getItem('loginAuth')
    //     if (token) getUserBanks()
    // }, [])



    const deleteBank = async (_id, dni) => {
        setLoading(true)
        setLoadingText("Eliminando Banco")
        let token = localStorage.getItem('loginAuth') || null

        await fetch(apiUrl + "bank/" + dni + "/" + _id, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })

        await getUserBanks(dni)
        setLoading(false)
        setLoadingText(false)
    }

    const sendForm = async (e) => {
        e.preventDefault()
        setLoading(true)
        const token = localStorage.getItem('loginAuth')
        const user = jwtDecode(token)

        setLoadingText("Enviando Datos del Banco")

        if (name === 1) {
            setAlertModal({ status: true, text: 'Debe elegir un banco', type: "warning", link: false })
            setLoading(false)
            return
        }
        if (money === 1) {
            setAlertModal({ status: true, text: 'Debe elegir un tipo de moneda', type: "warning", link: false })
            setLoading(false)
            return
        }
        if (type === 1) {
            setAlertModal({ status: true, text: 'Debe elegir un tipo de cuenta', type: "warning", link: false })
            setLoading(false)
            return
        }

        setAddBanks(false)

        let body = {
            name,
            dni: user.dni,
            titular,
            number,
            type,
            money
        }

        if (!token) {
            setAlertModal({ status: true, text: "token invalido, por favor recargue la pagina", type: "warning", link: false })
            setLoading(false)
            setLoadingText(false)
            setName(1)
            setMoney(1)
            setType(1)
            return
        }

        const res = await fetch(apiUrl + "bank", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(body)

        })

        if (res.error) {
            setLoading(false)
            setLoadingText(false)
            setAlertModal({ status: true, text: res.msg, type: "warning", link: false })
            return
        }

        await getUserBanks(user.dni)
        setLoading(false)
        setLoadingText(false)
        setName(1)
        setMoney(1)
        setType(1)
    }





    var timeClock = null



    /*  const { getBanks, getUserBanks, deleteBank, addBanks, setAddBanks, sendForm,
         setName, setMoney, setType, name, money, type, number, setNumber }
         = useBanks(apiUrl, setLoading, setLoadingText, setAlertModal, dni, titular) */

    const getSwapData = async (config) => {
        //obtener el precio de btc y setearlo en el estado
        setLoading(true)
        setLoadingText(`Obteniendo precio del bitcoin`)
        let btc = await getPricesOfCryptoInBinance()
        await setPricesSoles(btc)

        if (config) {
            setConfig(config)
            setComision(config.solOut)
            setPorcentageCompraBTC(config.buyBit)
            setPorcentageVentaBTC(config.sellBit)
            setLoading(false)
            setLoadingText(false)
        } else {
            const data = await (await fetch(apiUrl + "config")).json()
            /* //console.log(data.response) */
            setConfig(data.response)
            setComision(data.response.solOut || 0)
            /*  //console.log("buybit: " + data.response.buyBit) */
            setPorcentageCompraBTC(data.response.buyBit)
            setPorcentageVentaBTC(data.response.sellBit)
            setLoading(false)
            setLoadingText(false)
        }
    }



    const { emailLogin, formData, setFormData, user, setUser } =
        useLogin(setDni, setLoad, setIsLoggedIn, setAlertModal, setKyc, getSwapData, config, setConfig, getUserBanks, setBanksAdmin)

    useEffect(() => {
        const token = localStorage.getItem('loginAuth')
        if (token) {
            const decodedToken = jwtDecode(token)
            setUser(decodedToken)
            const dniDecoded = decodedToken.dni
            setDni(dniDecoded)
        }
    }, [])

    const { userDataToken, setUserDataToken } = useGetUserTokenInLocalStorage()



    useEffect(() => {
        validateError()
    }, [input1, input2, kyc, userDataToken])

    const { validateError } = useValidateErrorSwap(kyc, setError, setBtnStatus, userDataToken, input2, input1, selectedCoin1, selectedCoin2)

    useEffect(() => {
        if (!statusTime) {
            calc(input1, true)
        }

    }, [selectedCoin1, selectedCoin2, coinList])

    const firstConnect = async () => {
        setLoading(true)
        setLoadingText("Cargando Datos")
        setLoadingText("Conectando con metamask")
        getDarkModeLocalStorage()

        if (window.ethereum) {
            setLoading(true)
            /* if (!window.ethereum._state.isUnlocked) setAlertModal({ status: true, text: 'Por favor conecte su billetera Metamask', type: "warning", link: false }) */
            setLoadingText("Obteniendo identificador de cadena")
            const id = await getChainId()
             //console.log("Sected red: " + id)
            if (id === "0x38" || id === "0x1" || id === "0x89") {
                window.ethereum.request({ "method": "eth_requestAccounts" })
                    .then(async (res) => {
                        let wallet = res[0]
                        //colocar el id de cadena
                        setLoadingText("Obteniendo balance de USDT")
                        await setUsdtBalance(wallet, id)
                        getSwapData()
                        setChain(id)
                        //console.log("314;Datacontext, chain id: ",id)
                        setWallet(wallet)
                        setLoadingText("Obteniendo datos del usuario")
                        //conectar a la base de datos
                        /* await connect(wallet) */

                        /* //console.log(auth) */
                        //agrego datos para el swap

                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        getSwapData(false)
                        setLoading(false)
                    })
            } else {
                setRedesModal(true)
                alert("Red incorrecta")
                setLoading(false)
            }


        } else {
            getSwapData(false)
            setAlertModal({ status: true, text: 'Por favor instale Metamask', type: "warnign", link: "https://blog.bitmarketperu.com/que-es-metamask/" })
        }
    }

    /* const connect = async (wallet) => {

        /* if (!isLoggedIn) return */

    /*   let _wallet
      if (wallet) {
          _wallet = wallet
      } else {
          _wallet = await getWallet()
      } */

    /*  const body = { wallet: _wallet }
     const auth = await req.post(apiUrl + "auth", body) */
    /*   //console.log("auth: ", auth.response) */

    /* //console.log(">>>>>>>>>>>>>>>>>>")
    //console.log(jwtDecode(auth.response.token)) */

    /*  let storage = await getStorage('loginAuth') || false
     if (!storage && auth.response?.token) {
         setStorage('loginAuth', auth.response.token)
         storage = auth.response.token;
     } 

     //verificando token
     let verify = false;
     verify = await (await fetch(apiUrl + "auth/verify/" + storage)).json() || false
     if (verify?.error) setStorage('loginAuth', auth.response.token)

     if (auth.response?.banksAdmin) setBanksAdmin(auth.response.banksAdmin)
     if (auth.response?.level) setUserLevel(auth.response.level)

     setUser(auth.response)
     let user = auth.response
     /*  //console.log(user.name, 'username') 
     setUserName(user.name)
     setPhone(user.phone)
     setDni(user.dni)
     setEmail(user.email)

     let config = []
     if (auth.response?.config[0]) {
         config = auth.response.config[0]
     }
     setConfig(config)
     /* //console.log(auth.response.kyc) 
     if (auth.response?.kyc) setKyc(auth.response.kyc)
     setComision(auth.response.config[0].solOut)
     setPorcentageCompraBTC(auth.response.config[0].buyBit)
     setPorcentageVentaBTC(auth.response.config[0].sellBit)

     getBanks(dni)
     //setUserBanks(auth.response.banksUser)
     return auth
 } */

    const getAdminBanks = () => {
        const token = localStorage.getItem('loginAuth')
        const decodedToken = jwtDecode(token)
        return decodedToken.banksAdmin
    }

    const setUsdtBalance = async (wallet, id) => {
        const _id = web3.utils.hexToNumber(id)
        /* const decimals = await selectorDeRed(_id).usdtContract.methods.decimals().call() */
        const usdtBalance = await selectorDeRed(_id).usdtContract.methods.balanceOf(wallet).call()
        let wei
        _id == 56 ? wei = "ether" : wei = "mwei"
        setUsdt(Number(web3.utils.fromWei(usdtBalance, wei)).toFixed(2))
    }



    const getPricesOfCryptoInBinance = async () => {
        try {
            let res = await fetch("https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT")
            let btc = await res.json()
            /* let btc = { price: 20000 } */
            setBtcPrice(btc.price || false)
            return btc.price
        } catch (error) {
            setBtcPrice(0)
            errorManager(error)
            //console.log("Error en la api de binance - [getPricesOfCryptoInBinance]")
        }
    }

    const resume = (value, decimals) => {
        if (value) {
            return Number(value).toFixed(decimals)
        } else {
            return "No se pudo obtener el precio"
        }
    }

    const setPrices = (compra, venta, btc) => {

        let _coinList = []
        CoinList.map((item) => {
            if (item.symbol == "SOL") {
                item.compra = compra
                item.venta = venta
                _coinList.push(item)
            }
            if (item.symbol == "USD") {
                item.compra = 1
                item.venta = 1
                _coinList.push(item)

            }
            if (item.symbol == "BTC") {
                item.compra = btc
                item.venta = btc
                _coinList.push(item)
            }
            if (item.symbol == "USDT") {
                item.compra = 1
                item.venta = 1
                _coinList.push(item)
            }
        })


        setCoinList(_coinList)
        setSelectedCoin1(_coinList[0])
        setSelectedCoin2(_coinList[1])


        /* //console.log(_coinList) */
    }

    const getDarkModeLocalStorage = () => {
        let local = getStorage('bitmarketperu') || false
        if (!local) {
            setStorage('bitmarketperu', 'light')
            setDarkMode(false)
        } else local === 'light' ? setDarkMode(false) : setDarkMode(true)
    }






    useEffect(() => { autoConect() }, [])

    const autoConect = async () => {
        if (window.ethereum) {
            const chain = await getChainId()
            connectChain(chain)
        }
    }

    const connectChain = async (_chainId) => {
        try {
            setRedesModal(false)
            if (window.ethereum) {
                const chainId = web3.utils.toHex(_chainId)
                setChain(_chainId)
                //console.log("495;Datacontext;Chain Id: " , chainId)
                window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId }] })
                    .then(async (res) => {
                        /* //console.log('switch: ', res) */
                        //conectar metamask
                        /*  //console.log('conectando metamask') */
                        window.ethereum.request({ method: 'eth_requestAccounts' }).then((res) => {
                            /* //console.log('wallet: ',res[0]) */
                            setWallet(res[0])
                        })
                    }).catch((error) => {
                        //console.log(error)
                        //wallet_addEthereumChain(_chainId)
                    })
            } else {
                setAlertModal({ status: true, text: 'Debe Instalar Metamask', type: "warning", link: false })
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const wallet_addEthereumChain = (chainId) => {
        setLoading(true)
        let chaindata
        chainId == 1 ? chaindata = chains.eth : chainId == 56 ? chaindata = chains.bnb : chaindata = chains.matic
        window.ethereum.request({ method: 'wallet_addEthereumChain', params: chaindata }).then(() => {
            //connect()
            setLoading(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    const setPricesSoles = async (btc) => {
        try {
            let Proxy = await (await fetch(apiUrl + "proxy")).json()
            let resProxy = Proxy.response || [{ com_divisa: 0 }, { ven_divisa: 0 }]
            let precioCompra = 1 / Number(resProxy[0].com_divisa)
            let precioVenta = 1 / Number(resProxy[0].ven_divisa)
            setUsdtPrice({
                compra: resProxy[0].com_divisa,
                venta: resProxy[0].ven_divisa
            })
            setPrices(precioCompra, precioVenta, btc)
            // //console.log("proxi res " ,Proxy)
        } catch (error) {
            setPrices(0, 0, 0)
        }
    }

    const invert = () => {
        let _1 = selectedCoin1
        let _2 = selectedCoin2
        setSelectedCoin1(_2)
        setSelectedCoin2(_1)
    }

    const sendSwapTransaction = () => {
        /*  //console.log(config) */
        /* Enviar el post de la transaccion del swap */
    }

    const calc = (value, index) => index ? down(value) : up(value)

    const down = (value) => {
        let comision = calcComision()
        setComision(comision)
        setInput1(value)
        let subtotal = value * selectedCoin1.compra / selectedCoin2.compra
        let total = subtotal - (subtotal * comision / 100)
        selectedCoin2.symbol == "BTC" ? total = total.toFixed(6) : total = total.toFixed(2)
        if (isNaN(total)) total = 0;
        setInput2(Number(total))
        validateError()
    }

    const up = (value) => {
        let comision = calcComision()
        setInput2(value)
        let subtotal = value / selectedCoin1.compra * selectedCoin2.compra
        let total = subtotal + (subtotal * comision / 100)
        selectedCoin1.symbol == "BTC" ? total = total.toFixed(6) : total = total.toFixed(2)
        if (isNaN(total)) total = 0;
        setInput1(Number(total))
        validateError()
    }

    const calcComision = () => {
        if (selectedCoin1.symbol == "USDT" && selectedCoin2.symbol == "SOL") {
            return config.solOut
        }
        if (selectedCoin1.symbol == "SOL" && selectedCoin2.symbol == "USDT") {
            return config.solInp
        }
        if (selectedCoin1.symbol == "SOL" && selectedCoin2.symbol == "USD" || selectedCoin1.symbol == "USD" && selectedCoin2.symbol == "SOL") {
            return 0
        }
        if (selectedCoin1.symbol == "USD" && selectedCoin2.symbol == "USDT") {
            return config.dolInp
        }
        if (selectedCoin1.symbol == "USDT" && selectedCoin2.symbol == "USD") {
            return config.dolOut
        }
        if (selectedCoin1.symbol == "BTC") {
            return config.buyBit
        }
        if (selectedCoin2.symbol == "BTC") {
            return config.sellBit
        }
    }



    const backSteap = () => {
        if (transactionSteap == 1) {
            /* usar el router para ir a swap */
            window.location = '/#/swap'
        }
        if (transactionSteap == 2) {
            setTransactionSteap(1)
        }
        if (transactionSteap == 3) {
            setTransactionSteap(2)
        }
    }

    const resetVariables = () => {

        setDirection(false)
        setBtnStatus(false)
        setSelectBank(false)
        setTransactionSteap(1)
        setSendMethod(false)
        setReciveMethod(false)
        setReciveBank(nullId)
        setReciveBankId(nullId)
        setAdminBank(nullId)
        setConfirmRecive(false)
        setConfirmInfo(false)
        setTransactionId("")
        setRed(false)
        setReciveUser(false)
        setDataSbs({ status: false, origen: "", politico: "" })
    }

    const sendTransaction = () => {
        setLoadingText("Enviando Datos")
        setLoading(true)
        if (sendMethod === 1) {
            sendMetamask()
            //saveTransaction()
        } else {
            saveTransaction(false)
        }
    }

    const sendMetamask = async () => {
        setLoadingText("Enviando Transaccion a Metamask")
        const chainId = await getChainId()
        const _chainId = web3.utils.hexToNumber(chainId)
        const tConfig = await getTransactionConfig(chainId, input1)
        const ownerAddress = _chainId === 1 ? settings.usdtWallets[1] : _chainId == 56 ? settings.usdtWallets[2] : settings.usdtWallets[3]

        selectorDeRed(chainId).usdtContract.methods.transfer(ownerAddress, tConfig.convertedWeis)
            .send({ from: wallet, gasPrice: tConfig.gasPrice, gas: tConfig.gas })
            .then(res => {
                saveTransaction(res.transactionHash)
            })
            .catch(error => {
                console.log(error)
            }).finally(_ => {
                setLoading(false)
                setLoadingText(false)
            })
    }

    const redName = ["", "eth", "bsc", "matic", "avax", "sol", "trx", "btc"]

    const checkAmountSbs = () => {
        if (selectedCoin1.symbol === "USD" && input1 > settings.amountSbs) {
            setDataSbs({ ...dataSbs, status: true })
        }
        if (selectedCoin1.symbol === "SOL" && (input1 / usdPrice.compra) > settings.amountSbs) {

            setDataSbs({ ...dataSbs, status: true })
        }
        if (selectedCoin2.symbol === "USD" && input2 > settings.amountSbs) {
            setDataSbs({ ...dataSbs, status: true })
        }
        if (selectedCoin2.symbol === "SOL" && (input2 / usdPrice.compra) > settings.amountSbs) {
            setDataSbs({ ...dataSbs, status: true })
        }
    }

    const saveTransaction = async (metamaskTransactionHash) => {

        const _wallet = wallet
        // typeof (wallet) == "string" ? //console.log("wallet Success string") : //console.log("wallet error not string")
        // //console.log('[data]:', Object.values(adminBank).length)
        const bankAdmin = Object.values(adminBank).length === 24 ? nullId : adminBank._id
        // typeof (bankAdmin) == "string" && bankAdmin.length > 23 ? //console.log("bankAdmin Success string > 23") : //console.log("bankAdmin error not string < 23")

        const amountSend = Number(input1)
        // typeof (amountSend) == "number" ? //console.log("amountSend Success number") : //console.log("amountSend error not number")

        const amountReceive = Number(input2)
        // typeof (amountReceive) == "number" ? //console.log("amountReceive Success number") : //console.log("amountReceive error not number")

        const bank = reciveBankId
        // typeof (bank) == "string" && bank.length > 23 ? //console.log("bank Success string > 23") : //console.log("bank error not string < 23")

        /*viene de metamask*/
        const transactionHash = sendMethod == 1 ? metamaskTransactionHash : sendMethod == 4 ? "n/a" : transactionId
        // typeof (transactionHash) == "string" ? //console.log("transactionHash Success string") : //console.log("transactionHash error not string")

        const _network = await getChainId()
        const network = getChainText(_network)
        // typeof (network) == "string" ? //console.log("network Success string ") : //console.log("network error not string")
        /*
        mtodos de pago y recibo
        1 metamask
        2 binancePay
        3 walletExterna
        4 efectivo
        5 transferencia
        */

        /* const redtoNumber = ()=>{
            let num = parseInt(sendRed)
            return settings.setUsdtBalance[num]
        } */

        const method = sendMethod == 1 ? "metamask" : sendMethod == 2 ? "binancePay" : sendMethod == 3 ? "walletExterna" : sendMethod == 4 ? "efectivo" : "transferencia"
        // typeof (method) == "string" ? //console.log("method Success string") : //console.log("method error not string")

        const red = sendRed ? sendRed : "n/a"
        // typeof (red) == "string" ? //console.log("red Success string") : //console.log("red error not string")
        /* pay es correo , wallet fiat */
        const pay = sendMethod == 1 ? wallet : sendMethod == 2 ? settings.binancePayEmail : sendMethod == 3 ? settings.usdtWallets[Number(sendRed)] : "fiat"
        // typeof (pay) == "string" ? //console.log("pay Success string") : //console.log("pay error not string")

        const _reciveMethod = reciveMethod == 1 ? "metamask" : reciveMethod == 2 ? "binancePay" : reciveMethod == 3 ? "walletExterna" : reciveMethod == 4 ? "efectivo" : "transferencia"
        // typeof (_reciveMethod) == "string" ? //console.log("reciveMethod success string") : //console.log("reciveMethod erro not string")

        const _reciveUser = reciveUser || "n/a"
        // typeof (_reciveUser) == "string" ? //console.log("reciveUser success string") : //console.log("reciveUser erro not string")

        const _reciveNetwork = reciveNetwork || "n/a"
        // //console.log("reciveNetwork: ", reciveNetwork)
        // typeof (_reciveNetwork) === "string" ? //console.log("reciveNetwork success string") : //console.log("reciveNetwork erro not string")
        const body = {
            dni: user.dni,
            userIdTransaction: user._id,
            wallet: _wallet,
            id: transactionHash,
            amountSend,
            amountReceive,
            moneySend: selectedCoin1.name,
            moneyReceive: selectedCoin2.name,
            network,
            status: 3,
            bankAdmin,
            bank,
            reciveUser: _reciveUser,
            reciveNetwork: _reciveNetwork,
            reciveMethod: _reciveMethod,
            payMethod: {
                red,
                method,
                pay
            },
            origen: dataSbs.origen,
            politico: dataSbs.politico
        }

        //console.log(user.dni)

        req.post(apiUrl + "transactions", body)
            .then(res => {
                // //console.log("res: ", res)
                // if(res.error){
                //     alert(res.msg)
                // }
                if (res.error) {
                    setAlertModal({ status: true, text: 'Error Inesperado, verifique los datos y Vuelva a Intentarlo', type: "danger", link: false })
                    // alert(res.error)
                } else {
                    setInput1("")
                    setInput2("")
                    setAlertModal({
                        status: true,
                        text: "Dirijete a Dashboard / Transacciones para comunicarte con un operador",
                        type: "success",
                        handleBTN: () => window.location = '/#/dashboard'
                    })
                    resetVariables()
                }

            }).catch(error => {
                setAlertModal({ status: true, text: 'Error Inesperado, verifique los datos y Vuelva a Intentarlo', type: "danger", link: false })
                // //console.log("error: ", error)
            })
            .finally(_ => setLoading(false))
    }

    const VerifyAuth = async () => {
        let token = getStorage('loginAuth') || null
     
        const res = await (await fetch(apiUrl + "login/verify", {
            headers: {
                'Container-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })).json()
        // console.log('decodedToken',decodedToken)
        // login success
        setIsLoggedIn(res.response)
        
        if(token && res.response){
            const decodedToken = jwtDecode(token)
            // insertar datos del usuario
            setKyc(decodedToken?.kyc)
            setDni(decodedToken?.dni)
            setUser(decodedToken)

            getUserBanks(decodedToken?.dni)

            setBanksAdmin(decodedToken?.dni?.banksAdmin)
        }
      

    }

    useEffect(() => {
        VerifyAuth()
    }, [])

    const context = {
        isLoggedIn, setIsLoggedIn,
        wallet,
        loading, setLoading,
        loadingText, setLoadingText,
        user, setUser,
        config,
        darkMode, setDarkMode,
        comision, setComision,
        coinList, kyc, red, setRed,
        usdt, setUsdt, steap, setSteap,
        deleteBank, sendForm,
        addBanks, setAddBanks,
        addBanks2, setAddBanks2,
        direction, setDirection,
        setTitular, modalCrypto, setModalCrypto,
        validForm, setValid, banksAdmin,
        alertModal, setAlertModal, nullId,
        setRedesModal, redesModal, connectChain, chain, getChainId, btcPrice, userLevel: user.level
        , validateReq, porcentajeCompraBTC, porcentajeVentaBTC, usdPrice, getChainText,
        /* variables del swap */
        _coinsModal, _setCoinsModal,
        resume, invert, sendSwapTransaction, calc,
        selectedCoin1, setSelectedCoin1,
        selectedCoin2, setSelectedCoin2,
        input1, setInput1,
        input2, setInput2,
        btnStatus, setBtnStatus,
        error, transactionSteap, setTransactionSteap,
        backSteap, sendMethod, setSendMethod,
        reciveMethod, setReciveMethod,
        reciveBank, setReciveBank,
        reciveBankId, setReciveBankId,
        confirmRecive, setConfirmRecive,
        selectBank, setSelectBank,
        confirmInfo, setConfirmInfo, sendTransaction,
        adminBank, setAdminBank,
        selectedChain, setSelectedChain,
        transactionId, setTransactionId, firstConnect,
        sendRed, setSendRed,
        bankList, setBankList,
        selectAdminBank, setSelectAdminBank,
        reciveUser, setReciveUser,
        reciveNetwork, setReciveNetwork, redName,
        userName, setUserName,
        phone, setPhone,
        email, setEmail,
        dni, setDni,
        dataSbs, setDataSbs, checkAmountSbs,
        setPricesSoles, getPricesOfCryptoInBinance,
        timeClock, setError, setStatusTime, setBtcPrice, setCoinList, setUsdtPrice,
        load, setLoad, formData, setFormData, alert, setAlert, emailLogin, validateError,
        userDataToken, setUserDataToken, setConfig, getSwapData,

        name, setName,
        money, setMoney,
        type, setType,
        number, setNumber,
        userBanks, setUserBanks, getUserBanks,
        getAdminBanks, setBanksAdmin

    }

    return (
        <DataContext.Provider value={context}>
            {children}
        </DataContext.Provider>
    )

}


/* const compareNetworks = async ()=>{
    const usedNetwork = await eth.request({method:'eth_chainId'})
    const usedNetworkNumber = web3.utils.hexToNumber(usedNetwork)
    //console.log("Used Network: "+ usedNetwork + ", in Number: " +usedNetworkNumber )
} */
