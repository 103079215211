import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useState, useEffect, useContext } from 'react'
import { DataContext } from '../../context/DataContext'
import { apiUrl } from '../../services/apiService'
import req from '../../services/req'
const Grafic = () => {
  const ctx = useContext(DataContext)
  const [data, setData] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
  const [labels, setLabels] = useState(["", "", "", "", "", "", "", "", "", ""])
  useEffect(() => { getTransactions() }, [])

  const _data = {
    labels,
    datasets: [{
      fill: true,
      label: 'Precio de la transaccion',
      data,
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    }]
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      title: {
        display: true,
        text: 'Se muestran los 10 ultimos tipos de cambio de USDT a soles',
      },
    }
  }

  const getTransactions = async () => {
    try {
      let res = await req.get(apiUrl + "transactions/lates")
      ctx.validateReq(res, "grafic - getTransaction")
      const graficData = await res.response
      let filteredData = []
      let labels = []
      for (let i = 0; i < graficData.length; i++) {
          let total = (graficData[i].amountReceive / graficData[i].amountSend).toFixed(3)
          filteredData.push(total)
          labels.push(i)
        
      }
      const invertedData = filteredData.reverse()
      setData(invertedData)
      setLabels(labels)
    } catch (error) {
      //console.log("ERROR GRAFIC: ", error)
    }
  }

  Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

  return <Line options={options} data={_data} />
}

export default Grafic