import styles from '../../css/swap/swap.module.css'
import { useState, useEffect } from 'react'
import Grafic from '../../components/grafic/grafic'
import InvertSwap from '../../components/swap/invertSwap'
import { DataContext } from '../../context/DataContext'
import { useContext } from 'react'
import CoinsModal from '../../components/modals/coinsModal'
import Loader from '../../components/loader/Loader'
import { NavLink } from 'react-router-dom'
import DarkMode from '../../components/darkMode/DarkMode'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getStorage, setStorage } from '../../services/localStorage'
import Memory from '../../components/modalMemory/Modal'
import logo from '../../img/logoAzul.webp'
import bsc from '../../img/redes/bsc.webp'
import matic from '../../img/redes/matic.webp'
import eth from '../../img/redes/eth.svg'
import Resume from '../../services/resumeWallet'
import ModalCustom from '../../components/modals/modalCustom'
import kycImg from '../../img/kyc-bitmarketperu.webp'
import settings from '../../services/settings'
import Time from "../../components/time/Time"
import CoinList from '../../services/coinList'
import { apiUrl } from '../../services/apiService'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import NavMovil from '../../components/NavMovil/NavMovil'
import axios from 'axios'

function Swap() {

    const ctx = useContext(DataContext)

    const [infoKyc, setInfoKyc] = useState(false)
    const [input1, setInput1] = useState(ctx.input1)
    const [input2, setInput2] = useState(ctx.input2)
    const [clock, setClock] = useState({ hour: 0, minutes: 0, secounds: 30 })
    // const [ stateInterval, setStateInterval ] = useState(null)
    const [statusSwap, setStatusSwap] = useState(false)
    // const [ money1, setMoney1 ] = useState(null)
    // const [ money2, setMoney2 ] = useState(null)
    let interval = null;

    useEffect(() => {
        ctx.validateError()
        getConfig()
        //console.log("++++++++=======>>",ctx.user)
    }, [ctx.user])

    const getConfig = async () => {
        ctx.setLoading(true)
        ctx.setLoadingText("Obteniendo config")
        const res = await axios.get(apiUrl + "config")
        let config = res.data.response
        //console.log("Respuesta del config: ", config)
        ctx.getSwapData(config)
        ctx.setConfig(res.data.response)
        ctx.setLoading(false)
        ctx.setLoadingText(false)
    }

    const resetCalc = async () => {

        // ctx.calc(ctx.input1, false)
        let btc = await ctx.getPricesOfCryptoInBinance()
        // //console.log(btc)
        ctx.setBtcPrice(btc)
        await setPricesSoles(btc)

        calc(ctx.input1, true)
    }

    const setPricesSoles = async (btc) => {
        try {
            let Proxy = await (await fetch(apiUrl + "proxy")).json()
            let resProxy = Proxy.response || [{ com_divisa: 0 }, { ven_divisa: 0 }]
            let precioCompra = 1 / Number(resProxy[0].com_divisa)
            let precioVenta = 1 / Number(resProxy[0].ven_divisa)
            ctx.setUsdtPrice({
                compra: resProxy[0].com_divisa,
                venta: resProxy[0].ven_divisa
            })
            setPrices(precioCompra, precioVenta, btc)
        } catch (error) {
            // //console.log(error)
            setPrices(0, 0, 0)
        }
    }

    const setPrices = (compra, venta, btc) => {
        // //console.log(compra, venta, btc)
        let _coinList = []
        CoinList.map((item) => {
            if (item.symbol == "SOL") {
                item.compra = compra
                item.venta = venta
                _coinList.push(item)
            }
            if (item.symbol == "USD") {
                item.compra = 1
                item.venta = 1
                _coinList.push(item)

            }
            if (item.symbol == "BTC") {
                item.compra = btc
                item.venta = btc
                _coinList.push(item)
            }
            if (item.symbol == "USDT") {
                item.compra = 1
                item.venta = 1
                _coinList.push(item)
            }
        })

        // if(money1 && money2){
        // ctx.setCoinList(_coinList)
        // ctx.setSelectedCoin1(money1)
        // ctx.setSelectedCoin2(money2)

        // }

        // /* //console.log(_coinList) */

    }

    function closeInterval() {
        clearInterval(interval);
    }

    const handleTime = () => {
        let newSeconds = clock.secounds + 1;
        let newMinutes = clock.minutes;

        interval = setInterval(async function () {
            // //console.log('swap')
            newSeconds--;

            if (newSeconds === -1 && newMinutes > 0) {
                newMinutes--;
                setClock({ ...clock, minutes: newMinutes, secounds: clock.secounds });
                newSeconds = clock.secounds;
            } else if (newSeconds === -1 && newMinutes === 0) {
                await ctx.setBtnStatus(false)
                setStatusSwap(true);
                ctx.setStatusTime(true);
                newSeconds = clock.secounds;
                newMinutes = clock.minutes;
                setClock({ ...clock, minutes: newMinutes, secounds: newSeconds })
                closeInterval();
                await resetCalc();
                setStatusSwap(false);
                ctx.setStatusTime(false);
                handleTime()

            } else {
                setClock({ ...clock, minutes: newMinutes, secounds: newSeconds })

            }

        }, 1000);
    }

    const handleDarlk = () => {
        let local = getStorage('bitmarketperu')
        if (local === 'light') {
            setStorage('bitmarketperu', 'dark')
            ctx.setDarkMode(true)
        } else {
            setStorage('bitmarketperu', 'light')
            ctx.setDarkMode(false)
        }
    }
    const [modal, setModal] = useState(false)

    useEffect(() => {
        if (!statusSwap) {
            handleTime();
        }
        return (() => closeInterval())
    }, [])

    // useEffect(() => {
    //     if(ctx.selectedCoin1) setMoney1(ctx.selectedCoin1)
    //     if(ctx.selectedCoin2) setMoney2(ctx.selectedCoin2)
    // }, [ctx.selectedCoin1, ctx.selectedCoin2])

    useEffect(() => {
        if (statusSwap) {
            // //console.log("bucle")
            ctx.setInput1(input1);
            ctx.setInput2(input2);
            calc(input1, true)
        }
        // setInput1(ctx.input1); 
        // setInput2(ctx.input2); 

    }, [ctx.input1, ctx.input2, resetCalc, input1, input2])

    const calc = (value, index) => {
        index ? down(value) : up(value)
    }

    const down = (value) => {
        let comision = calcComision()
        ctx.setComision(comision)
        ctx.setInput1(value)
        let subtotal = value * ctx.selectedCoin1.compra / ctx.selectedCoin2.compra
        let total = subtotal - (subtotal * comision / 100)
        ctx.selectedCoin2.symbol == "BTC" ? total = total.toFixed(6) : total = total.toFixed(2)
        if (isNaN(total)) total = 0;
        ctx.setInput2(Number(total))
        ctx.validateError()
    }

    const up = (value) => {
        let comision = calcComision()
        ctx.setInput2(value)
        let subtotal = value / ctx.selectedCoin1.compra * ctx.selectedCoin2.compra
        let total = subtotal + (subtotal * comision / 100)
        ctx.selectedCoin1.symbol == "BTC" ? total = total.toFixed(6) : total = total.toFixed(2)
        if (isNaN(total)) total = 0;
        ctx.setInput1(Number(total))
        ctx.validateError()

    }

    const calcComision = () => {
        if (ctx.selectedCoin1.symbol == "USDT" && ctx.selectedCoin2.symbol == "SOL") {
            return ctx.config.solOut
        }
        if (ctx.selectedCoin1.symbol == "SOL" && ctx.selectedCoin2.symbol == "USDT") {
            return ctx.config.solInp
        }
        if (ctx.selectedCoin1.symbol == "SOL" && ctx.selectedCoin2.symbol == "USD" || ctx.selectedCoin1.symbol == "USD" && ctx.selectedCoin2.symbol == "SOL") {
            return 0
        }
        if (ctx.selectedCoin1.symbol == "USD" && ctx.selectedCoin2.symbol == "USDT") {
            return ctx.config.dolInp
        }
        if (ctx.selectedCoin1.symbol == "USDT" && ctx.selectedCoin2.symbol == "USD") {
            return ctx.config.dolOut
        }
        if (ctx.selectedCoin1.symbol == "BTC") {
            return ctx.config.buyBit
        }
        if (ctx.selectedCoin2.symbol == "BTC") {
            return ctx.config.sellBit
        }
    }


    /* const validateError = () => {
        
        //console.log(`se ejecuta el validador de swap`)
        if (!ctx.kyc) {
            ctx.setError("Debe verificar su identidad (KYC)")
            ctx.setBtnStatus(false)
            return
        }
        
        if (ctx.selectedCoin1.symbol == "USD" && ctx.selectedCoin2.symbol == "SOL") {
            ctx.setError("No se realizan cambios de Dólares a Soles")
            ctx.setBtnStatus(false)
        } else if (ctx.selectedCoin1.symbol == "SOL" && ctx.selectedCoin2.symbol == "USD") {
            ctx.setError("No se realizan cambios de Soles a Dólares")
            ctx.setBtnStatus(false)
        } else if (ctx.selectedCoin1.symbol == "USDT" && ctx.selectedCoin2.symbol == "BTC") {
            ctx.setError("No se realizan cambios de USDT a BTC")
            ctx.setBtnStatus(false)
        } else if (ctx.selectedCoin1.symbol == "BTC" && ctx.selectedCoin2.symbol == "USDT") {
            ctx.setError("No se realizan cambios de BTC a USDT")
            ctx.setBtnStatus(false)
        } else {
            if (ctx.selectedCoin1.symbol == "USDT" && ctx.input1 < settings.minUsdt) {
                ctx.setError(`Monto minimo de ${settings.minUsdt} USDT`)
                ctx.setBtnStatus(false)
            } else if (ctx.selectedCoin1.symbol == "USD" && ctx.input1 < settings.minUsd) {
                ctx.setError(`Monto minimo de ${settings.minUsd} USD`)
                ctx.setBtnStatus(false)
            } else if (ctx.selectedCoin1.symbol == "BTC" && ctx.input1 < settings.minBtc) {
                ctx.setError(`Monto minimo de ${settings.minBtc} BTC`)
                ctx.setBtnStatus(false)
            } else if (ctx.selectedCoin1.symbol == "SOL" && ctx.input1 < settings.minSoles) {
                ctx.setError(`Monto minimo de ${settings.minSoles} Soles`)
                ctx.setBtnStatus(false)
            } else {
                ctx.setError(false)
                ctx.setBtnStatus(true)
            }
        }

        if (Number(ctx.input2) === 0) {
            ctx.setBtnStatus(false)
            return;
        }

        //monto maximo de intercambio en usd
        if (ctx.selectedCoin1.symbol === "USD" && ctx.input1 > settings.maxUsd) {
            ctx.setError(`Monto maximo es ${settings.maxUsd} USD`)
            ctx.setBtnStatus(false)
            return;
        }

        //monto maximo de intercambio en soles
        if (ctx.selectedCoin1.symbol === "SOL" && ctx.input1 > settings.maxSoles) {
            ctx.setError(`Monto maximo es ${settings.maxSoles} SOLES`)
            ctx.setBtnStatus(false)
            return;
        }

        //monto maximo de intercambio en usd
        if (ctx.selectedCoin2.symbol === "USD" && ctx.input2 > settings.maxUsd) {
            ctx.setError(`Monto maximo es ${settings.maxUsd} USD`)
            ctx.setBtnStatus(false)
            return;
        }

        //monto maximo de intercambio en soles
        if (ctx.selectedCoin2.symbol === "SOL" && ctx.input2 > settings.maxSoles) {
            ctx.setError(`Monto maximo es ${settings.maxSoles} SOLES`)
            ctx.setBtnStatus(false)
            return;
        }
    } */

    const handleInput = e => {
        setStatusSwap(true);
        ctx.setInput1(e.target.value);
        setInput1(e.target.value)
        ctx.setInput2(input2);
        ctx.calc(e.target.value, true)
        setStatusSwap(false);
    }

    const handleInput2 = e => {
        setStatusSwap(true);
        ctx.setInput2(e.target.value);
        setInput2(e.target.value)
        ctx.setInput1(input1);
        ctx.calc(e.target.value, false)
        setStatusSwap(false);
    }

    // //console.log(ctx.selectedCoin1)
    // //console.log(ctx.selectedCoin2)
    // //console.log(money1, money2)

    return (
        <>
            <Loader />
            <CoinsModal />
            <AnimatePresence>
                {infoKyc &&
                    <ModalCustom setInfoKyc={setInfoKyc}>
                        <div><img src={kycImg} alt="" /></div>
                        <h5>Verificacion KYC (Conoce a Tu Cliente)</h5>
                        <p>cumpliendo con las exigencias legales y las normativas y regulaciones vigentes. Para realizar intercambios en bitmarketperu.com debe validar su identidad.</p>
                        <p>Nuestro proceso de comprobación KYC se realiza por medio de correo electrónico a la siguiente dirección:</p> <h5 className='text-center'><i className="bi bi-send-check"></i> {settings.email}</h5>
                        <p style={{ alignSelf: "baseline" }}>con los siguientes datos:</p>
                        <ul>
                            <li><i className="bi bi-send-check"></i> Foto frontal del documento de Identidad</li>
                            <li><i className="bi bi-send-check"></i> Foto posterior del documento de Identidad</li>
                            <li><i className="bi bi-send-check"></i> Foto con tu documento de Identidad (selfie)</li>
                        </ul>
                        <p>El tiempo de validación de identidad es de aproximadamente 30 minutos.</p>
                        <div className="centerAlertModal">
                            <button onClick={() => setInfoKyc(false)}>
                                Entendido
                            </button>
                        </div>
                    </ModalCustom>
                }
            </AnimatePresence>
            {modal ? <Memory setModal={setModal} />
                : <>
                    <NavMovil />
                    <div className='swapContainer'>
                        <div className="x">
                            <div className='container'>
                                <div className="row">
                                    <div className="col-7 pad text-white d-none d-md-none d-lg-block">
                                        <div className={styles.grafic}>
                                            <Grafic />
                                        </div>

                                    </div>
                                    <div className="col-md-12 col-lg-5 pad mb-5">
                                        <div className={styles.swapSection}>
                                            <h2> Swap </h2>

                                            <p> Cambia tokens al instante </p>
                                            <div className='text-small'>
                                                Precio BTC {ctx.btcPrice && ctx.resume(ctx.btcPrice, 2)} $
                                                <span style={{ color: "#f5da0c" }}>
                                                    (<Time hour={clock.hour} minutes={clock.minutes} secounds={clock.secounds} to="des" resetCalc={resetCalc} />)
                                                </span>
                                            </div>
                                            <div className='text-medium'>
                                                Compra USD: {ctx.usdPrice ? ctx.usdPrice.compra : <>Cargando.. </>} s -
                                                Venta USD:{ctx.usdPrice ? ctx.usdPrice.venta : <>Cargando.. </>} s
                                            </div>
                                            <hr />
                                            <div className='selectSwapItem mb-2'>
                                                <div onClick={() => { ctx._setCoinsModal(true); ctx.setDirection(1) }} className={styles.select}>
                                                    {ctx.selectedCoin1 ? <>
                                                        <img src={ctx.selectedCoin1.img} alt="" />
                                                        <div className='mx-2'>{ctx.selectedCoin1.symbol}</div>
                                                        <i className="bi-chevron-down"></i>
                                                    </> : "Cargando..."
                                                    }

                                                </div>
                                                <p className='p-to'>Envias</p>
                                                {ctx.usdt && ctx.selectedCoin1.symbol == "USDT" &&
                                                    <div className='lb'>
                                                        Balance {ctx.usdt} USDT
                                                    </div>
                                                }
                                            </div>
                                            <input id='swap1' value={ctx.input1} onChange={e => handleInput(e)} type="text" placeholder='0.0' autoComplete='off' />

                                            <InvertSwap />

                                            <div className='mb-2 selectSwapItem'>
                                                <div onClick={() => { ctx._setCoinsModal(true); ctx.setDirection(2) }} className={styles.select}>
                                                    {ctx.selectedCoin2 ? <>
                                                        <img src={ctx.selectedCoin2.img} alt="" />
                                                        <div className='mx-2'>{ctx.selectedCoin2.symbol}</div>
                                                        <i className="bi-chevron-down"></i>
                                                    </> : "Cargando..."
                                                    }
                                                </div>
                                                <p className='p-to'>Recibes</p>
                                                {ctx.usdt && ctx.selectedCoin2.symbol == "USDT" &&
                                                    <div className='lb'>
                                                        Balance {ctx.usdt} USDT
                                                    </div>
                                                }
                                            </div>
                                            <input value={ctx.input2} onChange={e => handleInput2(e)} type="text" placeholder='0.0' name="2" id="2" autoComplete='off' />
                                            {ctx.error &&
                                                <div className='text-danger' style={{ textAlign: "center", fontSize: "16px" }}>
                                                    <span onClick={() => !ctx.kyc && setInfoKyc(true)}><span className='info'><i className="bi bi-info-circle"></i></span> {ctx.error} </span>
                                                </div>
                                            }

                                            <div className='mb-2 lb'>
                                                comisión {ctx.comision}%
                                            </div>

                                            <div>
                                                {ctx.btnStatus ?
                                                    <NavLink to='/transaction' >
                                                        <button /*onClick={() => send()}*/ className="btnSwap" > Procesar </button>
                                                    </NavLink>
                                                    : <button className='btnDisabled' disabled> Procesar </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
        </>)
}
export default Swap