import { useContext, useState } from 'react';
import styles from '../../css/login/login.module.css';
import picture from '../../img/register.svg';
import AlertModal from '../../components/modals/alertModal';
import { DataContext } from '../../context/DataContext';
import NavMovil from '../../components/NavMovil/NavMovil';
import { apiUrl } from "../../services/apiService";
import req from '../../services/req';
import Load from './Load';
import { Redirect } from 'react-router-dom';

import { Link } from "react-router-dom";

const Register = () => {
  const { darkMode, alertModal, setAlertModal, setIsLoggedIn, isLoggedIn } = useContext(DataContext);
  const [ load, setLoad] = useState(false)

    const [formData, setFormData] = useState({
      email: '',
      phone: '',
      password: '',
      confirmPass: '',
      name:'',
      dni:''
    });
  
    const [alert, setAlert] = useState({
      type: '',
      message: ''
    });
  
    const handleChange = ({ target }) => {
      const { name, value } = target;
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }))
    }
  
    const handleSubmit = e => {
      e.preventDefault();

      if (!isValidEmail(formData.email)) {
        setAlert({ type: 'danger', message: 'Ingrese una dirección de correo válida.' });
        return;
      }

      if (!isValidPhoneNumber(formData.phone)) {
        setAlert({ type: 'danger', message: 'Ingrese un número de teléfono válido.' });
        return;
      }

      if (!isValidPassword(formData.password)) {
        setAlert({ type: 'danger', message: 'La contraseña debe tener al menos una letra mayúscula, un número y no menos de 6 caracteres.' });
        return;
      }
  
      if (formData.password !== formData.confirmPass) {
        setAlert({ type: 'danger', message: 'Las contraseñas no coinciden.' });
        return;
      }

      // registrar
      onRegister();

    };
  
    const isValidPhoneNumber = phone => {
        return true
       /*  const phonePattern = /^\d{4,20}$/; // Expresión regular para un número de 10 dígitos
        return phonePattern.test(phone); */
    };

    const isValidEmail = email => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expresión regular para validar correo electrónico
        return emailPattern.test(email);
    };

    const isValidPassword = password => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*\d).{6,}$/; // Al menos una mayúscula, un número y 6 caracteres
        return passwordPattern.test(password);
    };

    const handleCloseAlert = () => {
        setAlert({
            type: '',
            message: ''
        });
    };

    const onRegister = async () => {
        setLoad(true)
        try {
            const response = await req.post(`${apiUrl}login`, formData);
            if (!response.response.error) {
              // Aquí puedes manejar el éxito del registro
              // setIsLoggedIn(true);
              setAlertModal({
                status: true,
                type: 'success',
                text: 'Se ha registrado correctamente, ya puede iniciar sesión',
                redirect: '/login'
              });
              
            } else {
              setAlertModal({
                status: true,
                type: 'danger',
                text: response.response.error,
              });
            }
          } catch (error) {
                console.error('Error login:', error);
                setAlertModal({
                status: true,
                type: 'danger',
                text: 'Ocurrió un error, inténtalo nuevamente.',
                });
          }
          setLoad(false)
          
    }

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }
    
  return (<>
        {alertModal.status && <AlertModal setAlertModal={setAlertModal} alertModal={alertModal} />}
        {load && (<Load />)}
        <NavMovil />
        <div className={darkMode ? styles.title : styles.titleLight}>
            <h2>Registro</h2>
        </div>

        <div className={`${styles.container} ${styles.flexDirection}`}>
            <div className={styles.image}>
                <img src={picture} alt="Imagen" />
            </div>
            <div className={styles.form}>
                <form onSubmit={handleSubmit} className={styles.formContainer}>
                    <div className='row'>
                        <div className={`${styles.formGroup} col-md-8`}>
                            <label htmlFor="email">Correo:</label>
                            <input 
                                type="text" 
                                id="email" 
                                name="email"
                                onChange={handleChange}
                            />
                        </div>

                        <div className={`${styles.formGroup} col-md-4`}>
                            <label htmlFor="phone">Telefono:</label>
                            <input 
                                type="text"
                                id="phone" 
                                name="phone" 
                                onChange={handleChange}
                            />
                        </div>

                        <div className={`${styles.formGroup} col-md-6`}>
                            <label htmlFor="email">Nombre Completo:</label>
                            <input 
                                type="text" 
                                id="name" 
                                name="name"
                                onChange={handleChange} />
                        </div>
                        <div className={`${styles.formGroup} col-md-6`}>
                            <label htmlFor="email">Documento de identidad:</label>
                            <input
                                type="text" 
                                id="dni" 
                                name="dni"
                                onChange={handleChange} />
                        </div>
                    </div>
                    
                    <div className='row'>
                        <div className={`${styles.formGroup} col-md-6`}>
                            <label htmlFor="password">Contraseña:</label>
                            <input 
                                type="password" 
                                id="password" 
                                name="password" 
                                onChange={handleChange}
                            />
                        </div>
                        <div className={`${styles.formGroup} col-md-6`}>
                            <label htmlFor="confirmPass">Repetir Contraseña:</label>
                            <input 
                                type="password" 
                                id="confirmPass" 
                                name="confirmPass" 
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    {alert.message && (
                        <div className={`alert alert-${alert.type} alert-dismissible`} role="alert">
                        {alert.message}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleCloseAlert}>
                        </button>
                      </div>
                    )}
                    <button className={styles.btn} type="submit">Registrar</button>
                    
                </form>
                <p className={darkMode ? styles.register : styles.registerLight}>
                    ¿YA ESTÁS REGISTRADO?
                    <Link className={darkMode ? styles.linkRegister : styles.linkRegisterLight} to="./login"> Iniciar Sesión</Link>
                </p>
            </div>
        </div>
    </>);
};

export default Register;
