/* import { useContext } from "react"
//
//import Web3 from "web3"
import { NavLink } from 'react-router-dom'
import Loader from "../../components/loader/Loader"
import { DataContext } from "../../context/DataContext" */
import { motion} from 'framer-motion/dist/framer-motion'

//const web3 = new Web3("https://bsc-dataseed.binance.org/")
import { useState } from "react"
import Header from './Header'
// import Franja from './Franja'
// import Details from './Details'
// import Chart from './Chart'
// import Secure from './Secure'
import Footer from '../landing/Footer'
import logo from '../../img/logoAzul.webp'
import { useContext } from "react"
import { DataContext } from "../../context/DataContext"
import DarkMode from '../../components/darkMode/DarkMode'
import { getStorage, setStorage } from '../../services/localStorage'
import bsc from '../../img/redes/bsc.webp'
import matic from '../../img/redes/matic.webp'
import eth from '../../img/redes/eth.svg'
import Resume from '../../services/resumeWallet'
import AlertModal from '../../components/modals/alertModal'
import Memory from '../../components/modalMemory/Modal'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Landing = ()=> {

    // const {loading} = useContext(DataContext)

    const { wallet, redesModal, setRedesModal, darkMode, setDarkMode, chain, alertModal, setAlertModal } = useContext(DataContext)

    const [modal, setModal] = useState(false)

    const handleDarlk = () => {
        let local = getStorage('bitmarketperu')
        if (local === 'light') {
            setStorage('bitmarketperu', 'dark')
            setDarkMode(true)
        } else {
            setStorage('bitmarketperu', 'light')
            setDarkMode(false)
        }
    }

    return (<>
        {modal ? (<Memory setModal={setModal} />)
        : 
            (<div>
            {/* {loading && <Loader/>}*/}   
            {alertModal.status && <AlertModal setAlertModal={setAlertModal} alertModal={alertModal} />}
            <div className='d-lg-none nav-section-1 nav-mobile'>
                <div className='logo-mobile'>
                    <LazyLoadImage effect='blur' src={logo} alt="" />
                    <span className={`${!darkMode && "nav-text-logo"}`}>BitmarketPerú</span>
                </div>
                <DarkMode setDarkMode={() => handleDarlk()} darkMode={darkMode} />
                <div onClick={() => setModal(true)} className='btn-mobil-game'>
                    <div className='container-ico-game'>
                        <i className="bi bi-controller ico-mobil-game"></i>
                    </div>
                </div>
                <div className='nav-section-2'>
                    <button onClick={() => setRedesModal(true)} className='connect-btn'>
                        <div className='external-circle'>
                            {chain && chain == 1 && <div className='internal-circle'>
                                <LazyLoadImage effect='blur' className='redLogoWallet' src={eth} alt="" />
                            </div>}
                            {chain && chain == 56 && <div className='internal-circle'>
                                <LazyLoadImage effect='blur' className='redLogoWallet' src={bsc} alt="" />
                            </div>}
                            {chain && chain == 137 && <div className='internal-circle'>
                                <LazyLoadImage effect='blur' className='redLogoWallet' src={matic} alt="" />
                            </div>}
                            {!chain && <>
                                <div className='internal-circle'>
                                    <i className='bi-wallet2 text-white' ></i>
                                </div>
                            </>}
                        </div>
                        <div style={{ fontSize: '16px' }}>
                            {wallet ? Resume(wallet) : <>Conectar Wallet</>}
                        </div>
                    </button>
                </div>
            </div>
            <Header />
            <motion.div
                // initial={{ 
                //     opacity: 1,
                //  }}
                // whileInView={{ opacity: 1}}
                // transition={{ duration: 1 }}
            >
                {/* <Franja /> */}
            </motion.div>
            {/* <Details /> */}
            {/* <Chart /> */}
            <motion.div
                // initial={{ opacity: 0.5 }}
                // whileInView={{ opacity: 1}}
                // transition={{ duration: 1 }}
            >
                {/* <Franja /> */}
            </motion.div>
            {/* <Secure /> */}
            <Footer />
        </div>)
        }
        
        </>)
}

export default Landing