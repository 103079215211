import { useContext } from 'react'
import styles from '../../css/landing/secure.module.css'
import bitcoins from '../../img/secure.webp'
import Card from './Card'
import { motion } from 'framer-motion/dist/framer-motion'
import { DataContext } from '../../context/DataContext'
import Call from './Call'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Secure = () => {

    const { darkMode } = useContext(DataContext)

    return (<>
    <div className={darkMode ? styles.secureContainer : styles.secureContainerLight}>
        <div className={darkMode ? styles.secure : styles.secureLight}>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
            <Card>
                <div className={styles.container}>
                    <div className={styles.text}>
                        <motion.h3 
                            className={darkMode ? styles.textTitle : styles.textTitleLight }
                            initial={{ opacity: 0, y: -100 }}
                            whileInView={{ opacity: 1, y: 0}}
                            transition={{ duration: 1 }}
                        >Transacciones en Minutos</motion.h3>
                        <motion.p 
                            className={ darkMode ? styles.textParagraph : styles.textParagraphLight }
                            initial={{ opacity: 0, x: -100 }}
                            whileInView={{ opacity: 1, x: 0}}
                            transition={{ duration: 1 }}
                        ><i className="bi bi-geo-alt-fill"></i> Contamos con local en <a className={darkMode ? styles.linkDark :  styles.link } href='https://goo.gl/maps/qQnoXXxK5TeJBAWw8' target="_blank" title="Dirección" rel="noreferrer"> Miraflores Lima Perú</a>, con más de 4 años de experiencia.</motion.p>
                    </div>
                    <motion.div 
                        className={styles.picture}
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0}}
                        transition={{ duration: 1 }}
                    >
                        <LazyLoadImage
                            effect='blur'
                            className={styles.pictureImg} 
                            src={bitcoins} alt=''
                        />
                    </motion.div>
                </div>
            </Card>
            </motion.div>
        </div>
        <Call />
    </div>
    </>);
}
 
export default Secure;