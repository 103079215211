import { useContext } from "react"
import { DataContext } from "../../context/DataContext"
const Loader = () => {
    const { loading, loadingText } = useContext(DataContext)
    return (<>{
        loading &&
        <div className="bgtransparentDark">
            <div className="wrapperLoader text-center">
                <div className="spinner-border yellow" role="status">
                    <span className="sr-only"></span>
                </div>
                <div className="p-3">
                    {loadingText && loadingText}
                </div>
            </div>
        </div>
    }
    </>
    )
}

export default Loader