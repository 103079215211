import { useContext } from "react"
import { DataContext } from "../../context/DataContext"
import { motion } from 'framer-motion/dist/framer-motion'
import LogoBank from "../logoBank/logoBank"
const SelectAdminBank = () => {
    const ctx = useContext(DataContext)
    return (<>
        {ctx.selectAdminBank && <>
            <motion.div className='bgtransparentDark' animate={{ opacity: 1 }} transition={{ duration: 0.3 }} exit={{ opacity: 1 }}>
                <motion.div className='modal-display' initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }} transition={{ type: 'spring', duration: .3 }} exit={{ opacity: 0, scale: 0 }}>
                    <div className="modalHeader">
                        <div>
                            <i className="bi bi-bank"></i>  Elija un Banco
                        </div>
                        <div>
                            <i onClick={() => ctx.setSelectAdminBank(false)} className='bi-x x'></i>
                        </div>
                    </div>
                    <div className="pb-3">
                        <div className="bankList">
                            {ctx.bankList && ctx.bankList.filter(item => item.status === 0 && item.money == ctx.selectedCoin1.category).map((item) => 
                            <div key={item._id} onClick={() => {
                                ctx.setAdminBank(item)
                                ctx.setSelectAdminBank(false)
                            }} className="bankItem">
                                <LogoBank name={item.name} />
                                <div>
                                    {item.name} - {item.money}<br />
                                    <p className='secondaryText'>
                                        {item.titular} <br />
                                        {item.number} 
                                       {/*  - <br></br>
                                        {ctx.selectedCoin1.category} <br />
                                        {ctx.selectedCoin2.category} */}
                                    </p>
                                </div>
                            </div>)
                            }
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </>}
    </>)
}
export default SelectAdminBank