import styled from 'styled-components'

const Switche = styled.div`
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding: 4px 4px;
    background-color: ${props => props.darkMode ? "#3a1e44" : "rgb(18 44 59 / 23%)"};
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 2px 3px 6px 1px rgb(0 0 0 / 12%);

    span{
        display: grid;
        place-items: center;
        width: 28px;
        height: auto;
        border-radius: 50%;
    }
`;

const Sol = styled.span`
    background-color: ${props => props.darkMode ? "#3a1e44" : "#1c0942"};
    border: ${props => props.darkMode ? "transparent" : "1px solid rgb(236,184,10)"};
    color: ${props => props.darkMode ? "rgb(118, 146, 168)" : "rgb(236,184,10)"}; 
`;

const Luna = styled.span`
    background-color: ${props => props.darkMode && "#3a1e44"};
    border: 1px solid ${props => props.darkMode ? "rgb(236,184,10)" : "transparent"};
    color: ${props => props.darkMode ? "rgb(236,184,10)" : "rgb(118, 146, 168)"};
`;




const DarkMode = ({ darkMode, setDarkMode}) => {

    const handleClick = () => {
        setDarkMode(darkMode ? false : true);
    }
   
    return (<>
        <Switche darkMode={darkMode} onClick={() => handleClick()}>
            <Sol><i className="bi bi-sun" style={{fontSize: "17px"}}></i></Sol>
            <Luna><i className="bi bi-moon" style={{fontSize: "15px"}}></i></Luna>
        </Switche>
    </>);
}
 
export default DarkMode;